import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const sdvoeConsolidatedSdvoe = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    title: " SDVoE Consolidated Summary Report for SDVoE Academy",
    buttons: [
      "colvis",
      "copyHtml5",
      // "excelHtml5",
      // "pdfHtml5",
      {
        extend: "csvHtml5",
        title: " SDVoE Consolidated Summary Report for SDVoE Academy",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: " SDVoE Consolidated Summary Report for SDVoE Academy",
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    data: [],
    dom: "Bfrtip",
    scrollX: true,
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "company", title: "Company" },
      { data: "organization_type", title: "Organization Type" },
      { data: "other", title: "Other" },
      { data: "email", title: "Email" },
      { data: "phone", title: "Phone" },
      { data: "address", title: "Address" },
      { data: "city", title: "City" },
      { data: "country", title: "Country" },
      { data: "title", title: "Title" },
      { data: "jk_role", title: "JK Role" },
      { data: "first_access_date", title: "First Access Date" },
      { data: "original_dpc_grade", title: "Original DPC Grade" },
      { data: "original_dpc_date", title: "Original DPC Date" },
      { data: "manual_attempt", title: "Manual Attempt" },
      { data: "original_dpc_result", title: "Original DP Result" },
      { data: "original_dpc_attempts", title: "Original DP Attempts" },
      { data: "current_dpc_grade", title: "Current DPC Grade" },
      { data: "current_dpc_date", title: "Current DPC Date" },
      { data: "current_dpc_result", title: "Current DPC Result" },
      { data: "current_dpc_attempts", title: "Current DPC Attempts" },
      { data: "china_dpc_grade", title: "China DPC Grade" },
      { data: "china_dpc_date", title: "China DPC Date" },
      { data: "china_dpc_result", title: "China DPC Result" },
      { data: "china_dpc_attempts", title: "China DPC Attempts" },
      { data: "api_lvl_1_grade", title: "API Level 1 Grade" },
      { data: "api_lvl_1_date", title: "API Level 1 Date" },
      { data: "api_lvl_1_result", title: "API Level 1 Result" },
      { data: "api_lvl_1_attempts", title: "API Level 1 Attempts" },
      { data: "api_lvl_2_grade", title: "API Level 2 Grade" },
      { data: "api_lvl_2_date", title: "API Level 2 Date" },
      { data: "api_lvl_2_result", title: "API Level 2 Result" },
      { data: "api_lvl_2_attempts", title: "API Level 2 Attempts" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
