import getDataIdFromEvent from "./getDataIdFromEvent";

const identifyClick = (e: { target: HTMLElement }) => {
  const element = e.target;

  const dataID = getDataIdFromEvent(e);

  if (dataID) {
    const paramsArray = dataID.split("-");
    if (paramsArray.length === 3 && paramsArray[0] === "dticon") {
      return { type: "dtButton", params: [paramsArray[1], paramsArray[2]] };
    }
  }

  // check if it is a pagination click
  else if (
    element.getAttribute("class")?.split(" ")[0] === "paginate_button" ||
    element.getAttribute("class") === "page-link"
  )
    return {
      type: "pagination",
      tableID: element.getAttribute("aria-controls"),
    };
  // check if it is a datatable cell click
  else if (element.nodeName === "TD")
    return {
      type: "cellClick",
      tableID: element.parentElement?.parentElement?.parentElement?.id,
    };
  else return false;
};

export default identifyClick;
