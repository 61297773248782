/*!

=========================================================
* Clavia Portal - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import store from "redux/store";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import AuthLayout from "layouts/Auth.js";
import PortalLayout from "layouts/Portal.js";

// custom components
import PrivateRoute from "components/_Custom/PrivateRoute";
import GlobalLoader from "components/_Custom/GlobalLoader";
import NotificationsMonitor from "components/_Custom/NotificationsMonitor";

// api call
import apiCall from "./functions/apiCall";
import { checkIfLoggedInApiParams } from "./config/api_calls";

// css
import "tippy.js/dist/tippy.css";

const ClaviaPortalApp = () => {
  // every time app reloads, checks if existing token is valid
  useEffect(() => {
    store.dispatch(apiCall(null, checkIfLoggedInApiParams));
  }, []);

  return (
    <Provider store={store}>
      <NotificationsMonitor />
      <GlobalLoader />
      <BrowserRouter>
        <Switch>
          {/*@ts-ignore*/}
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <PrivateRoute path="/portal" component={PortalLayout} />
          <Redirect from="/" to="/portal/home" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<ClaviaPortalApp />, document.getElementById("root"));
