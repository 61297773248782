import React, { useState } from "react";

import { useHistory } from "react-router";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  SUPPORT_DIALOG_SHOW,
  SUPPORT_SET_DT_PAGE,
  SUPPORT_SET_DT_ROW,
} from "../../redux/actionTypes";
// template
import { Card, Col, Container, Row } from "react-bootstrap";
// datatables
import Datatable from "../../components/_Custom/DataTables/DataTable";
import { supportTableParamsAdmin } from "../dataTables/supportTableParamsAdmin";
import { supportTableParamsUser } from "../dataTables/supportTableParamsUser";
// custom functions
import useDataTableRefresh from "../../functions/dataTables/useDatatableRefresh";
import identifyClick from "../../functions/identifyClick";
import useEventListener from "../../functions/useEventListener";
// custom components
import SupportCaseDialog from "../../components/_Custom/Dialogs/SupportCaseDialog";
import AddButton from "../../components/_Custom/AddButton";
// api calls
import apiCall from "../../functions/apiCall";
import { getApproveSupportReqParams } from "../../config/api_calls/partials/supportPageApiCalls";

const SupportPage: React.FC = () => {
  // eslint-disable-next-line no-undef
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const { admin } = useSelector((state: RootStateOrAny) => state.auth.user);

  const tableParams = admin ? supportTableParamsAdmin : supportTableParamsUser;

  const handleClickEvent = (e: { target: HTMLElement }) => {
    const clickRelevantType = identifyClick(e);
    let dtData;
    if (clickRelevantType) {
      let selectedRow;
      // let dtData;
      switch (clickRelevantType.type) {
        case "cellClick":
          if (dataTable && dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: SUPPORT_SET_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: SUPPORT_SET_DT_PAGE,
            payload: dataTable && dataTable.page.info().page,
          });
          break;
        case "dtButton":
          // @ts-ignore
          dataTable && dataTable.rows().deselect();
          dtData =
            dataTable &&
            dataTable
              .rows(clickRelevantType.params && clickRelevantType.params[1])
              .data()[0];
          if (
            clickRelevantType.params &&
            clickRelevantType.params[0] === "CheckCircle"
          ) {
            dispatch(
              apiCall({ action: 1 }, getApproveSupportReqParams(dtData.id)) // approve request
            );
          } else if (
            clickRelevantType.params &&
            clickRelevantType.params[0] === "ArrowForward"
          ) {
            dispatch(
              apiCall({ action: 0 }, getApproveSupportReqParams(dtData.id)) // send request back
            );
          } else if (
            clickRelevantType.params &&
            clickRelevantType.params[0] === "Visibility"
          ) {
            history.push(`/portal/support/${dtData.id}`);
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent);

  useDataTableRefresh(dataTable, tableParams);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Support</Card.Title>
              <p className="card-category">Manage Support Requests</p>
            </Card.Header>
            <Card.Body>
              <Datatable
                setDataTable={setDataTable}
                tableId="supportCaseTable"
                tableParams={tableParams}
              />
            </Card.Body>
            <Card.Footer>
              <AddButton
                handleAddClick={() =>
                  dispatch({ type: SUPPORT_DIALOG_SHOW, payload: null })
                }
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <SupportCaseDialog />
    </Container>
  );
};

export default SupportPage;
