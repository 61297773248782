import React from "react";

// react-bootstrap components
import { Container, Col } from "react-bootstrap";

import ChangeOwnPwdCardWithForm from "../Forms/_Custom/ChangeOwnPwdCardWithForm";
import apiCall from "../../functions/apiCall";

import { changeOwnPasswordParams } from "../../config/api_calls";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function ChangeOwnPasswordPage() {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootStateOrAny) => state.auth.user.id);
  const { isAuthenticated } = useSelector(
    (state: RootStateOrAny) => state.auth
  );
  const { req_pass_change } = useSelector(
    (state: RootStateOrAny) => state.auth.user
  );

  const handleSubmit = (formData: any) => {
    dispatch(apiCall(formData, changeOwnPasswordParams(userId)));
  };

  return (
    <>
      {isAuthenticated && !req_pass_change ? (
        <Redirect to="/" />
      ) : (
        <div
          className="full-page section-image"
          data-color="black"
          data-image={require("../../assets/img/full-screen-image-2.jpg")}
        >
          <div className="content d-flex align-items-center p-0">
            <Container>
              <Col className="mx-auto" lg="4" md="8">
                <ChangeOwnPwdCardWithForm onSubmit={handleSubmit} />
              </Col>
            </Container>
          </div>
          <div
            className="full-page-background"
            style={{
              backgroundImage:
                "url(" +
                require("../../assets/img/backgrounds/login_bg.jpeg") +
                ")",
            }}
          />
        </div>
      )}
    </>
  );
}

export default ChangeOwnPasswordPage;
