import React, { useState } from "react";

// redux
import { useDispatch } from "react-redux";
import {
  PLANS_SET_DT_PAGE,
  PLANS_SET_DT_ROW,
  DELETE_DIALOG_SHOW,
  ADD_EDIT_DIALOG_SHOW,
} from "../../redux/actionTypes";

// api params
import {
  getAddPlanParams,
  getEditPlanParams,
  getDelPlanParams,
  populateAddEditPlanFormParams,
} from "../../config/api_calls";

// react-bootstrap components
import { Card, Container, Row, Col } from "react-bootstrap";

// datatables
import Datatable from "../../components/_Custom/DataTables/DataTable";
import { plansTableParams } from "../dataTables/plansTableParams";

// custom components
import GlobalAddEditDialog from "components/_Custom/Dialogs/GlobalAddEditDialog";
import AddEditPlanForm from "../Forms/_Custom/AddEditPlanForm";
import AddButton from "../../components/_Custom/AddButton";
import DeleteDialog from "../../components/_Custom/Dialogs/DeleteDialog";

// custom functions
import useDataTableRefresh from "../../functions/dataTables/useDatatableRefresh";
import useEventListener from "../../functions/useEventListener";
import identifyClick from "../../functions/identifyClick";

import "datatables.net";

const PlansPage = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line no-undef
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );
  const [editDelId, setEditDelId] = useState(0);

  const handleAddButtonClick = () => {
    setEditDelId(0);
    dispatch({ type: ADD_EDIT_DIALOG_SHOW });
  };

  const handleClickEvent = (e: { target: HTMLElement }) => {
    const clickRelevantType = identifyClick(e);
    if (clickRelevantType) {
      let selectedRow;
      let dtData;
      switch (clickRelevantType.type) {
        case "cellClick":
          if (dataTable && dataTable.rows({ selected: true }).data()[0])
            selectedRow = dataTable.rows({ selected: true }).data()[0].id;
          else selectedRow = null;
          dispatch({ type: PLANS_SET_DT_ROW, payload: selectedRow });
          break;
        case "pagination":
          dispatch({
            type: PLANS_SET_DT_PAGE,
            payload: dataTable && dataTable.page.info().page,
          });
          break;
        case "dtButton":
          // @ts-ignore
          dataTable && dataTable.rows().deselect();
          dtData =
            dataTable &&
            dataTable
              .rows(clickRelevantType.params && clickRelevantType.params[1])
              .data()[0];
          if (
            clickRelevantType.params &&
            clickRelevantType.params[0] === "Edit"
          ) {
            setEditDelId(dtData.id);
            dispatch({ type: ADD_EDIT_DIALOG_SHOW });
          } else if (
            clickRelevantType.params &&
            clickRelevantType.params[0] === "Delete"
          ) {
            dispatch({ type: DELETE_DIALOG_SHOW });
            setEditDelId(dtData.id);
          }
          break;
        default:
          return false;
      }
    }
  };

  useEventListener("click", handleClickEvent);

  useDataTableRefresh(dataTable, plansTableParams);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Plans</Card.Title>
                <p className="card-category">Manage subscription plans</p>
              </Card.Header>
              <Card.Body>
                <Datatable
                  setDataTable={setDataTable}
                  tableId="planTable"
                  tableParams={plansTableParams}
                />
              </Card.Body>
              <Card.Footer>
                <AddButton handleAddClick={handleAddButtonClick} />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <GlobalAddEditDialog
          getAddParams={getAddPlanParams}
          getEditParams={getEditPlanParams}
          populateFormParams={populateAddEditPlanFormParams}
          topTitle="Plan"
          instanceId={editDelId}
        >
          {/*@ts-ignore*/}
          <AddEditPlanForm />
        </GlobalAddEditDialog>
        <DeleteDialog
          title="Delete Plan"
          confirmationQuestion={`Are you sure you would like to delete this plan (id ${editDelId})?`}
          confirmDeleteApiCallParams={getDelPlanParams(editDelId)}
        />
      </Container>
    </>
  );
};

export default PlansPage;
