import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const sdvoeQuiz = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "SDVoE Quiz",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "SDVoE Quiz",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      {
        data: { _: "exam_date.display", sort: "exam_date.@data-order" },
        title: "Exam Date",
      },
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "email", title: "Email" },
      { data: "country", title: "Country" },
      { data: "idnumber", title: "ID Number" },
      { data: "examcode", title: "Exam Code" },
      { data: "course", title: "Course" },
      { data: "assessment", title: "Assessment" },
      { data: "pass_to_grade", title: "Pass to Grade" },
      { data: "grade_achieved", title: "Grade Achieved" },
      { data: "max_grade", title: "Max Grade" },
      { data: "percentage", title: "Percentage" },
      { data: "pass_fail", title: "Pass/Fail" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
