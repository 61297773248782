import React, { useState } from "react";

import { useHistory } from "react-router";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  HOME_PAGE_SELECT_REPORT,
  HOME_PAGE_SHOW_CHART_DIALOG,
  MSG_CUSTOM_MSG,
} from "redux/actionTypes";
// template
import { Col, Form, Card } from "react-bootstrap";
// custom components
import CustomSingleSelect from "../../../../CustomSingleSelect";
import ReportButtons from "./components/ReportButtons";
// custom functions
import useGetSelectedReportParams from "functions/useGetSelectedReportParams";
import useValidateReportDateFields from "functions/useValidateReportDateFields";
// styles
import { minPanelHeight, rowWithTopMargin } from "../../ReportGenerationPanel";
import useGetFetchDocument from "../../../../../../functions/useGetFetchDocument";
// types
import { DocParamsType } from "functions/useGetFetchDocument";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const ReportSelector: React.FC = () => {
  const reportSelectOptions = useSelector(
    (state: RootStateOrAny) => state.homePage.formFields.values.report_options
  );

  // stores selected report string
  const selectedReport = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.selectedReport
  );

  const formDateValidityObj = useValidateReportDateFields(); // checks if time selections are valid for the specific report

  const { checkboxUrlSuffix, dateUrlSuffix } = useSelector(
    (state: RootStateOrAny) => state.homePage.reports
  );

  const { selectedMoodleInstanceId } = useSelector(
    (state: RootStateOrAny) => state.homePage
  );

  const selectedReportParams = useGetSelectedReportParams();

  const [docParams, setDocParams] = useState<DocParamsType>({
    url: "",
    fetchMsg: "",
    fileName: "",
    format: "",
  });

  const history = useHistory();

  const dispatch = useDispatch();

  const handlePdfOrCsvClick = (reportType: "csv" | "pdf") => {
    // validation check
    if (!formDateValidityObj.valid) {
      dispatch({
        type: MSG_CUSTOM_MSG,
        payload: {
          color: "danger",
          text: formDateValidityObj.message,
        },
      });
      return;
    }

    // get date for timestamp
    const newDate = new Date();

    // set report params to generate report
    setDocParams({
      url: `${apiUrl}reports/?report_id=${
        selectedReportParams?.value
      }&instance_id=${selectedMoodleInstanceId}${
        dateUrlSuffix + checkboxUrlSuffix
      }&rep_format=${reportType}`,
      fileName: `${selectedReportParams?.label} - generated on ${
        newDate.getMonth() + 1
      } ${newDate.getDate()}, ${newDate.getFullYear()}`,
      fetchMsg: "Fetching report...",
      format: reportType,
    });
  };

  const handleDatatableClick = () => {
    // validation check
    if (!formDateValidityObj.valid) {
      dispatch({
        type: MSG_CUSTOM_MSG,
        payload: {
          color: "danger",
          text: formDateValidityObj.message,
        },
      });
      return;
    }
    history.push(`/portal/home/datatable-view`);
  };

  const handleChartClick = () => {
    // validation check
    if (!formDateValidityObj.valid) {
      dispatch({
        type: MSG_CUSTOM_MSG,
        payload: {
          color: "danger",
          text: formDateValidityObj.message,
        },
      });
      return;
    }

    // show chart
    dispatch({
      type: HOME_PAGE_SHOW_CHART_DIALOG,
      payload: {
        urlSuffix: dateUrlSuffix + checkboxUrlSuffix,
        reportId: selectedReportParams?.value,
      },
    });
  };

  useGetFetchDocument(docParams);

  return (
    <Card style={{ minHeight: minPanelHeight }}>
      <Form.Group as={Col}>
        <Form.Row style={rowWithTopMargin}>
          <Form.Label>Select Report</Form.Label>
        </Form.Row>
        <Form.Row style={rowWithTopMargin}>
          <Col xs={9} sm={8} md={9} lg={9} xl={7}>
            <CustomSingleSelect
              isDisabled={false}
              options={reportSelectOptions}
              placeholder="Select Report"
              value={selectedReport}
              onChange={(e) =>
                dispatch({ type: HOME_PAGE_SELECT_REPORT, payload: e })
              }
            />
          </Col>
        </Form.Row>
        <ReportDescription />
        <ReportButtons
          handleChartClick={handleChartClick}
          handlePdfOrCsvClick={handlePdfOrCsvClick}
          handleDatatableClick={handleDatatableClick}
        />
      </Form.Group>
    </Card>
  );
};

export default ReportSelector;

const ReportDescription: React.FC = () => {
  const selectedReportInfo = useGetSelectedReportParams();

  return (
    <>
      <Form.Row style={rowWithTopMargin}>
        <Form.Label>
          {selectedReportInfo?.description ? "Report Description" : ""}
        </Form.Label>
      </Form.Row>
      <Form.Row style={rowWithTopMargin}>
        <Col>
          <p>
            {selectedReportInfo?.description
              ? selectedReportInfo.description
              : "No report selected"}
          </p>
        </Col>
      </Form.Row>
    </>
  );
};
