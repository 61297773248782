// event listeners are inconsistent and often yield child elements, this loops through parents to find the first
// element with 'data-id' (row);

export default function getDataIdFromEvent(e: { target: HTMLElement }) {
  let parent = e.target;
  let dataID;

  // loop while a parent exists, and it's not yet what we are looking for.
  while (parent) {
    if (parent.getAttribute("data-id")) {
      dataID = parent.getAttribute("data-id");

      return dataID;
    }
    // We didn't find anything yet, so snag the next parent or break
    if (parent.parentElement) parent = parent.parentElement;
    else break;
  }

  return null;
}
