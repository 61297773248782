// allows selection of dates based on selected report pararams, sets url suffix in redux for reports to use
import React from "react";

import { Form } from "react-bootstrap";

// custom
import ReportDatePicker from "./components/ReportDatePicker";
import ReportDateShortcuts from "./components/ReportDateShortcuts";
import ReportMonthPicker from "./components/ReportMonthPicker";
import ReportYearPicker from "./components/ReportYearPicker";

// functions
import useGetSelectedReportParams from "functions/useGetSelectedReportParams";
import useSetReportUrlDateSuffix from "functions/useSetReportUrlDateSuffix";

// styles
import { rowWithTopMargin } from "../../../../ReportGenerationPanel";

const DateOptions: React.FC = () => {
  const reportInfo = useGetSelectedReportParams();

  useSetReportUrlDateSuffix();

  return (
    <>
      {/*year only row*/}
      {reportInfo?.accepts_year ? (
        <Form.Row style={rowWithTopMargin}>
          <ReportYearPicker type="year" />
        </Form.Row>
      ) : (
        <></>
      )}

      {/*year range row*/}
      {reportInfo?.accepts_year_range ? (
        <Form.Row style={rowWithTopMargin}>
          <ReportYearPicker type="fromYear" />
          <ReportYearPicker type="toYear" />
        </Form.Row>
      ) : (
        <></>
      )}

      {/*month multi select row*/}
      {reportInfo?.accepts_multiselect_month ? (
        <Form.Row style={rowWithTopMargin}>
          <ReportMonthPicker />
        </Form.Row>
      ) : (
        <></>
      )}

      {/*date only row*/}
      {reportInfo?.accepts_date ? (
        <Form.Row style={rowWithTopMargin}>
          <ReportDatePicker type="date" />
        </Form.Row>
      ) : (
        <></>
      )}

      {/*date range row*/}
      {reportInfo?.accepts_date_range ? (
        <Form.Row style={rowWithTopMargin}>
          <ReportDatePicker type="fromDate" />
          <ReportDatePicker type="toDate" />
          <ReportDateShortcuts />
        </Form.Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default DateOptions;
