import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const topTenPerformers = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Top 10 Highest Performers",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Top 10 Highest Performers",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "email", title: "Email" },
      { data: "company", title: "Company" },
      { data: "city", title: "City" },
      { data: "country", title: "Country" },
      { data: "average_percentage", title: "Average Percentage" },
      { data: "total_modules_viewed", title: "Total Modules Viewed" },
      { data: "rank", title: "Rank" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[8, "asc"]],
  },
};
