import { useEffect, useRef } from "react";

export default function useEventListener(
  eventName: string,
  handler: (e: { target: HTMLElement }) => void
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(e: { target: HTMLElement }) => void>();

  // Update ref.current value if handler changes. This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Create event listener that calls handler function stored in ref
      // @ts-ignore
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      window.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        window.removeEventListener(eventName, eventListener);
      };
    },
    [eventName] // Re-run if eventName or element changes
  );
}
