import React, { useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import { Form } from "react-bootstrap";

// select boxes
import { OptionsType, OptionTypeBase } from "react-select";

import CustomSpinner from "../../../components/_Custom/CustomSpinner";

import { useForm } from "react-hook-form";

import CustomFormSelect from "../../../components/_Custom/CustomFormSelect";

const AddEditReportPackageForm: React.FC<AddEditReportPackageFormProps> = ({
  formSuggestions,
  formSuggestionsLoading,
  dialogLoading,
  onSubmit,
}) => {
  const formFields = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog.formFields
  );

  // update fields from pre-populated values when they change (add is empty, edit is not)
  useEffect(() => {
    setValue("name", formFields.name ? formFields.name : "");
    setValue("reports", formFields.reports ? formFields.reports : []);
  }, [formFields]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  return dialogLoading ? (
    <CustomSpinner height={300} />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} id="modalForm">
      <Form.Group controlId="formBasicCheckbox">
        <Form.Label>Package Name</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          {...register("name", { required: true })}
          isInvalid={errors.name?.type === "required"}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid package name
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Report</Form.Label>
        <CustomFormSelect
          loading={formSuggestionsLoading}
          options={formSuggestions.report_options}
          formFields={formFields}
          placeholder="Select Available Report(s)"
          fieldName="reports"
          control={control}
          showValidationError={errors.reports?.type === "required"}
          validationMessage="Please select at least one available report"
          isMulti={true}
          required={true}
        />
      </Form.Group>
    </Form>
  );
};

export default AddEditReportPackageForm;

interface AddEditReportPackageFormProps {
  onSubmit: (event: any) => void;
  formSuggestions: FormSuggestionsType;
  formSuggestionsLoading: boolean;
  dialogLoading: boolean;
}

interface FormSuggestionsType {
  report_options: OptionsType<OptionTypeBase>;
}
