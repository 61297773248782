// companies
import {
  USER_MANAGEMENT_DT_REFRESH,
  USER_MANAGEMENT_DT_REFRESH_RESEL,
  MSG_SHOW_ERROR_MSG,
  // change password dialog
  USER_MANAGEMENT_HIDE_CP_DIALOG,
  // messages
  MSG_USER_ADD_SUCCESS,
  MSG_USER_DEL_SUCCESS,
  MSG_USER_UPDATE_SUCCESS,
  MSG_PWD_CHANGED,
  // add edit dialog
  ADD_EDIT_DIALOG_HIDE,
  ADD_EDIT_DIALOG_LOADING,
  ADD_EDIT_DIALOG_NOT_LOADING,
  ADD_EDIT_DIALOG_FORM_POPULATE,
  // delete dialog
  DELETE_DIALOG_HIDE,
  DELETE_DIALOG_LOADING,
  DELETE_DIALOG_NOT_LOADING,
  USER_MANAGEMENT_CP_DIALOG_LOADING,
  USER_MANAGEMENT_CP_DIALOG_NOT_LOADING,
  GLOBAL_LOADER_SHOW,
  AUTH_LOGIN_SUCCESS,
  GLOBAL_LOADER_HIDE,
} from "redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const getAddUserParams = () => {
  return {
    url: `${apiUrl}acc/users/`,
    method: "post",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_USER_ADD_SUCCESS,
      USER_MANAGEMENT_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const populateAddEditUserFormParams = (userId: number) => {
  return {
    url: `${apiUrl}acc/users/${userId}`,
    method: "get",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      ADD_EDIT_DIALOG_FORM_POPULATE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [
      MSG_SHOW_ERROR_MSG,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
  };
};

export const getEditUserParams = (userId: number) => {
  return {
    url: `${apiUrl}acc/users/${userId}?form_id=edit_profile_form`,
    method: "put",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_USER_UPDATE_SUCCESS,
      USER_MANAGEMENT_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const getDelUserParams = (userId: number) => {
  return {
    url: `${apiUrl}acc/users/${userId}`,
    method: "delete",
    preSendDispatchTypes: [DELETE_DIALOG_LOADING],
    successDispatchTypes: [
      DELETE_DIALOG_NOT_LOADING,
      DELETE_DIALOG_HIDE,
      MSG_USER_DEL_SUCCESS,
      USER_MANAGEMENT_DT_REFRESH,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, DELETE_DIALOG_NOT_LOADING],
  };
};

export const getEditUserPassParams = (userId: number) => {
  return {
    url: `${apiUrl}acc/users/${userId}?form_id=edit_user_pass_form`,
    method: "put",
    preSendDispatchTypes: [USER_MANAGEMENT_CP_DIALOG_LOADING],
    successDispatchTypes: [
      USER_MANAGEMENT_HIDE_CP_DIALOG,
      MSG_PWD_CHANGED,
      USER_MANAGEMENT_CP_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [
      MSG_SHOW_ERROR_MSG,
      USER_MANAGEMENT_CP_DIALOG_NOT_LOADING,
    ],
  };
};

export const changeOwnPasswordParams = (userId: number) => {
  return {
    url: `${apiUrl}acc/users/${userId}?form_id=edit_own_pass_form`,
    method: "put",
    preSendDispatchTypes: [GLOBAL_LOADER_SHOW],
    successDispatchTypes: [
      GLOBAL_LOADER_HIDE,
      AUTH_LOGIN_SUCCESS,
      MSG_PWD_CHANGED,
    ],
    errorDispatchTypes: [GLOBAL_LOADER_HIDE, MSG_SHOW_ERROR_MSG],
  };
};
