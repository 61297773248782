import React from "react";

import moment from "moment";

// redux
import { useDispatch } from "react-redux";
import {
  HOME_PAGE_SET_REPORTS_FROM_DATE,
  HOME_PAGE_SET_REPORTS_TO_DATE,
} from "redux/actionTypes";

const ReportDateShortcuts: React.FC = () => {
  const dispatch = useDispatch();

  const setDate: SetDate = (type, date) => {
    dispatch({
      type:
        type === "from"
          ? HOME_PAGE_SET_REPORTS_FROM_DATE
          : HOME_PAGE_SET_REPORTS_TO_DATE,
      // @ts-ignore
      payload: date,
    });
  };

  return (
    <>
      <a className="date-shortcut title"> Date Shortcuts:</a>
      <a
        className="date-shortcut link"
        onClick={() => {
          setDate("from", moment().startOf("month").format("YYYY-MM-DD"));
          setDate("to", moment().endOf("month").format("YYYY-MM-DD"));
        }}
      >
        Current Month
      </a>
      <a
        className="date-shortcut link"
        onClick={() => {
          setDate("from", moment().startOf("year").format("YYYY-MM-DD"));
          setDate("to", moment().endOf("year").format("YYYY-MM-DD"));
        }}
      >
        Current Year
      </a>
      <a
        className="date-shortcut link"
        onClick={() => {
          setDate(
            "from",
            moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
          );
          setDate(
            "to",
            moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
          );
        }}
      >
        Last Month
      </a>
      <a
        className="date-shortcut link"
        onClick={() => {
          setDate(
            "from",
            moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD")
          );
          setDate(
            "to",
            moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
          );
        }}
      >
        Last Year
      </a>
    </>
  );
};

export default ReportDateShortcuts;

type SetDate = (type: "from" | "to", date: string) => void;
