import {
  USER_MANAGEMENT_SET_DT_PAGE,
  USER_MANAGEMENT_SET_DT_ROW,
  USER_MANAGEMENT_DT_REFRESH,
  USER_MANAGEMENT_DT_REFRESH_RESEL,
  USER_MANAGEMENT_SHOW_CP_DIALOG,
  USER_MANAGEMENT_HIDE_CP_DIALOG,
  USER_MANAGEMENT_CP_DIALOG_LOADING,
  USER_MANAGEMENT_CP_DIALOG_NOT_LOADING,
} from "redux/actionTypes";

import { RootStateOrAny } from "react-redux";

const initialState = {
  tables: {
    userManagementDataTable: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {},
    },
  },
  dialogs: {
    changePasswordDialog: {
      open: false,
      loading: false,
    },
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case USER_MANAGEMENT_SET_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          userManagementDataTable: {
            ...state.tables.userManagementDataTable,
            viewedPage: action.payload,
          },
        },
      };
    case USER_MANAGEMENT_SET_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          userManagementDataTable: {
            ...state.tables.userManagementDataTable,
            selectedRow: action.payload,
          },
        },
      };
    case USER_MANAGEMENT_DT_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          userManagementDataTable: {
            ...state.tables.userManagementDataTable,
            selectedRow: null,
            triggerDtRefresh: {},
          },
        },
      };
    case USER_MANAGEMENT_DT_REFRESH_RESEL:
      return {
        ...state,
        tables: {
          ...state.tables,
          userManagementDataTable: {
            ...state.tables.userManagementDataTable,
            selectedRow: action.payload.data.id,
            triggerDtRefresh: {},
          },
        },
      };
    case USER_MANAGEMENT_SHOW_CP_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          changePasswordDialog: {
            ...state.dialogs.changePasswordDialog,
            open: true,
          },
        },
      };
    case USER_MANAGEMENT_HIDE_CP_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          changePasswordDialog: {
            ...state.dialogs.changePasswordDialog,
            open: false,
          },
        },
      };
    case USER_MANAGEMENT_CP_DIALOG_LOADING:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          changePasswordDialog: {
            ...state.dialogs.changePasswordDialog,
            loading: true,
          },
        },
      };
    case USER_MANAGEMENT_CP_DIALOG_NOT_LOADING:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          changePasswordDialog: {
            ...state.dialogs.changePasswordDialog,
            loading: false,
          },
        },
      };
    default:
      return state;
  }
}
