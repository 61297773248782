// reports
import {
  GLOBAL_LOADER_SHOW,
  GLOBAL_LOADER_HIDE,
  HOME_PAGE_FORM_FIELDS_LOADING,
  HOME_PAGE_FORM_FIELDS_NOT_LOADING,
  HOME_PAGE_FORM_FIELDS_SET,
  HOME_PAGE_TOP_NAVBAR_LOADING,
  HOME_PAGE_TOP_NAVBAR_NOT_LOADING,
  HOME_PAGE_TOP_NAVBAR_DATA_SET,
  HOME_PAGE_CHART_DATA_CLEAR,
  HOME_PAGE_CHART_DATA_SET,
  MSG_SHOW_ERROR_MSG,
  HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING,
  HOME_PAGE_CHART_DIALOG_DATA_LOADING,
  HOME_PAGE_CHARTS_DATA_SET,
  HOME_PAGE_CHARTS_NOT_LOADING,
  HOME_PAGE_CHARTS_LOADING,
} from "redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const populateReportsFormParams = {
  url: `${apiUrl}form-options/?form_id=reports_form`,
  method: "get",
  preSendDispatchTypes: [HOME_PAGE_FORM_FIELDS_LOADING, GLOBAL_LOADER_SHOW],
  successDispatchTypes: [
    HOME_PAGE_FORM_FIELDS_SET,
    HOME_PAGE_FORM_FIELDS_NOT_LOADING,
    GLOBAL_LOADER_HIDE,
  ],
  errorDispatchTypes: [
    HOME_PAGE_FORM_FIELDS_NOT_LOADING,
    GLOBAL_LOADER_HIDE,
    MSG_SHOW_ERROR_MSG,
  ],
};

export const getFetchChartParams = (
  reportId: string,
  instanceId: number,
  urlSuffix: string
) => {
  return {
    url: `${apiUrl}reports/?report_id=${reportId}&instance_id=${instanceId}${urlSuffix}&rep_format=chart`,
    method: "get",
    preSendDispatchTypes: [HOME_PAGE_CHART_DIALOG_DATA_LOADING],
    successDispatchTypes: [
      HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING,
      HOME_PAGE_CHART_DATA_SET,
    ],
    errorDispatchTypes: [
      HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING,
      MSG_SHOW_ERROR_MSG,
      HOME_PAGE_CHART_DATA_CLEAR,
    ],
  };
};

export const getTopNavDataParams = {
  url: `${apiUrl}datasets/top_nav_admin`,
  method: "get",
  preSendDispatchTypes: [HOME_PAGE_TOP_NAVBAR_LOADING],
  successDispatchTypes: [
    HOME_PAGE_TOP_NAVBAR_NOT_LOADING,
    HOME_PAGE_TOP_NAVBAR_DATA_SET,
  ],
  errorDispatchTypes: [HOME_PAGE_TOP_NAVBAR_NOT_LOADING, MSG_SHOW_ERROR_MSG],
};

export const getHomepageChartParams = (instanceId: number) => {
  return {
    url: `${apiUrl}reports/?report_id=home_page&instance_id=${instanceId}&rep_format=chart`,
    method: "get",
    preSendDispatchTypes: [HOME_PAGE_CHARTS_LOADING],
    successDispatchTypes: [
      HOME_PAGE_CHARTS_NOT_LOADING,
      HOME_PAGE_CHARTS_DATA_SET,
    ],
    errorDispatchTypes: [HOME_PAGE_CHARTS_NOT_LOADING, MSG_SHOW_ERROR_MSG],
  };
};
