import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

import { IconObjType } from "../../types/types";

export const reportPackageTableParams = {
  section: "reportPackages",
  refreshUrl: "report-packages/",
  reduxTableName: "reportPackageTable",
  buttonsColumn: {
    id: 3,
    genIcons: (): IconObjType[] => {
      return [
        { icon: "Edit", tooltip: "Edit Report Package" },
        { icon: "Delete", tooltip: "Delete Report Package" },
      ];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    data: [],
    buttons: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID" },
      { data: "name", title: "Report Package Name", responsivePriority: 1 },
      {
        data: "selected_reports_str",
        title: "Included Reports",
        responsivePriority: 1,
      },
      { data: null, title: "Actions" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No report packages available",
    },
    aaSorting: [[0, "asc"]],
  },
};
