import {
  GLOBAL_LOADER_HIDE,
  GLOBAL_LOADER_SHOW,
  MSG_SHOW_ERROR_MSG,
  MSG_SUPPORT_REQUEST_SUBMITTED,
  SUPPORT_DT_REFRESH_RESEL,
  SUPPORT_DIALOG_HIDE,
  SUPPORT_DIALOG_NOT_LOADING,
  SUPPORT_DIALOG_LOADING,
} from "../../../redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const getAddSupportReqParams = () => {
  return {
    url: `${apiUrl}support-cases/`,
    method: "post",
    preSendDispatchTypes: [SUPPORT_DIALOG_LOADING],
    successDispatchTypes: [
      SUPPORT_DIALOG_HIDE,
      SUPPORT_DIALOG_NOT_LOADING,
      SUPPORT_DT_REFRESH_RESEL,
      MSG_SUPPORT_REQUEST_SUBMITTED,
    ],
    errorDispatchTypes: [SUPPORT_DIALOG_NOT_LOADING, MSG_SHOW_ERROR_MSG],
  };
};

export const getApproveSupportReqParams = (caseId: number) => {
  return {
    url: `${apiUrl}support-cases/${caseId}`,
    method: "put",
    preSendDispatchTypes: [GLOBAL_LOADER_SHOW],
    successDispatchTypes: [GLOBAL_LOADER_HIDE, SUPPORT_DT_REFRESH_RESEL],
    errorDispatchTypes: [GLOBAL_LOADER_HIDE, MSG_SHOW_ERROR_MSG],
  };
};
