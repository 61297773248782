// this is a function and not a dict because keys are variables and not strings so need to populate dynamically

import {
  // auth messages
  MSG_AUTH_LOGGED_OUT,
  MSG_AUTH_LOGIN_SUCCESS,
  MSG_AUTH_LOGIN_ERROR,
  // company section messages
  MSG_CLIENT_ADD_SUCCESS,
  MSG_CLIENT_DEL_SUCCESS,
  MSG_CLIENT_UPDATE_SUCCESS,
  // moodle instance section messages
  MSG_MOODLE_INST_ADD_SUCCESS,
  MSG_MOODLE_INST_DEL_SUCCESS,
  MSG_MOODLE_INST_UPDATE_SUCCESS,
  MSG_PWD_CHANGED,
  // reports db id
  MSG_REPORT_DB_ENTRY_ADD_SUCCESS,
  MSG_REPORT_DB_ENTRY_DEL_SUCCESS,
  MSG_REPORT_DB_ENTRY_UPDATE_SUCCESS,
  // plans
  MSG_PLAN_DEL_SUCCESS,
  MSG_PLAN_ADD_SUCCESS,
  MSG_PLAN_UPDATE_SUCCESS,
  // report packages
  MSG_REPORT_PACKAGES_ADD_SUCCESS,
  MSG_REPORT_PACKAGES_DEL_SUCCESS,
  MSG_REPORT_PACKAGES_UPDATE_SUCCESS,
  // users
  MSG_USER_ADD_SUCCESS,
  MSG_USER_DEL_SUCCESS,
  MSG_USER_UPDATE_SUCCESS,
  // support
  MSG_SUPPORT_REQUEST_SUBMITTED,
  // support messages
  MSG_SUPPORT_MESSAGE_SENT,
} from "../redux/actionTypes";

const getMessagesDict = () => {
  const returnDict: any = {};

  // auth messages
  returnDict[MSG_AUTH_LOGGED_OUT] = { color: "info", text: "Logged out" };
  returnDict[MSG_AUTH_LOGIN_SUCCESS] = {
    color: "success",
    text: "Welcome to Clavia Portal",
  };
  returnDict[MSG_AUTH_LOGIN_ERROR] = {
    color: "danger",
    text: "Unable to log in. Please check your credentials.",
  };
  // company messages
  returnDict[MSG_CLIENT_ADD_SUCCESS] = {
    color: "success",
    text: `Added company`,
  };
  returnDict[MSG_CLIENT_DEL_SUCCESS] = {
    color: "success",
    text: "Deleted company",
  };
  returnDict[MSG_CLIENT_UPDATE_SUCCESS] = {
    color: "success",
    text: `Updated company`,
  };

  // moodle instance messages
  returnDict[MSG_MOODLE_INST_ADD_SUCCESS] = {
    color: "success",
    text: `Added Moodle instance`,
  };
  returnDict[MSG_MOODLE_INST_DEL_SUCCESS] = {
    color: "success",
    text: "Deleted Moodle Instance",
  };
  returnDict[MSG_MOODLE_INST_UPDATE_SUCCESS] = {
    color: "success",
    text: `Updated Moodle instance`,
  };
  // report package messages
  returnDict[MSG_REPORT_PACKAGES_ADD_SUCCESS] = {
    color: "success",
    text: "Added report package",
  };
  returnDict[MSG_REPORT_PACKAGES_DEL_SUCCESS] = {
    color: "success",
    text: "Report package deleted",
  };
  returnDict[MSG_REPORT_PACKAGES_UPDATE_SUCCESS] = {
    color: "success",
    text: "Updated report package",
  };
  // users
  returnDict[MSG_USER_ADD_SUCCESS] = {
    color: "success",
    text: "User added.",
  };
  returnDict[MSG_USER_DEL_SUCCESS] = {
    color: "success",
    text: "User deleted.",
  };
  returnDict[MSG_USER_UPDATE_SUCCESS] = {
    color: "success",
    text: "User updated.",
  };
  returnDict[MSG_PWD_CHANGED] = {
    color: "success",
    text: "Password changed.",
  };
  // plans
  returnDict[MSG_PLAN_ADD_SUCCESS] = {
    color: "success",
    text: "Plan added.",
  };
  returnDict[MSG_PLAN_DEL_SUCCESS] = {
    color: "success",
    text: "Plan deleted.",
  };
  returnDict[MSG_PLAN_UPDATE_SUCCESS] = {
    color: "success",
    text: "Plan updated.",
  };
  // support requests
  returnDict[MSG_SUPPORT_REQUEST_SUBMITTED] = {
    color: "success",
    text: "Case submitted. Our support staff will review it and get in touch.",
  };
  // support messages
  returnDict[MSG_SUPPORT_MESSAGE_SENT] = {
    color: "success",
    text: "Message added to support case",
  };
  // report db ids
  returnDict[MSG_REPORT_DB_ENTRY_ADD_SUCCESS] = {
    color: "success",
    text: "Report database entry added",
  };
  returnDict[MSG_REPORT_DB_ENTRY_DEL_SUCCESS] = {
    color: "success",
    text: "Report database entry deleted",
  };
  returnDict[MSG_REPORT_DB_ENTRY_UPDATE_SUCCESS] = {
    color: "success",
    text: "Report database entry updated",
  };
  return returnDict;
};

export default getMessagesDict;
