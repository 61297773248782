import React from "react";

import { Col, Form, Button } from "react-bootstrap";

// functions
import useGetSelectedReportParams from "functions/useGetSelectedReportParams";

// styles
import { rowWithTopMargin } from "../../../ReportGenerationPanel";

const ReportButtons: React.FC<ReportButtonsComponent> = ({
  handlePdfOrCsvClick,
  // handleChartClick,
  handleDatatableClick,
}) => {
  const reportInfo = useGetSelectedReportParams();

  return reportInfo ? (
    <>
      <Form.Row style={rowWithTopMargin}>
        <Form.Label>Report Format Options</Form.Label>
      </Form.Row>
      <Form.Row>
        <Col xs="12">
          {reportInfo?.datatable_available && (
            <ReportButton
              onClick={() => handleDatatableClick()}
              iconClr="info"
              iconStr="fa-table"
              iconLabel="View Report"
            />
          )}
          {reportInfo?.pdf_available && (
            <ReportButton
              onClick={() => handlePdfOrCsvClick("pdf")}
              iconClr="danger"
              iconStr="fa-file-pdf"
              iconLabel="PDF"
            />
          )}
          {reportInfo?.csv_available && (
            <ReportButton
              onClick={() => handlePdfOrCsvClick("csv")}
              iconClr="primary"
              iconStr="fa-file-csv"
              iconLabel="CSV"
            />
          )}
          {/*{reportInfo?.chart_available && (*/}
          {/*  <ReportButton*/}
          {/*    onClick={() => handleChartClick()}*/}
          {/*    iconClr="success"*/}
          {/*    iconStr="fa-chart-pie"*/}
          {/*    iconLabel="Chart"*/}
          {/*  />*/}
          {/*)}*/}
        </Col>
      </Form.Row>
    </>
  ) : (
    <></>
  );
};

export default ReportButtons;

const ReportButton: React.FC<ReportIconTypes> = ({
  onClick,
  iconStr,
  iconClr,
  iconLabel,
}) => {
  return (
    <Button
      style={{ marginRight: "3px", marginTop: "6px" }}
      variant={iconClr}
      onClick={onClick}
    >
      <i className={`fas ${iconStr} fa-lg indigo-text`} />
      {`  ${iconLabel}`}
    </Button>
  );
};

type ReportIconTypes = {
  onClick: () => void;
  iconStr: string;
  iconClr: "success" | "primary" | "danger" | "info";
  iconLabel: "Chart" | "CSV" | "PDF" | "View Report";
};

interface ReportButtonsComponent {
  handlePdfOrCsvClick: (type: "csv" | "pdf") => void;
  handleChartClick: () => void;
  handleDatatableClick: () => void;
}
