import { combineReducers } from "redux";

// site-wide reducers
import _authReducer from "./partials/_authReducer";
import _dialogsReducer from "./partials/_dialogsReducer";
import _globalLoader from "./partials/_globalLoader";
import _messagesReducer from "./partials/_messagesReducer";
// page-specific reducers
import companiesPageReducer from "./partials/companiesPageReducer";
import moodleInstancesPageReducer from "./partials/moodleInstancesPageReducer";
import plansPageReducer from "./partials/plansPageReducer";
import reportDbEntryReducer from "./partials/reportDbEntryReducer";
import reportPackageReducer from "./partials/reportPackageReducer";
import homePageReducer from "./partials/homePageReducer";
import userManagementPage from "./partials/userManagementPageReducer";
import supportPageReducer from "./partials/supportPageReducer";
import supportMessagesPageReducer from "./partials/supportMessagesPageReducer";

export default combineReducers({
  // site-wide
  auth: _authReducer,
  dialogs: _dialogsReducer,
  globalLoader: _globalLoader,
  messages: _messagesReducer,
  // page-specific
  companiesPage: companiesPageReducer,
  homePage: homePageReducer,
  moodleInstancesPage: moodleInstancesPageReducer,
  plansPage: plansPageReducer,
  reportDbEntries: reportDbEntryReducer,
  reportPackages: reportPackageReducer,
  supportMessagesPage: supportMessagesPageReducer,
  supportPage: supportPageReducer,
  userManagementPage: userManagementPage,
});
