import React from "react";

import { Col, Form } from "react-bootstrap";

import moment from "moment";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  HOME_PAGE_SET_REPORTS_DATE,
  HOME_PAGE_SET_REPORTS_FROM_DATE,
  HOME_PAGE_SET_REPORTS_TO_DATE,
} from "redux/actionTypes";

const ReportDatePicker: React.FC<ReportDatePickerType> = ({ type }) => {
  const reduxDate = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.dateOptions[type]
  );

  const dispatch = useDispatch();

  return (
    <Col xs="6">
      <Form.Group controlId="duedate">
        <Form.Label>
          {type === "fromDate" ? "From " : type === "toDate" ? "To " : ""}Date
        </Form.Label>
        <Form.Control
          type="date"
          value={reduxDate}
          min="2010-01-01"
          max={moment().endOf("year").format("YYYY-MM-DD")}
          onChange={(e) =>
            dispatch({
              type:
                type === "fromDate"
                  ? HOME_PAGE_SET_REPORTS_FROM_DATE
                  : type === "toDate"
                  ? HOME_PAGE_SET_REPORTS_TO_DATE
                  : HOME_PAGE_SET_REPORTS_DATE,
              payload: e.target.value ? e.target.value : "",
            })
          }
        />
      </Form.Group>
    </Col>
  );
};

export default ReportDatePicker;

type ReportDatePickerType = {
  type: "date" | "fromDate" | "toDate";
};
