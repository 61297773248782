import React, { useEffect, useRef } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import NotificationAlert from "react-notification-alert";

const NotificationsMonitor = () => {
  const notificationAlertRef = useRef(null);

  // auth messages state
  const msgState = useSelector((state: RootStateOrAny) => state.messages);

  // notification function
  const notify = (color: notificationColorsType, message: string) => {
    const options = {
      place: "tr",
      message: <div>{message}</div>,
      type: color,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 3,
    };
    // @ts-ignore
    notificationAlertRef.current.notificationAlert(options);
  };

  // authentication messages
  useEffect(() => {
    if (msgState.msgObj && msgState.msgObj.color && msgState.msgObj.text)
      notify(msgState.msgObj.color, msgState.msgObj.text);
  }, [msgState]);

  return <NotificationAlert ref={notificationAlertRef} />;
};

export default NotificationsMonitor;

type notificationColorsType =
  | "primary"
  | "success"
  | "danger"
  | "warning"
  | "info";
