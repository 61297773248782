import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const sdvoeCertificates = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "SDVoE Certificates",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "SDVoE Certificates",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      {
        data: { _: "date.display", sort: "date.@data-order" },
        title: "Date",
      },
      { data: "assessment", title: "Assessment" },
      { data: "name", title: "Name" },
      { data: "company", title: "Company" },
      { data: "email", title: "Email" },
      { data: "jk_organisation", title: "JK Organisation" },
      { data: "other_organisation", title: "Other Organisation" },
      { data: "presenter_name", title: "Presenter Name" },
      { data: "title", title: "Title" },
      { data: "jk_role", title: "JK Role" },
      { data: "country", title: "Country" },
      { data: "grade_achieved", title: "Grade Achieved" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
