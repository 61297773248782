import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const userRegistrationsTypeC = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "User Registrations (Type B)",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "User Registrations (Type B)",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      {
        data: { _: "date.display", sort: "date.@data-order" },
        title: "Date",
      },
      { data: "user", title: "User" },
      { data: "user_name", title: "Username" },
      { data: "title", title: "Title" },
      { data: "email", title: "Email" },
      { data: "city", title: "City" },
      { data: "country", title: "Country" },
      { data: "phone", title: "Phone" },
      // { data: "aboutyourself", title: "About Yourself" },
      // { data: "manager", title: "Manager" },
      // { data: "ifotherpleasespecify", title: "Specify Manager (If Other)" },
      { data: "company_name", title: "Company Name" },
      { data: "aboutyourorganization", title: "About Your Organization" },
      { data: "ifother", title: "Specify If Other Organization" },
      { data: "agreetoprocessdata", title: "Agree to Process Data" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
