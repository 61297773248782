import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const sdvoeConsolidatedNetgear = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    title: " SDVoE Consolidated Summary Report for Netgear Academy",
    buttons: [
      "colvis",
      "copyHtml5",
      // "excelHtml5",
      // "pdfHtml5",
      {
        extend: "csvHtml5",
        title: " SDVoE Consolidated Summary Report for Netgear Academy",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: " SDVoE Consolidated Summary Report for Netgear Academy",
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    data: [],
    dom: "Bfrtip",
    scrollX: true,
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "email", title: "Email" },
      { data: "city", title: "City" },
      { data: "country", title: "Country" },
      { data: "course", title: "Course" },
      { data: "first_access", title: "First Access" },
      { data: "grade", title: "Grade" },
      {
        data: {
          _: "assessment_date.display",
          sort: "assessment_date.@data-order",
        },
        title: "Assessment Date",
      },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
