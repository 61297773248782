import React, { useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import { Form, Col } from "react-bootstrap";

import { useForm } from "react-hook-form";

// select box + types
import { OptionsType, OptionTypeBase } from "react-select";

import CustomFormSelect from "../../../components/_Custom/CustomFormSelect";

const AddEditUserForm: React.FC<AddEditUserFormProps> = ({
  onSubmit,
  formSuggestions,
  formSuggestionsLoading,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm();

  const { formFields } = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog
  );

  const newEntry =
    Object.keys(formFields).length === 0 && formFields.constructor === Object;

  // set form values if they are provided (when editing) or clear (when adding)
  useEffect(() => {
    setValue("first_name", formFields.first_name ? formFields.first_name : "");
    setValue("last_name", formFields.last_name ? formFields.last_name : "");
    setValue("company", formFields.company ? formFields.company : "");
    setValue("email", formFields.email ? formFields.email : "");
    setValue("password", "");
    setValue("password2", "");
  }, [formFields, formSuggestions]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="modalForm">
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            {...register("first_name", { required: true })}
            type="text"
            placeholder=""
            isInvalid={errors.first_name?.type === "required"}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid name
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <Form.Label>Surname</Form.Label>
          <Form.Control
            {...register("last_name", { required: true })}
            type="text"
            placeholder=""
            isInvalid={errors.last_name?.type === "required"}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid surname
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Label>Company</Form.Label>
        <CustomFormSelect
          loading={formSuggestionsLoading}
          options={formSuggestions.company_options}
          formFields={formFields}
          placeholder="Select User Company"
          fieldName="company"
          control={control}
          showValidationError={errors.company?.type === "required"}
          validationMessage="Please provide a valid company name"
          required={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Email </Form.Label>
        <Form.Control
          {...register("email", { required: true })}
          isInvalid={errors.email?.type === "required"}
          type="text"
          placeholder=""
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid e-mail address
        </Form.Control.Feedback>
      </Form.Group>
      {newEntry ? (
        <Form.Row>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              {...register("password", { required: newEntry })}
              isInvalid={errors.password?.type === "required"}
              type="password"
              placeholder=""
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Repeat Password</Form.Label>
            <Form.Control
              {...register("password2", {
                required: newEntry,
                validate: {
                  passwordsMatch: (value) =>
                    value === getValues().password ||
                    "Email confirmation error!",
                },
              })}
              isInvalid={
                errors.password2?.type === "required" ||
                (errors.password2?.type === "passwordsMatch" &&
                  getValues().password)
              }
              type="password"
              placeholder=""
            />
            <Form.Control.Feedback type="invalid">
              {errors.password2?.type === "required" ? (
                <>Please provide a valid password</>
              ) : errors.password2?.type === "passwordsMatch" ? (
                <>Passwords dot not match</>
              ) : (
                <></>
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default AddEditUserForm;

interface AddEditUserFormProps {
  onSubmit: (event: any) => void;
  formSuggestions: FormSuggestionsType;
  formSuggestionsLoading: boolean;
  dialogLoading: boolean;
  newEntry: boolean;
}

interface FormSuggestionsType {
  company_options: OptionsType<OptionTypeBase>;
}
