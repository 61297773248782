import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { DataTableParamsType } from "../../components/_Custom/DataTables/DataTable";

// gets table props from redux based on provided redux section and table name
// if names are undefined, returns null for all props
const useGetTableReduxPropsOrNull: (
  tableParams: DataTableParamsType | null
) => GetTableReduxPropsOrNullType = (tableParams) => {
  const reduxSection = tableParams?.section ? tableParams.section : undefined;
  const reduxTableName = tableParams?.reduxTableName
    ? tableParams.reduxTableName
    : undefined;

  const [reduxPageFilter, setReduxPageFilter] = useState(null);
  const [reduxTriggerDtRefresh, setReduxTriggerDtRefresh] = useState(null);
  const [reduxRefreshUrl, setReduxRefreshUrl] = useState(null);
  const [reduxViewedPage, setReduxViewedPage] = useState(null);
  const [reduxSelectedRow, setReduxSelectedRow] = useState(null);

  const { pageFilter, triggerDtRefresh, refreshUrl, viewedPage, selectedRow } =
    reduxSection && reduxTableName
      ? useSelector(
          (state) =>
            // @ts-ignore
            state[reduxSection]["tables"][reduxTableName]
        )
      : {
          pageFilter: undefined,
          triggerDtRefresh: undefined,
          refreshUrl: undefined,
          viewedPage: undefined,
          selectedRow: undefined,
        };

  useEffect(() => {
    // if redux section is provided, get redux section props, if not then set props to null
    if (reduxSection) {
      setReduxPageFilter(pageFilter);
      setReduxTriggerDtRefresh(triggerDtRefresh);
      setReduxRefreshUrl(refreshUrl);
      setReduxViewedPage(viewedPage);
      setReduxSelectedRow(selectedRow);
    } else {
      setReduxPageFilter(null);
      setReduxTriggerDtRefresh(null);
      setReduxRefreshUrl(null);
      setReduxViewedPage(null);
      setReduxSelectedRow(null);
    }
  }, [pageFilter, triggerDtRefresh, refreshUrl, viewedPage, selectedRow]);

  return {
    reduxPageFilter: reduxPageFilter,
    reduxTriggerDtRefresh: reduxTriggerDtRefresh,
    reduxRefreshUrl: reduxRefreshUrl,
    reduxViewedPage: reduxViewedPage,
    reduxSelectedRow: reduxSelectedRow,
  };
};

export default useGetTableReduxPropsOrNull;

type GetTableReduxPropsOrNullType = {
  reduxPageFilter: number | null;
  reduxTriggerDtRefresh: string | null;
  reduxRefreshUrl: string | null;
  reduxViewedPage: number | null;
  reduxSelectedRow: number | null;
};
