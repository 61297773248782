import React, { useEffect } from "react";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { USER_MANAGEMENT_HIDE_CP_DIALOG } from "../../../redux/actionTypes";
import { getEditUserPassParams } from "config/api_calls";
import apiCall from "../../../functions/apiCall";

import { Button, Col, Form, Modal } from "react-bootstrap";

import { useForm } from "react-hook-form";

import CustomSpinner from "../CustomSpinner";

const ChangePasswordDialog: React.FC<ChangePasswordDialogPropTypes> = ({
  userId,
  userName,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (formData: formDataTypes) => {
    dispatch(apiCall(formData, getEditUserPassParams(userId)));
  };

  const { open, loading } = useSelector(
    (state: RootStateOrAny) =>
      state.userManagementPage.dialogs.changePasswordDialog
  );

  useEffect(() => {
    if (open) {
      setValue("password", "");
      setValue("password2", "");
    }
  }, [open]);

  return (
    <Modal
      className="modal-primary"
      show={open}
      onHide={() => dispatch({ type: USER_MANAGEMENT_HIDE_CP_DIALOG })}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Change User Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <CustomSpinner height={150} />
        ) : (
          <>
            <h5>{`To change password for ${userName} (DB ID ${userId}), enter new password details below:`}</h5>
            <Form onSubmit={handleSubmit(onSubmit)} id="changePassForm">
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    {...register("password", { required: true })}
                    type="password"
                    placeholder=""
                    isInvalid={errors.password?.type === "required"}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a password
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Repeat Password</Form.Label>
                  <Form.Control
                    {...register("password2", {
                      required: true,
                      validate: {
                        passwordsMatch: (value) =>
                          value === getValues().password ||
                          "Email confirmation error!",
                      },
                    })}
                    type="password"
                    placeholder=""
                    isInvalid={
                      errors.password2?.type === "required" ||
                      (errors.password2?.type === "passwordsMatch" &&
                        getValues().password)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password2?.type === "required" ? (
                      <>Please provide a valid password</>
                    ) : errors.password2?.type === "passwordsMatch" ? (
                      <>Passwords dot not match</>
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" form="changePassForm">
          Submit
        </Button>
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: USER_MANAGEMENT_HIDE_CP_DIALOG })}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordDialog;

interface ChangePasswordDialogPropTypes {
  userId: number;
  userName: string;
}

interface formDataTypes {
  password: string;
  password2: string;
}
