import {
  MOODLE_INSTANCES_SET_DT_PAGE,
  MOODLE_INSTANCES_SET_DT_ROW,
  MOODLE_INSTANCES_DT_REFRESH,
  MOODLE_INSTANCES_DT_REFRESH_RESEL,
} from "redux/actionTypes";

import { RootStateOrAny } from "react-redux";

const initialState = {
  tables: {
    moodleInstancesDataTable: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {},
    },
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case MOODLE_INSTANCES_SET_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          moodleInstancesDataTable: {
            ...state.tables.moodleInstancesDataTable,
            viewedPage: action.payload,
          },
        },
      };
    case MOODLE_INSTANCES_SET_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          moodleInstancesDataTable: {
            ...state.tables.moodleInstancesDataTable,
            selectedRow: action.payload,
          },
        },
      };
    case MOODLE_INSTANCES_DT_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          moodleInstancesDataTable: {
            ...state.tables.moodleInstancesDataTable,
            selectedRow: null,
            triggerDtRefresh: {},
          },
        },
      };
    case MOODLE_INSTANCES_DT_REFRESH_RESEL:
      return {
        ...state,
        tables: {
          ...state.tables,
          moodleInstancesDataTable: {
            ...state.tables.moodleInstancesDataTable,
            selectedRow: action.payload.data.id,
            triggerDtRefresh: {},
          },
        },
      };
    default:
      return state;
  }
}
