import React, { useEffect } from "react";

import { Col, Form, Row } from "react-bootstrap";

import CustomSpinner from "../../../components/_Custom/CustomSpinner";

import { RootStateOrAny, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

const AddEditMoodleInstanceForm: React.FC<AddEditMoodleInstanceFormProps> = ({
  onSubmit,
  dialogLoading,
}) => {
  const formFields = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog.formFields
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  // update fields from pre-populated values when they change (add is empty, edit is not)
  useEffect(() => {
    setValue("name", formFields.name ? formFields.name : "");
    setValue("url", formFields.url ? formFields.url : "");
    setValue("username", formFields.username ? formFields.username : "");
    setValue("password", formFields.password ? formFields.password : "");
    setValue(
      "report_img_url",
      formFields.report_img_url ? formFields.report_img_url : ""
    );
    setValue(
      "database_prefix",
      formFields.database_prefix ? formFields.database_prefix : ""
    );
    setValue(
      "report_color",
      formFields.report_color ? formFields.report_color : ""
    );
    setValue(
      "database_name",
      formFields.database_name ? formFields.database_name : ""
    );
  }, [formFields]);

  return dialogLoading ? (
    <CustomSpinner height={300} />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} id="modalForm">
      <Row>
        <Col md={6}>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Label>Instance Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("name", { required: true })}
              isInvalid={errors.name?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name for the Moodle Instance
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("url", { required: true })}
              isInvalid={errors.url?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid URL to access Moodle database instance
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Database Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("database_name", { required: true })}
              isInvalid={errors.database_name?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid database name for the instance
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>DB Username</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("username", { required: true })}
              isInvalid={errors.username?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Moodle instance database username is required
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Report Color</Form.Label>
            <Form.Control
              type="report_color"
              {...register("report_color", { required: true })}
              isInvalid={errors.report_color?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a hex code for report colour
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Database Prefix</Form.Label>
            <Form.Control
              type="database_prefix"
              {...register("database_prefix", { required: true })}
              isInvalid={errors.database_prefix?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a database prefix
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Report Image URL</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("report_img_url", { required: true })}
              isInvalid={errors.report_img_url?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid image URL
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>DB Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              {...register("password", { required: true })}
              isInvalid={errors.password?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default AddEditMoodleInstanceForm;

interface AddEditMoodleInstanceFormProps {
  onSubmit: (event: any) => void;
  dialogLoading: boolean;
}
