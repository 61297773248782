import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

import { IconObjType } from "../../types/types";

export const plansTableParams = {
  section: "plansPage",
  refreshUrl: "plans/",
  reduxTableName: "plansDataTable",
  buttonsColumn: {
    id: 3,
    genIcons: (): IconObjType[] => {
      return [
        { icon: "Edit", tooltip: "Edit Plan" },
        { icon: "Delete", tooltip: "Delete Plan" },
      ];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID" }, // 0
      { data: "name", title: "Plan Name", responsivePriority: 1 }, // 1
      { data: "enrollments", title: "Certificates" }, // 2
      { data: null, title: "Actions" }, // 3
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No plans available",
    },
    aaSorting: [[0, "asc"]],
  },
};
