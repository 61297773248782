import React, { useEffect, useState } from "react";
// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { HOME_PAGE_SELECT_MOODLE_INSTANCE } from "redux/actionTypes";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
// custom components
import ChartDialog from "components/_Custom/Dialogs/ChartDialog";
import TopAdminBar from "components/_Custom/HomePage/TopAdminBar";
import CustomSingleSelect from "components/_Custom/CustomSingleSelect";
import EnrolmentCountCard from "components/_Custom/HomePage/EnrolmentCountCard";
import UserInfoCard from "components/_Custom/HomePage/UserInfoCard";
import ReportGenerationPanel from "components/_Custom/HomePage/ReportGenerationPanel/ReportGenerationPanel";
import PlanInfoCard from "../../components/_Custom/HomePage/PlanInfoCard";
import CertificatesCard from "components/_Custom/HomePage/CertificatesCard";
// other components
import "datatables.net";
// api calls
import apiCall from "functions/apiCall";
import {
  getHomepageChartParams,
  populateReportsFormParams,
} from "config/api_calls";
// types
import { OptionTypeBase } from "react-select";

const HomePage = () => {
  const dispatch = useDispatch();

  const [activeInstanceName, setActiveInstanceName] = useState("");

  const formFieldsLoading = useSelector(
    (state: RootStateOrAny) => state.homePage.formFields.loading
  );

  const instanceOptions = useSelector(
    (state: RootStateOrAny) => state.homePage.formFields.values.instance_options
  );

  const subscriptionIsActive = useSelector(
    (state: RootStateOrAny) => state.auth.user.subscription_active
  );

  const { admin } = useSelector((state: RootStateOrAny) => state.auth.user);

  const { selectedMoodleInstanceId } = useSelector(
    (state: RootStateOrAny) => state.homePage
  );

  useEffect(() => {
    dispatch(apiCall(null, populateReportsFormParams));
  }, []);

  // when moodle instance changes, load chart info
  useEffect(() => {
    if (selectedMoodleInstanceId || selectedMoodleInstanceId === 0)
      dispatch(apiCall({}, getHomepageChartParams(selectedMoodleInstanceId)));
  }, [selectedMoodleInstanceId]);

  // every time moodle instance is selected, show its name at the top
  useEffect(() => {
    if (selectedMoodleInstanceId && instanceOptions) {
      const selectedOption = instanceOptions.find(
        (option: OptionTypeBase) => option.value === selectedMoodleInstanceId
      );
      setActiveInstanceName(selectedOption.label);
    }
  }, [selectedMoodleInstanceId, instanceOptions]);

  // selected the first option in moodle instance options on load of options
  useEffect(() => {
    if (
      !selectedMoodleInstanceId &&
      instanceOptions &&
      instanceOptions.length
    ) {
      dispatch({
        type: HOME_PAGE_SELECT_MOODLE_INSTANCE,
        payload: instanceOptions[0].value,
      });
    }
  }, [instanceOptions]);

  return subscriptionIsActive ? (
    <>
      <Container fluid>
        {admin ? <TopAdminBar /> : <></>}
        <Row>
          <Col xs="12">
            <h4 className="title">{`${activeInstanceName}`}</h4>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {instanceOptions && instanceOptions.length > 1 ? (
              <Row style={{ marginBottom: "10px" }}>
                <Col xs="10" sm="6" md="4">
                  <CustomSingleSelect
                    isDisabled={formFieldsLoading}
                    placeholder="Select Moodle Instance"
                    options={instanceOptions}
                    value={selectedMoodleInstanceId}
                    onChange={(selectedValue: number) =>
                      dispatch({
                        type: HOME_PAGE_SELECT_MOODLE_INSTANCE,
                        payload: selectedValue,
                      })
                    }
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {/*<Row>*/}
        {/*  <Col xs="12">*/}
        {/*    <h4 className="title">Charts & Graphs</h4>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row>
          <Col xs="12" sm="6" md="3">
            <PlanInfoCard />
          </Col>
          <Col xs="12" sm="6" md="3">
            <UserInfoCard />
          </Col>
          <Col xs="12" sm="6" md="3">
            <EnrolmentCountCard />
          </Col>
          <Col xs="12" sm="6" md="3">
            <CertificatesCard />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <ReportGenerationPanel />
          </Col>
        </Row>
      </Container>
      <ChartDialog />
    </>
  ) : (
    <SuspensionMessage />
  );
};

export default HomePage;

const SuspensionMessage: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <p>
            Your portal has been suspended due to a problem with your account.
          </p>
          <p>
            Please contact{" "}
            <a href="mailto: billing@claviagroup.com">
              billing@claviagroup.com
            </a>{" "}
            to re-activate this service.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
