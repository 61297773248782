import { RootStateOrAny } from "react-redux";

import moment from "moment";

import {
  HOME_PAGE_SELECT_MOODLE_INSTANCE,
  HOME_PAGE_SELECT_REPORT,
  // form fields
  HOME_PAGE_FORM_FIELDS_SET,
  HOME_PAGE_FORM_FIELDS_LOADING,
  HOME_PAGE_FORM_FIELDS_NOT_LOADING,
  // top navbar
  HOME_PAGE_TOP_NAVBAR_LOADING,
  HOME_PAGE_TOP_NAVBAR_NOT_LOADING,
  HOME_PAGE_TOP_NAVBAR_DATA_SET,
  // charts
  HOME_PAGE_CHART_DATA_SET,
  HOME_PAGE_CHART_DATA_CLEAR,
  HOME_PAGE_HIDE_CHART_DIALOG,
  HOME_PAGE_SHOW_CHART_DIALOG,
  HOME_PAGE_CHART_DIALOG_DATA_LOADING,
  HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING,
  HOME_PAGE_CHARTS_DATA_SET,
  HOME_PAGE_CHARTS_LOADING,
  HOME_PAGE_CHARTS_NOT_LOADING,
  // report params
  HOME_PAGE_REPORTS_SET_CHECKBOX_URL_SUFFIX,
  // report dates
  HOME_PAGE_SET_REPORTS_FROM_DATE,
  HOME_PAGE_SET_REPORTS_TO_DATE,
  HOME_PAGE_SET_REPORTS_YEAR,
  HOME_PAGE_SET_REPORTS_FROM_YEAR,
  HOME_PAGE_SET_REPORTS_TO_YEAR,
  HOME_PAGE_SET_REPORTS_DATE,
  HOME_PAGE_REPORTS_ADD_ALL_MONTHS,
  HOME_PAGE_REPORTS_ADD_MONTH,
  HOME_PAGE_REPORTS_REMOVE_ALL_MONTHS,
  HOME_PAGE_REPORTS_REMOVE_MONTH,
  HOME_PAGE_REPORTS_SET_DATE_URL_SUFFIX,
} from "../../actionTypes";

const initialState = {
  formFields: {
    values: [],
    loading: false,
  },
  topBar: {
    loading: false,
    values: {},
    error: "",
  },
  topChart: {
    chartDataLoading: false,
    chartData: [],
  },
  reports: {
    selectedReport: null,
    checkboxUrlSuffix: "",
    dateUrlSuffix: "",
    dateOptions: {
      months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      date: moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      fromDate: moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      toDate: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD"),
      year: new Date().getFullYear(),
      fromYear: new Date().getFullYear() - 1,
      toYear: new Date().getFullYear(),
    },
  },
  chartDialog: {
    reportId: "",
    urlSuffix: "",
    show: false,
    loading: false,
    data: [],
  },
  selectedMoodleInstanceId: null,
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case HOME_PAGE_FORM_FIELDS_LOADING:
      return {
        ...state,
        formFields: {
          ...state.formFields,
          loading: true,
        },
      };
    case HOME_PAGE_FORM_FIELDS_NOT_LOADING:
      return {
        ...state,
        formFields: {
          ...state.formFields,
          loading: false,
        },
      };
    case HOME_PAGE_FORM_FIELDS_SET:
      return {
        ...state,
        formFields: {
          ...state.formFields,
          values: action.payload.data,
        },
      };
    case HOME_PAGE_SELECT_MOODLE_INSTANCE:
      return {
        ...state,
        selectedMoodleInstanceId: action.payload,
      };
    case HOME_PAGE_TOP_NAVBAR_LOADING:
      return {
        ...state,
        topBar: {
          ...state.topBar,
          loading: true,
        },
      };
    case HOME_PAGE_TOP_NAVBAR_NOT_LOADING:
      return {
        ...state,
        topBar: {
          ...state.topBar,
          loading: false,
        },
      };
    case HOME_PAGE_TOP_NAVBAR_DATA_SET:
      return {
        ...state,
        topBar: {
          ...state.topBar,
          values: action.payload.data,
        },
      };
    // Chart Dialog
    case HOME_PAGE_SHOW_CHART_DIALOG:
      return {
        ...state,
        chartDialog: {
          ...state.chartDialog,
          show: true,
          urlSuffix: action.payload.urlSuffix,
          reportId: action.payload.reportId,
        },
      };
    case HOME_PAGE_HIDE_CHART_DIALOG:
      return {
        ...state,
        chartDialog: {
          ...state.chartDialog,
          show: false,
        },
      };
    case HOME_PAGE_CHART_DIALOG_DATA_LOADING:
      return {
        ...state,
        chartDialog: {
          ...state.chartDialog,
          loading: true,
        },
      };
    case HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING:
      return {
        ...state,
        chartDialog: {
          ...state.chartDialog,
          loading: false,
        },
      };
    case HOME_PAGE_CHART_DATA_SET:
      return {
        ...state,
        chartDialog: {
          ...state.chartDialog,
          data: action.payload.data,
        },
      };
    case HOME_PAGE_CHART_DATA_CLEAR:
      return {
        ...state,
        chartDialog: {
          ...state.chartDialog,
          data: [],
        },
      };
    // Enrolment chart
    case HOME_PAGE_CHARTS_LOADING:
      return {
        ...state,
        topChart: {
          ...state.topChart,
          chartDataLoading: true,
        },
      };
    case HOME_PAGE_CHARTS_NOT_LOADING:
      return {
        ...state,
        topChart: {
          ...state.topChart,
          chartDataLoading: false,
        },
      };
    case HOME_PAGE_CHARTS_DATA_SET:
      return {
        ...state,
        topChart: {
          ...state.topChart,
          chartData: action.payload.data,
        },
      };
    // reports
    case HOME_PAGE_SET_REPORTS_DATE:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            date: action.payload,
          },
        },
      };
    case HOME_PAGE_SET_REPORTS_FROM_DATE:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            fromDate: action.payload,
          },
        },
      };
    case HOME_PAGE_SET_REPORTS_TO_DATE:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            toDate: action.payload,
          },
        },
      };
    case HOME_PAGE_SET_REPORTS_YEAR:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            year: action.payload,
          },
        },
      };
    case HOME_PAGE_SET_REPORTS_FROM_YEAR:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            fromYear: action.payload,
          },
        },
      };
    case HOME_PAGE_SET_REPORTS_TO_YEAR:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            toYear: action.payload,
          },
        },
      };
    case HOME_PAGE_REPORTS_ADD_ALL_MONTHS:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          },
        },
      };
    case HOME_PAGE_REPORTS_REMOVE_ALL_MONTHS:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            months: [],
          },
        },
      };
    case HOME_PAGE_REPORTS_ADD_MONTH:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            months: !state.reports.dateOptions.months.includes(action.payload)
              ? [...state.reports.dateOptions.months, action.payload]
              : [...state.reports.dateOptions.months],
          },
        },
      };
    case HOME_PAGE_REPORTS_REMOVE_MONTH:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateOptions: {
            ...state.reports.dateOptions,
            months: state.reports.dateOptions.months.includes(action.payload)
              ? [...state.reports.dateOptions.months].filter((month) => {
                  return month !== action.payload;
                })
              : state.reports.dateOptions.months,
          },
        },
      };
    case HOME_PAGE_SELECT_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: action.payload,
        },
      };
    case HOME_PAGE_REPORTS_SET_CHECKBOX_URL_SUFFIX:
      return {
        ...state,
        reports: {
          ...state.reports,
          checkboxUrlSuffix: action.payload,
        },
      };
    case HOME_PAGE_REPORTS_SET_DATE_URL_SUFFIX:
      return {
        ...state,
        reports: {
          ...state.reports,
          dateUrlSuffix: action.payload,
        },
      };
    default:
      return state;
  }
}
