import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

import { IconObjType } from "../../types/types";

export const moodleInstancesTableParams = {
  section: "moodleInstancesPage",
  refreshUrl: "moodle-instances/",
  reduxTableName: "moodleInstancesDataTable",
  buttonsColumn: {
    id: 7,
    genIcons: (): IconObjType[] => {
      return [
        { icon: "Edit", tooltip: "Edit Moodle Instance" },
        { icon: "Delete", tooltip: "Delete Moodle Instance" },
      ];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID" }, // 0
      { data: "name", title: "Moodle Instance Name", responsivePriority: 1 }, // 1
      { data: "url", title: "URL" }, // 2
      { data: "database_name", title: "DB Name" }, // 3
      { data: "username", title: "DB Username" }, // 4
      {
        data: "report_color",
        title: "Report Colour (HEX)",
        className: "dt-body-center",
      }, // 5
      {
        data: "database_prefix",
        title: "Database Prefix",
        className: "dt-body-center",
      }, // 6
      { data: null, title: "Actions" }, // 7
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No Moodle instances available",
    },
    aaSorting: [[0, "asc"]],
  },
};
