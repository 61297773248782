import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

import { IconObjType } from "../../types/types";

export const reportDbEntryTableParams = {
  section: "reportDbEntries",
  refreshUrl: "report-db-entry/",
  reduxTableName: "reportDbEntryTable",
  buttonsColumn: {
    id: 7,
    genIcons: (): IconObjType[] => {
      return [
        { icon: "Edit", tooltip: "Edit Report DB Entry" },
        { icon: "Delete", tooltip: "Delete Report DB Entry" },
      ];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID" },
      { data: "title", title: "Report Title", responsivePriority: 1 },
      {
        data: "description",
        title: "Description",
        responsivePriority: 1,
      },
      { data: "id_str", title: "ID String", responsivePriority: 1 },
      {
        data: "format_options",
        title: "Format Options",
        responsivePriority: 1,
      },
      {
        data: "accepts_fields",
        title: "Accepts Fields",
        responsivePriority: 1,
      },
      {
        data: "checkbox_filters",
        title: "Checkbox Filters",
        responsivePriority: 1,
      },
      { data: null, title: "Actions" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No reports available",
    },
    aaSorting: [[1, "asc"]],
  },
};
