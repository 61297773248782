import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const supportMessagesTableParams = {
  section: "supportMessagesPage",
  reduxTableName: "supportMessageTable",
  refreshUrl: "",
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      {
        data: {
          _: "time_posted_obj.display",
          sort: "time_posted_obj.@data-order",
        },
        title: "Date & Time Posted",
        width: "200",
      },
      { data: "user_str", title: "User", width: "250" },
      {
        data: "message",
        title: "Message",
        width: "500",
        responsivePriority: 1,
      },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No messages in this support case",
    },
    aaSorting: [[0, "-asc"]],
  },
};
