import React, { useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import { Form } from "react-bootstrap";

import CustomSpinner from "components/_Custom/CustomSpinner";

const AddEditPlanForm: React.FC<AddEditPlanFormProps> = ({
  onSubmit,
  dialogLoading,
}) => {
  const formFields = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog.formFields
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  // update fields from pre-populated values when they change (add is empty, edit is not)
  useEffect(() => {
    setValue("name", formFields.name ? formFields.name : "");
    setValue(
      "enrollments",
      formFields.enrollments ? formFields.enrollments : 0
    );
  }, [formFields]);

  return dialogLoading ? (
    <CustomSpinner height={180} />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} id="modalForm">
      <Form.Group controlId="formBasicCheckbox">
        <Form.Label>Plan Name</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          {...register("name", { required: true })}
          isInvalid={errors.name?.type === "required"}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid plan name
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Label>Enrollments</Form.Label>
        <Form.Control
          type="number"
          placeholder=""
          {...register("enrollments", { required: true })}
          isInvalid={errors.name?.type === "required"}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid enrollment number
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default AddEditPlanForm;

interface AddEditPlanFormProps {
  onSubmit: (event: any) => void;
  dialogLoading: boolean;
}
