import { GLOBAL_LOADER_SHOW, GLOBAL_LOADER_HIDE } from "redux/actionTypes";

import { RootStateOrAny } from "react-redux";

const initialState = {
  showLoader: false,
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case GLOBAL_LOADER_SHOW:
      return {
        ...state,
        showLoader: true,
      };
    case GLOBAL_LOADER_HIDE:
      return {
        ...state,
        showLoader: false,
      };
    default:
      return state;
  }
}
