import {
  MSG_SHOW_ERROR_MSG,
  SUPPORT_MESSAGE_DIALOG_HIDE,
  SUPPORT_MESSAGE_DIALOG_LOADING,
  SUPPORT_MESSAGE_DIALOG_NOT_LOADING,
  SUPPORT_MESSAGE_DT_REFRESH_RESEL,
  MSG_SUPPORT_MESSAGE_SENT,
  GLOBAL_LOADER_SHOW,
  GLOBAL_LOADER_HIDE,
} from "../../../redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const getAddSupportMessageParams = (caseId: number) => {
  return {
    url: `${apiUrl}support-cases/${caseId}/messages`,
    method: "post",
    preSendDispatchTypes: [SUPPORT_MESSAGE_DIALOG_LOADING],
    successDispatchTypes: [
      SUPPORT_MESSAGE_DIALOG_NOT_LOADING,
      SUPPORT_MESSAGE_DIALOG_HIDE,
      SUPPORT_MESSAGE_DT_REFRESH_RESEL,
      MSG_SUPPORT_MESSAGE_SENT,
    ],
    errorDispatchTypes: [
      SUPPORT_MESSAGE_DIALOG_NOT_LOADING,
      MSG_SHOW_ERROR_MSG,
    ],
  };
};

export const getApproveSupportReqFromPageParams = (caseId: number) => {
  return {
    url: `${apiUrl}support-cases/${caseId}`,
    method: "put",
    preSendDispatchTypes: [GLOBAL_LOADER_SHOW],
    successDispatchTypes: [
      GLOBAL_LOADER_HIDE,
      SUPPORT_MESSAGE_DT_REFRESH_RESEL, // this refreshes stats at the top rather than table
    ],
    errorDispatchTypes: [GLOBAL_LOADER_HIDE, MSG_SHOW_ERROR_MSG],
  };
};
