import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";
import { IconObjType } from "../../types/types";

export const supportTableParamsUser = {
  section: "supportPage",
  refreshUrl: "support-cases/",
  reduxTableName: "supportCaseTable",
  buttonsColumn: {
    id: 4,
    genIcons: (): IconObjType[] => {
      return [{ icon: "Visibility", tooltip: "View Messages" }];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID", width: "50" },
      {
        data: { _: "date_obj.display", sort: "date_obj.@data-order" },
        title: "Last Message",
        width: "150",
      },
      {
        data: "name",
        title: "Name",
        responsivePriority: 1,
        width: "400",
      },
      {
        data: "status_str",
        title: "Status",
        responsivePriority: 1,
        width: "100",
      },
      { data: null, title: "Actions", width: "100" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "There are currently no support cases",
    },
    aaSorting: [[1, "desc"]],
  },
};
