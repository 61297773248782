import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";
import { IconObjType } from "../../types/types";

export const userManagementTableParams = {
  section: "userManagementPage",
  refreshUrl: "acc/users/",
  reduxTableName: "userManagementDataTable",
  buttonsColumn: {
    id: 4,
    genIcons: (): IconObjType[] => {
      return [
        { icon: "Edit", tooltip: "Edit User" },
        { icon: "LockIcon", tooltip: "Change User Password" },
        { icon: "Delete", tooltip: "Delete User" },
      ];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID" }, // 0
      { data: "full_name", title: "Name", responsivePriority: 1 }, // 1
      {
        data: "email",
        title: "Email",
        responsivePriority: 1,
      }, // 2
      {
        data: "company_str",
        title: "Company",
        responsivePriority: 1,
      }, // 3
      { data: null, title: "Actions" }, // 4
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No users available",
    },
    aaSorting: [[0, "asc"]],
  },
};
