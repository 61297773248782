import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const coursesByCategoryStructure = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    title: "Courses By Category Structure, Role and Visibility",
    buttons: [
      "colvis",
      "copyHtml5",
      // "excelHtml5",
      // "pdfHtml5",
      {
        extend: "csvHtml5",
        title: "Courses By Category Structure, Role and Visibility",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Courses By Category Structure, Role and Visibility",
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    data: [],
    dom: "Bfrtip",
    scrollX: true,
    select: {
      style: "single",
    },
    columns: [
      { data: "course", title: "Course" },
      { data: "category", title: "Category" },
      { data: "parent_category", title: "Parent Category" },
      { data: "top_category", title: "Top Category" },
      { data: "access_roles", title: "Access Roles" },
      { data: "visible_to_public", title: "Visible To Public" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
