import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const totalCertificatesByCompanyAndCountry = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Total Certificates Awarded by Company and Country",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Total Certificates Awarded by Company and Country",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "company", title: "Company" },
      { data: "country", title: "Country" },
      { data: "total_certifications", title: "Total Certificates" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[2, "desc"]],
  },
};
