import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

import { IconObjType } from "../../types/types";

// button
const resolveBtn: IconObjType = {
  icon: "CheckCircle",
  tooltip: "Mark as Resolved",
};
const markAsPendingUserInputBtn: IconObjType = {
  icon: "ArrowForward",
  tooltip: "Mark as Pending User Input",
};
const viewCaseBtn: IconObjType = {
  icon: "Visibility",
  tooltip: "View Messages",
};

export const supportTableParamsAdmin = {
  section: "supportPage",
  refreshUrl: "support-cases/",
  reduxTableName: "supportCaseTable",
  buttonsColumn: {
    id: 5,
    genIcons: (dtRow: any): IconObjType[] => {
      let approvalButtons: any[] = [];
      if (dtRow.status === 1 || dtRow.status === 2 || dtRow.status === 5)
        approvalButtons = [resolveBtn, markAsPendingUserInputBtn];
      else if (dtRow.status === 3) approvalButtons = [resolveBtn];
      else if (dtRow.status === 4)
        approvalButtons = [markAsPendingUserInputBtn];

      return [...approvalButtons, viewCaseBtn];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    buttons: [],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID", width: "50" },
      {
        data: { _: "date_obj.display", sort: "date_obj.@data-order" },
        title: "Last Message",
        width: "150",
      },
      {
        data: "user_name_str",
        title: "User",
        responsivePriority: 1,
        width: "200",
      },
      {
        data: "name",
        title: "Name",
        responsivePriority: 1,
        width: "700",
      },
      {
        data: "status_str",
        title: "Status",
        responsivePriority: 1,
        width: "100",
      },
      { data: null, title: "Actions", width: "100" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "There are currently no support cases",
    },
    aaSorting: [[1, "desc"]],
  },
};
