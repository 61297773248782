// takes the table params from constants' file, checks if there should be button(s) in a row, if there should, then
// adds those buttons to the specified column, if not, returns the params as is

import React, { useEffect } from "react";
import ReactDOM from "react-dom";

// tooltip library
import Tippy from "@tippyjs/react";

// buttons
import { buttonParams } from "../../components/_Custom/DataTables/DATATABLE_BUTTONS";

// types
import { DataTableParamsType } from "../../components/_Custom/DataTables/DataTable";
import { IconObjType } from "../../types/types";

const rowButtonStyles = {
  button: {
    backgroundColor: "transparent",
    margin: "0 0 0 0px",
    padding: "0px",
    "& svg,& .fab,& .fas,& .far,& .fal": {
      marginRight: "0px",
    },
    fontSize: "0.875rem",
    minWidth: "28px",
    boxSizing: "border-box",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "500",
    lineHeight: "1.75",
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    border: 0,
    cursor: "pointer",
  },
  icon: {
    verticalAlign: "middle",
    top: "-1px",
    position: "relative",
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    flexShrink: "0",
  },
  label: {
    width: "100%",
    display: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
  },
};

export default function useAddButtonsToTable(table: DataTableParamsType) {
  useEffect(() => {
    // find which column has a button (if any)
    if (table.buttonsColumn) {
      const buttonsColumnId = table.buttonsColumn.id;
      const genIcons = table.buttonsColumn.genIcons;

      table.tableNativeParams.columnDefs = [];
      table.tableNativeParams.columnDefs.push({
        targets: [buttonsColumnId],
        // @ts-ignore
        createdCell: (td: string, cellData: any, rowData: any, row: number) => {
          ReactDOM.render(
            // @ts-ignore
            <>
              {genIcons(rowData).map((iconObj: IconObjType, index: number) => {
                return (
                  <Tippy
                    key={iconObj.icon + index}
                    content={iconObj.tooltip}
                    hideOnClick={true}
                    onShow={(instance) => {
                      setTimeout(() => {
                        instance.hide();
                      }, 1000);
                    }}
                  >
                    <button
                      // @ts-ignore
                      style={{
                        ...rowButtonStyles.button,
                        // @ts-ignore
                        color: buttonParams[iconObj.icon].color,
                        outline: "none",
                      }}
                      // @ts-ignore
                      tabIndex="0"
                      type="button"
                      // style={{ color: buttonParams[iconObj.icon].color }}
                      data-id={`dticon-${iconObj.icon}-${row}`}
                    >
                      <span style={rowButtonStyles.label}>
                        <svg
                          // @ts-ignore
                          style={rowButtonStyles.icon}
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          {/*@ts-ignore*/}
                          <path d={buttonParams[iconObj.icon].pathD} />
                        </svg>
                      </span>
                    </button>
                  </Tippy>
                );
              })}
            </>,
            td
          );
        },
      });
    }
  }, [table]);

  return table.tableNativeParams;
}
