import axiosApiInstance from "./axiosApiInstance";
import { Dispatch } from "react";

const apiCall = (payload: any, params: paramsObjType) => (
  dispatch: Dispatch<any>
) => {
  const {
    url,
    method,
    preSendDispatchTypes,
    successDispatchTypes,
    errorDispatchTypes,
  } = params;

  // form request params
  const requestParams = {
    url: url,
    method: method,
    responseType: "json",
    data: {},
  };

  if (payload) {
    requestParams.data = payload;
  }

  // dispatch pre-request types (usually to start loader)
  if (preSendDispatchTypes)
    for (let i = 0; i < preSendDispatchTypes.length; i++) {
      dispatch({ type: preSendDispatchTypes[i] });
    }

  axiosApiInstance
    // @ts-ignore
    .request(requestParams)
    .then((res) => {
      if (successDispatchTypes) {
        for (let i = 0; i < successDispatchTypes.length; i++) {
          dispatch({ type: successDispatchTypes[i], payload: res });
        }
      }
    })
    .catch((err) => {
      if (errorDispatchTypes) {
        for (let i = 0; i < errorDispatchTypes.length; i++) {
          dispatch({ type: errorDispatchTypes[i], payload: err });
        }
      }
    });
};

export default apiCall;

export interface paramsObjType {
  url: string;
  method: string;
  preSendDispatchTypes: string[];
  successDispatchTypes: string[];
  errorDispatchTypes: string[];
}
