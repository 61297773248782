import {
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_SET_TOKENS,
} from "redux/actionTypes";

import { RootStateOrAny } from "react-redux";

const initialState = {
  isAuthenticated: false,
  user: {},
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case AUTH_SET_TOKENS:
      localStorage.setItem("access_token", action.payload.data.access);
      localStorage.setItem("refresh_token", action.payload.data.refresh);
      return {
        ...state,
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: { ...action.payload.data.user },
      };
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    case AUTH_LOGOUT:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
