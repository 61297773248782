// companies
import {
  CLIENTS_DT_REFRESH,
  CLIENTS_DT_REFRESH_RESEL,
  // messages
  MSG_CLIENT_ADD_SUCCESS,
  MSG_CLIENT_DEL_SUCCESS,
  MSG_CLIENT_UPDATE_SUCCESS,
  // add edit dialog
  ADD_EDIT_DIALOG_HIDE,
  ADD_EDIT_DIALOG_LOADING,
  ADD_EDIT_DIALOG_NOT_LOADING,
  ADD_EDIT_DIALOG_FORM_POPULATE,
  // delete dialog
  DELETE_DIALOG_HIDE,
  DELETE_DIALOG_LOADING,
  DELETE_DIALOG_NOT_LOADING,
  // error handling
  MSG_SHOW_ERROR_MSG,
} from "redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const getAddCompanyParams = () => {
  return {
    url: `${apiUrl}companies/`,
    method: "post",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_CLIENT_ADD_SUCCESS,
      CLIENTS_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const populateAddEditCompanyFormParams = (instanceId: number) => {
  return {
    url: `${apiUrl}companies/${instanceId}`,
    method: "get",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      ADD_EDIT_DIALOG_FORM_POPULATE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [
      MSG_SHOW_ERROR_MSG,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
  };
};

export const getEditCompanyParams = (instId: number) => {
  return {
    url: `${apiUrl}companies/${instId}`,
    method: "put",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_CLIENT_UPDATE_SUCCESS,
      CLIENTS_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const getDelCompanyParams = (instId: number) => {
  return {
    url: `${apiUrl}companies/${instId}`,
    method: "delete",
    preSendDispatchTypes: [DELETE_DIALOG_LOADING],
    successDispatchTypes: [
      DELETE_DIALOG_NOT_LOADING,
      DELETE_DIALOG_HIDE,
      MSG_CLIENT_DEL_SUCCESS,
      CLIENTS_DT_REFRESH,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, DELETE_DIALOG_NOT_LOADING],
  };
};
