import { RootStateOrAny } from "react-redux";

import {
  SUPPORT_DT_REFRESH_RESEL,
  SUPPORT_SET_DT_PAGE,
  SUPPORT_SET_DT_ROW,
  SUPPORT_DIALOG_SHOW,
  SUPPORT_DIALOG_HIDE,
  SUPPORT_DIALOG_LOADING,
  SUPPORT_DIALOG_NOT_LOADING,
} from "../../actionTypes";

const initialState = {
  tables: {
    supportCaseTable: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {},
    },
  },
  dialog: {
    show: false,
    loading: false,
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case SUPPORT_DT_REFRESH_RESEL:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportCaseTable: {
            ...state.tables.supportCaseTable,
            selectedRow: action.payload.data.id,
            triggerDtRefresh: {},
          },
        },
      };
    case SUPPORT_SET_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportCaseTable: {
            ...state.tables.supportCaseTable,
            viewedPage: action.payload,
          },
        },
      };
    case SUPPORT_SET_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportCaseTable: {
            ...state.tables.supportCaseTable,
            selectedRow: action.payload,
          },
        },
      };
    case SUPPORT_DIALOG_SHOW:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          show: true,
        },
      };
    case SUPPORT_DIALOG_HIDE:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          show: false,
        },
      };
    case SUPPORT_DIALOG_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loading: true,
        },
      };
    case SUPPORT_DIALOG_NOT_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loading: false,
        },
      };

    default:
      return state;
  }
}
