import { useEffect } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { HOME_PAGE_REPORTS_SET_DATE_URL_SUFFIX } from "redux/actionTypes";
// custom
import useGetSelectedReportParams from "./useGetSelectedReportParams";
// types
import { RootStateOrAny } from "react-redux";

const useSetReportUrlDateSuffix = () => {
  const reportInfo = useGetSelectedReportParams();

  const {
    date,
    fromDate,
    toDate,
    year,
    fromYear,
    toYear,
    months,
  } = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.dateOptions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let urlSuffix;
    if (reportInfo)
      urlSuffix = `${
        reportInfo.accepts_year_range
          ? `&to_year=${toYear}&from_year=${fromYear}`
          : ""
      }${reportInfo.accepts_year ? `&year=${year}` : ""}${
        reportInfo.accepts_date_range
          ? `&from_date=${fromDate}&to_date=${toDate}`
          : ""
      }${reportInfo.accepts_date ? `&date=${date}` : ""}${
        reportInfo.accepts_multiselect_month ? `&months=[${months}]` : ""
      }`;
    else urlSuffix = "";

    dispatch({
      type: HOME_PAGE_REPORTS_SET_DATE_URL_SUFFIX,
      payload: urlSuffix,
    });
  }, [toDate, fromDate, fromYear, toYear, year, date, months, reportInfo]);
};

export default useSetReportUrlDateSuffix;
