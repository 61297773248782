import { useMemo, useState } from "react";

import { RootStateOrAny, useSelector } from "react-redux";
import { ReportInfoType } from "types/types";

const useGetSelectedReportParams: GetSelectedReportParamsType = () => {
  const [selectedReportParams, setSelectedReportParams] = useState(null);

  // all available report parameters
  const allReportParams = useSelector(
    (state: RootStateOrAny) => state.homePage.formFields.values.report_options
  );

  // stores selected report string
  const selectedReport = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.selectedReport
  );

  // matches selected report in redux (selected from selection card) with available options to get all parameters
  useMemo(() => {
    let params = null;
    if (allReportParams && selectedReport)
      params = allReportParams.find(
        (reportInfo: ReportInfoType) => reportInfo.value === selectedReport
      );
    setSelectedReportParams(params);
  }, [allReportParams, selectedReport]);

  return selectedReportParams;
};

export default useGetSelectedReportParams;

type GetSelectedReportParamsType = () => ReportInfoType | null;
