import React from "react";

import { Col, Card, Form } from "react-bootstrap";
// custom components
import DateOptions from "./components/DateOptions/DateOptions";
import CheckboxFilters from "./components/CheckboxFilters";
// custom functions
import useGetSelectedReportParams from "functions/useGetSelectedReportParams";
// styles
import { minPanelHeight } from "../../ReportGenerationPanel";

const ReportParamsSetter: React.FC = () => {
  const selectedReportParams = useGetSelectedReportParams();
  return (
    <Card style={{ minHeight: minPanelHeight }}>
      {selectedReportParams ? (
        <>
          {selectedReportParams.accepts_year ||
          selectedReportParams.accepts_year_range ||
          selectedReportParams.accepts_multiselect_month ||
          selectedReportParams.accepts_date ||
          selectedReportParams.accepts_date_range ||
          (selectedReportParams.checkbox_filters &&
            selectedReportParams.checkbox_filters.length) ? (
            <Form.Group as={Col}>
              <CheckboxFilters />
              <DateOptions />
            </Form.Group>
          ) : (
            <NoOptionsMessage message="This report takes no parameters" />
          )}
        </>
      ) : (
        <NoOptionsMessage message="Please select a report" />
      )}
    </Card>
  );
};

export default ReportParamsSetter;

const NoOptionsMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <h4>{message}</h4>
    </div>
  );
};
