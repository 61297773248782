import React from "react";
import { Button } from "react-bootstrap";

const AddButton: React.FC<AddButtonTypes> = ({
  handleAddClick,
  titleOverride,
}) => {
  return (
    <Button
      className="btn-wd btn-outline mr-1"
      type="button"
      variant="success"
      onClick={handleAddClick}
    >
      <span className="btn-label">
        <i className="fas fa-plus-circle" />
      </span>
      {titleOverride ? titleOverride : "Add"}
    </Button>
  );
};

export default AddButton;

interface AddButtonTypes {
  handleAddClick: () => void;
  titleOverride?: string;
}
