import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const claviaTvChannelPartner = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Clavia TV Channel Partner Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Clavia TV Channel Partner Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "channel_partner", title: "Channel Partner" },
      { data: "status", title: "Status" },
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "company", title: "Company" },
      { data: "job_title", title: "Job Title" },
      { data: "email", title: "E-Mail" },
      { data: "city", title: "City" },
      { data: "country", title: "Country" },
      { data: "course", title: "Course" },
      {
        data: { _: "date.display", sort: "date.@data-order" },
        title: "Enrolment Date",
      },
      { data: "attempts", title: "Quiz Attempt" },
      { data: "score", title: "Score" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
