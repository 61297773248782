import React, { useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import { Form } from "react-bootstrap";

// select box
import { OptionsType, OptionTypeBase } from "react-select";

import CustomSpinner from "components/_Custom/CustomSpinner";
import CustomFormSelect from "../../../components/_Custom/CustomFormSelect";

const AddEditCompanyForm: React.FC<AddEditCompanyFormProps> = ({
  onSubmit,
  formSuggestions,
  formSuggestionsLoading,
  dialogLoading,
}) => {
  const formFields = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog.formFields
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  // update fields from pre-populated values when they change (add is empty, edit is not)
  useEffect(() => {
    setValue("name", formFields.name ? formFields.name : "");
    setValue(
      "report_packages",
      formFields.report_packages ? formFields.report_packages : ""
    );
    setValue(
      "moodle_instances",
      formFields.moodle_instances ? formFields.moodle_instances : ""
    );
    setValue("plan", formFields.plan ? formFields.plan : "");
    setValue("active", formFields.active ? formFields.active : false);
    setValue(
      "multi_instance_reports",
      formFields.multi_instance_reports
        ? formFields.multi_instance_reports
        : false
    );
  }, [formFields]);

  return dialogLoading ? (
    <CustomSpinner height={300} />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} id="modalForm">
      <Form.Group controlId="formBasicCheckbox">
        <Form.Label>Company Name</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          {...register("name", { required: true })}
          isInvalid={errors.name?.type === "required"}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid company name
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Available Report(s)</Form.Label>
        <CustomFormSelect
          loading={formSuggestionsLoading}
          options={formSuggestions.report_package_options}
          formFields={formFields}
          placeholder="Select Available Report(s)"
          fieldName="report_packages"
          control={control}
          showValidationError={errors.report_packages?.type === "required"}
          validationMessage="Please select at least one available report"
          isMulti={true}
          required={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Moodle Instance(s)</Form.Label>
        <CustomFormSelect
          loading={formSuggestionsLoading}
          options={formSuggestions.moodle_instance_options}
          formFields={formFields}
          placeholder="Select Moodle Instance(s)"
          fieldName="moodle_instances"
          control={control}
          showValidationError={errors.moodle_instances?.type === "required"}
          validationMessage="Please select at least one Moodle instance"
          isMulti={true}
          required={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Plan</Form.Label>
        <CustomFormSelect
          loading={formSuggestionsLoading}
          options={formSuggestions.plan_options}
          formFields={formFields}
          placeholder="Select Plan"
          fieldName="plan"
          control={control}
          showValidationError={errors.plan?.type === "required"}
          validationMessage="Please select plan"
          isMulti={false}
          required={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check>
          <Form.Check.Label>
            <Form.Check.Input {...register("active")} />
            <span className="form-check-sign" />
            Subscription Active
          </Form.Check.Label>
          <Form.Check.Label>
            <Form.Check.Input {...register("multi_instance_reports")} />
            <span className="form-check-sign" />
            Multi-Instance Reports
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    </Form>
  );
};

export default AddEditCompanyForm;

interface AddEditCompanyFormProps {
  onSubmit: (event: any) => void;
  formSuggestions: FormSuggestionsType;
  formSuggestionsLoading: boolean;
  dialogLoading: boolean;
}

interface FormSuggestionsType {
  report_package_options: OptionsType<OptionTypeBase>;
  moodle_instance_options: OptionsType<OptionTypeBase>;
  plan_options: OptionsType<OptionTypeBase>;
}
