import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

import { IconObjType } from "../../types/types";

export const companyTableParams = {
  section: "companiesPage",
  refreshUrl: "companies/",
  reduxTableName: "companiesDataTable",
  buttonsColumn: {
    id: 7,
    genIcons: (): IconObjType[] => {
      return [
        { icon: "Edit", tooltip: "Edit Company" },
        { icon: "Delete", tooltip: "Delete Company" },
      ];
    },
  },
  tableNativeParams: {
    autoWidth: true,
    responsive: true,
    data: [],
    buttons: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "id", title: "ID" }, // 0
      { data: "name", title: "Company Name", responsivePriority: 1 }, // 1
      {
        data: "selected_report_packages_str",
        title: "Assigned Report Packages",
        responsivePriority: 1,
      }, // 2
      {
        data: "selected_moodle_instances_str",
        title: "Assigned Moodle Instances",
        responsivePriority: 1,
      }, // 3
      {
        data: "plan_str",
        title: "Plan",
        responsivePriority: 1,
      }, // 4
      {
        data: "subscription_status",
        title: "Subscription",
      }, // 5
      {
        data: "multi_instance_reports_str",
        title: "Multi-Instance Reports",
      }, // 6
      { data: null, title: "Actions" }, // 7
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No companies available",
    },
    aaSorting: [[0, "asc"]],
  },
};
