import React from "react";

import Select, { OptionTypeBase } from "react-select";

const CustomSingleSelect: React.FC<CustomSelectTypes> = ({
  options,
  isDisabled,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <Select
      className="react-select primary"
      classNamePrefix="react-select"
      name="singleSelect"
      value={
        options &&
        options.filter((optObj: OptionTypeBase) => optObj.value === value)
      }
      onChange={(selObj: OptionTypeBase) => onChange(selObj.value)}
      options={options}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};

export default CustomSingleSelect;

interface CustomSelectTypes {
  isDisabled: boolean;
  options: any;
  placeholder: string;
  value: number | string;
  onChange: (selectedValue: number) => void;
}
