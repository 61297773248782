import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const yearOnYearGrowthReport = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Year on Year Growth Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Year on Year Growth Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "month", title: "Month" },
      { data: "registrations", title: "Registrations" },
      { data: "enrolments", title: "Enrolments" },
      { data: "certifications", title: "Certifications" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
