import React from "react";

// react-bootstrap components
import { Container, Row } from "react-bootstrap";

function AuthFooter() {
  return (
    <>
      <footer className="footer position-absolute fixed-bottom">
        <Container>
          <Row className="align-items-center">
            <div className="col text-center">
              <p
                className="copyright text-center m-0"
                style={{ float: "none" }}
              >
                © {new Date().getFullYear()}{" "}
                <a href="https://www.claviagroup.com/">Clavia Group</a>,
                Powerful e-Learning
              </p>
            </div>
          </Row>
          {/*<nav>*/}
          {/*<ul className="footer-menu d-flex justify-content-center">*/}
          {/*<li>*/}
          {/*  <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>*/}
          {/*    Home*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="http://www.claviagroup.com/">Company</a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>*/}
          {/*    Portfolio*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>*/}
          {/*    Blog*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*</ul>*/}
          {/*<p className="copyright text-center m-0">*/}
          {/*  © {new Date().getFullYear()}{" "}*/}
          {/*  <a href="https://www.claviagroup.com/">Clavia Group</a>, Powerful*/}
          {/*  e-Learning*/}
          {/*</p>*/}
          {/*</nav>*/}
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
