import { useEffect } from "react";

import axiosApiInstance from "../axiosApiInstance";

// redux
import { useDispatch } from "react-redux";
import { MSG_SHOW_ERROR_MSG } from "../../redux/actionTypes";
// custom functions
import useGetTableReduxPropsOrNull from "./useGetTableReduxPropsOrNull";
// types
import { DataTableParamsType } from "../../components/_Custom/DataTables/DataTable";

const apiUrl = process.env.REACT_APP_API_V1_URL;

// export functions
async function useDataTableRefresh(
  dataTable: any,
  tableParams: DataTableParamsType | null
) {
  const dispatch = useDispatch();

  const {
    reduxPageFilter,
    reduxTriggerDtRefresh,
    reduxRefreshUrl,
    reduxViewedPage,
    reduxSelectedRow,
  } = useGetTableReduxPropsOrNull(tableParams);

  const FILTER_TAB_VALUES = tableParams?.filterTabValues;

  useEffect(() => {
    if (dataTable && Object.keys(dataTable).length && tableParams) {
      dataTable.processing(true);

      let refreshUrl;
      if (tableParams.refreshUrl) {
        // big sections have fixed URLs which are specified in table params
        refreshUrl = `${apiUrl}${tableParams.refreshUrl}`;
      } else if (reduxRefreshUrl) {
        // detail sections have 'dynamic' URLs that are added to redux
        refreshUrl = `${apiUrl}${reduxRefreshUrl}`;
      } else return;

      if ((reduxPageFilter || reduxPageFilter === 0) && FILTER_TAB_VALUES) {
        refreshUrl += FILTER_TAB_VALUES[reduxPageFilter];
      }

      axiosApiInstance
        .get(refreshUrl)
        .then((res) => {
          dataTable.clear();
          dataTable.rows.add(res.data).draw();
        })
        .then(() => {
          // select datatable row as specified in redux store
          if (reduxSelectedRow != null) {
            const rowNo = dataTable.rows().count();
            for (let i = 0; i < rowNo; i++) {
              // select row that was added/edited
              const idInTable = dataTable.rows(i).data()[0].id;
              if (reduxSelectedRow === idInTable) {
                dataTable.rows(i).select();
                dataTable.row(i).show().draw(false); // go to correct page
                break; // break the for loop
              }
            }
          }

          // select datatable page as specified in redux store
          else if (reduxViewedPage) {
            if (reduxViewedPage <= dataTable.page.info().pages - 1) {
              dataTable.page(reduxViewedPage).draw("page");
            } else {
              dataTable.page(reduxViewedPage - 1).draw("page"); // page before if page no longer exists
            }
          }
        })
        .then(() => dataTable.processing(false))
        .catch((err) => dispatch({ type: MSG_SHOW_ERROR_MSG, payload: err }));
    }
    // triggerDtRefresh change triggers useEffect as it is an object (selectedRow will have same value as triggerDtRefresh)
    // eslint-disable-next-line
    }, [reduxPageFilter, reduxTriggerDtRefresh, reduxRefreshUrl, dataTable])
}

export default useDataTableRefresh;
