import React from "react";

// react-bootstrap components
import { Container } from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            {/*<ul className="footer-menu">*/}
            {/*<li>*/}
            {/*  <a href="/">Home</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a href="http://www.claviagroup.com/">Company</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
            {/*    Portfolio*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <a href="#" onClick={(e) => e.preventDefault()}>*/}
            {/*    Blog*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*</ul>*/}
            <p className="copyright text-center m-0">
              © {new Date().getFullYear()}{" "}
              <a href="https://www.claviagroup.com/">Clavia Group</a>, Powerful
              e-Learning
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
