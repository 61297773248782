import React, { useEffect } from "react";

import { useForm } from "react-hook-form";

// template
import { Button, Form, Modal } from "react-bootstrap";
// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { SUPPORT_DIALOG_HIDE } from "redux/actionTypes";
// api calls
import apiCall from "../../../functions/apiCall";
import { getAddSupportReqParams } from "config/api_calls";
// components
import CustomSpinner from "../CustomSpinner";

const SupportCaseDialog: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const { show, loading } = useSelector(
    (state: RootStateOrAny) => state.supportPage.dialog
  );

  const onSubmit = (formData: formDataTypes) => {
    dispatch(apiCall(formData, getAddSupportReqParams()));
  };

  useEffect(() => {
    if (show) {
      setValue("name", "");
      setValue("message", "");
    }
  }, [show]);

  return (
    <Modal
      className="modal-primary"
      show={show}
      onHide={() => dispatch({ type: SUPPORT_DIALOG_HIDE, payload: null })}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Add a Support Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <CustomSpinner height={150} />
        ) : (
          <>
            <Form onSubmit={handleSubmit(onSubmit)} id="supportCaseForm">
              <Form.Group>
                <Form.Label>Case Name</Form.Label>
                <Form.Control
                  {...register("name", { required: true })}
                  type="text"
                  isInvalid={errors.name?.type === "required"}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a case name
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  {...register("message", { required: true })}
                  as="textarea"
                  isInvalid={errors.message?.type === "required"}
                />
                <Form.Control.Feedback type="invalid">
                  Please describe your case
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" form="supportCaseForm">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupportCaseDialog;

interface formDataTypes {
  name: string;
  message: string;
}
