import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const questionAnalysis = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    title: "Question Analysis Report",
    buttons: [
      "colvis",
      "copyHtml5",
      // "excelHtml5",
      // "pdfHtml5",
      {
        extend: "csvHtml5",
        title: "Question Analysis Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Question Analysis Report",
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    data: [],
    dom: "Bfrtip",
    scrollX: true,
    select: {
      style: "single",
    },
    columns: [
      { data: "question", title: "Question" },
      { data: "question_type", title: "Question Type" },
      { data: "category", title: "Category" },
      { data: "attempts", title: "Attempts" },
      { data: "correctly_answered", title: "Correctly Answered" },
      { data: "incorrectly_answered", title: "Incorrectly Answered" },
      { data: "score", title: "Score" },
      { data: "difficulty", title: "Difficulty" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
