import React, { useEffect, useState } from "react";

import { Button, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ChangeOwnPwdCardWithForm: React.FC<ChangeOwnPasswordFormPropTypes> = ({
  onSubmit,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm();

  const [cardClasses, setCardClasses] = useState("card-hidden");

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 500);
  });

  useEffect(() => {
    setValue("password", "");
    setValue("new_password", "");
    setValue("new_password2", "");
  }, []);

  return (
    <Form
      action=""
      className="form"
      id="changeOwnPassForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Card className={"card-login " + cardClasses}>
        <Card.Header>
          <h3 className="header text-center">Set New Password</h3>
        </Card.Header>
        <Card.Body>
          <Card.Body>
            <Form.Group>
              <label>Current Password</label>
              <Form.Control
                {...register("password", { required: true })}
                type="password"
                placeholder=""
                isInvalid={errors.password?.type === "required"}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your current password
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>New Password</label>
              <Form.Control
                {...register("new_password", {
                  required: true,
                  validate: {
                    newPassSame: (value) =>
                      value !== getValues().password ||
                      "New password cannot be the same as the old one",
                  },
                })}
                type="password"
                placeholder=""
                isInvalid={
                  errors.new_password?.type === "required" ||
                  (errors.new_password?.type === "newPassSame" &&
                    getValues().password)
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.new_password?.type === "required" ? (
                  <>Please provide new password</>
                ) : errors.new_password?.type === "newPassSame" ? (
                  <>New password cannot be the same as the old one</>
                ) : (
                  <></>
                )}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>Repeat New Password</label>
              <Form.Control
                {...register("new_password2", {
                  required: true,
                  validate: {
                    passwordsMatch: (value) =>
                      value === getValues().new_password ||
                      "Email confirmation error!",
                  },
                })}
                type="password"
                placeholder=""
                isInvalid={
                  errors.new_password2?.type === "required" ||
                  (errors.new_password2?.type === "passwordsMatch" &&
                    getValues().new_password)
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.new_password2?.type === "required" ? (
                  <>Please provide a valid password</>
                ) : errors.new_password2?.type === "passwordsMatch" ? (
                  <>Passwords dot not match</>
                ) : (
                  <></>
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </Card.Body>
        </Card.Body>
        <Card.Footer className="ml-auto mr-auto">
          <Button
            className="btn-wd"
            variant="warning"
            type="submit"
            form="changeOwnPassForm"
          >
            Submit
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default ChangeOwnPwdCardWithForm;

interface ChangeOwnPasswordFormPropTypes {
  onSubmit: (event: any) => void;
}
