import { RootStateOrAny } from "react-redux";

import getMessagesDict from "../../../config/getMessagesDict";

// import all message types
import {
  // system error
  MSG_SHOW_ERROR_MSG,
  // custom message
  MSG_CUSTOM_MSG,
  // auth messages
  MSG_AUTH_LOGGED_OUT,
  MSG_AUTH_LOGIN_SUCCESS,
  MSG_AUTH_LOGIN_ERROR,
  // company section messages
  MSG_CLIENT_ADD_SUCCESS,
  MSG_CLIENT_DEL_SUCCESS,
  MSG_CLIENT_UPDATE_SUCCESS,
  // moodle instance section messages
  MSG_MOODLE_INST_ADD_SUCCESS,
  MSG_MOODLE_INST_DEL_SUCCESS,
  MSG_MOODLE_INST_UPDATE_SUCCESS,
  // plans
  MSG_PLAN_DEL_SUCCESS,
  MSG_PLAN_ADD_SUCCESS,
  MSG_PLAN_UPDATE_SUCCESS,
  // report db it messages
  MSG_REPORT_DB_ENTRY_ADD_SUCCESS,
  MSG_REPORT_DB_ENTRY_DEL_SUCCESS,
  MSG_REPORT_DB_ENTRY_UPDATE_SUCCESS,
  // report package messages
  MSG_REPORT_PACKAGES_ADD_SUCCESS,
  MSG_REPORT_PACKAGES_DEL_SUCCESS,
  MSG_REPORT_PACKAGES_UPDATE_SUCCESS,
  // users
  MSG_USER_ADD_SUCCESS,
  MSG_USER_DEL_SUCCESS,
  MSG_USER_UPDATE_SUCCESS,
  MSG_PWD_CHANGED,
  // support request
  MSG_SUPPORT_REQUEST_SUBMITTED,
  // support request messages
  MSG_SUPPORT_MESSAGE_SENT,
} from "../../actionTypes";

const initialState = {
  msgObj: { color: "", text: "" },
  triggerMessage: {},
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case MSG_SHOW_ERROR_MSG:
      return {
        ...state,
        msgObj: {
          color: "danger",
          text: action.payload.customMsg,
        },
        triggerMessage: {},
      };
    case MSG_CUSTOM_MSG:
      return {
        ...state,
        msgObj: action.payload,
        triggerMessage: {},
      };
    case MSG_PWD_CHANGED:
    case MSG_AUTH_LOGGED_OUT:
    case MSG_AUTH_LOGIN_ERROR:
    case MSG_AUTH_LOGIN_SUCCESS:
    case MSG_CLIENT_ADD_SUCCESS:
    case MSG_CLIENT_DEL_SUCCESS:
    case MSG_CLIENT_UPDATE_SUCCESS:
    case MSG_MOODLE_INST_ADD_SUCCESS:
    case MSG_MOODLE_INST_DEL_SUCCESS:
    case MSG_MOODLE_INST_UPDATE_SUCCESS:
    case MSG_REPORT_PACKAGES_ADD_SUCCESS:
    case MSG_REPORT_PACKAGES_DEL_SUCCESS:
    case MSG_REPORT_PACKAGES_UPDATE_SUCCESS:
    case MSG_USER_ADD_SUCCESS:
    case MSG_USER_DEL_SUCCESS:
    case MSG_USER_UPDATE_SUCCESS:
    case MSG_SUPPORT_REQUEST_SUBMITTED:
    case MSG_REPORT_DB_ENTRY_ADD_SUCCESS:
    case MSG_REPORT_DB_ENTRY_DEL_SUCCESS:
    case MSG_REPORT_DB_ENTRY_UPDATE_SUCCESS:
    case MSG_PLAN_DEL_SUCCESS:
    case MSG_PLAN_ADD_SUCCESS:
    case MSG_PLAN_UPDATE_SUCCESS:
    case MSG_SUPPORT_MESSAGE_SENT:
      return {
        ...state,
        // @ts-ignore
        msgObj: getMessagesDict()[action.type],
        triggerMessage: {},
      };
    default:
      return state;
  }
}
