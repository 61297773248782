import React from "react";

import { Card, Row, Col } from "react-bootstrap";

// custom components
import ReportParamsSetter from "./components/ReportParamsSetter/ReportParamsSetter";
import ReportSelector from "./components/ReportSelector/ReportSelector";

export const rowWithTopMargin = { marginTop: "12px" };
export const minPanelHeight = "300px";

const ReportGenerationPanel: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">Report Generation Panel</Card.Title>
        <p className="card-category">
          Select report, set its parameters, and click ‘View Report’ to view and
          adapt, or ‘CSV’ to download entire table
        </p>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} md={6}>
            <ReportSelector />
          </Col>
          <Col xs={12} md={6}>
            <ReportParamsSetter />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ReportGenerationPanel;
