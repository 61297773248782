import React, { useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import { Form, Row, Col } from "react-bootstrap";

import CustomSpinner from "components/_Custom/CustomSpinner";

const AddEditReportDbEntryForm: React.FC<AddEditCompanyFormProps> = ({
  onSubmit,
  dialogLoading,
}) => {
  const formFields = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog.formFields
  );

  const stringFormFieldNames = [
    "title",
    "description",
    "id_str",
    "checkbox_filters",
  ];

  const booleanFormFieldNames = [
    "csv_available",
    "pdf_available",
    "chart_available",
    "datatable_available",
    "accepts_date",
    "accepts_date_range",
    "accepts_multiselect_month",
    "accepts_year",
    "accepts_year_range",
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  // update fields from pre-populated values when they change (add is empty, edit is not)
  useEffect(() => {
    // set string field names to their value (or blank if none provided)
    stringFormFieldNames.forEach((fieldName) => {
      setValue(fieldName, fieldName in formFields ? formFields[fieldName] : "");
    });

    // set boolean field names to their value (or false if none provided)
    booleanFormFieldNames.forEach((fieldName) => {
      setValue(
        fieldName,
        fieldName in formFields ? formFields[fieldName] : false
      );
    });
  }, [formFields]);

  return dialogLoading ? (
    <CustomSpinner height={300} />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} id="modalForm">
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Report Title</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("title", { required: true })}
              isInvalid={errors.title?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid report title
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              placeholder=""
              {...register("description", { required: true })}
              isInvalid={errors.description?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid description
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group>
            <Form.Label>ID String</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("id_str", { required: true })}
              isInvalid={errors.id_str?.type === "required"}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid report id string
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.Label>Checkbox Filters</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              {...register("checkbox_filters", { required: false })}
              isInvalid={errors.checkbox_filters?.type === "required"}
            />
            {/*<Form.Control.Feedback type="invalid">*/}
            {/*  Please provide a valid checkbox filter string*/}
            {/*</Form.Control.Feedback>*/}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Available Formats</Form.Label>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input {...register("csv_available")} />
                <span className="form-check-sign" />
                CSV
              </Form.Check.Label>
              <Form.Check.Label>
                <Form.Check.Input {...register("pdf_available")} />
                <span className="form-check-sign" />
                PDF
              </Form.Check.Label>
              <Form.Check.Label>
                <Form.Check.Input {...register("chart_available")} />
                <span className="form-check-sign" />
                Chart
              </Form.Check.Label>
              <Form.Check.Label>
                <Form.Check.Input {...register("datatable_available")} />
                <span className="form-check-sign" />
                Datatable
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Date Fields</Form.Label>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input {...register("accepts_date")} />
                <span className="form-check-sign" />
                Date
              </Form.Check.Label>
              <Form.Check.Label>
                <Form.Check.Input {...register("accepts_date_range")} />
                <span className="form-check-sign" />
                Date Range
              </Form.Check.Label>
              <Form.Check.Label>
                <Form.Check.Input {...register("accepts_multiselect_month")} />
                <span className="form-check-sign" />
                Multiselect Month
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input {...register("accepts_year")} />
                <span className="form-check-sign" />
                Year
              </Form.Check.Label>
              <Form.Check.Label>
                <Form.Check.Input {...register("accepts_year_range")} />
                <span className="form-check-sign" />
                Year Range
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default AddEditReportDbEntryForm;

interface AddEditCompanyFormProps {
  onSubmit: (event: any) => void;
  formSuggestionsLoading: boolean;
  dialogLoading: boolean;
}
