import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const detailedFeedback = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Detailed Feedback",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Detailed Feedback",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    // CATEGORY, MODULE, FIRST_NAME, LAST_NAME, EMAIL, COMPANY, QUESTION, SUBMITTED_DATE, RESPONSE
    columns: [
      { data: "category", title: "Category" },
      { data: "module", title: "Module" },
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "email", title: "Email" },
      { data: "company", title: "Company" },
      { data: "question", title: "Question" },
      { data: "submitted_date", title: "Submitted Date" },
      { data: "response", title: "Response" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
