// moodle instances
import {
  DELETE_DIALOG_LOADING,
  DELETE_DIALOG_NOT_LOADING,
  DELETE_DIALOG_HIDE,
  MSG_MOODLE_INST_DEL_SUCCESS,
  MOODLE_INSTANCES_DT_REFRESH,
  ADD_EDIT_DIALOG_LOADING,
  ADD_EDIT_DIALOG_HIDE,
  ADD_EDIT_DIALOG_NOT_LOADING,
  ADD_EDIT_DIALOG_FORM_POPULATE,
  MSG_MOODLE_INST_ADD_SUCCESS,
  MOODLE_INSTANCES_DT_REFRESH_RESEL,
  MSG_MOODLE_INST_UPDATE_SUCCESS,
  MSG_SHOW_ERROR_MSG,
} from "redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const getAddMoodleInstanceParams = () => {
  return {
    url: `${apiUrl}moodle-instances/`,
    method: "post",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_MOODLE_INST_ADD_SUCCESS,
      MOODLE_INSTANCES_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const populateAddEditMoodleInstanceFormParams = (instanceId: number) => {
  return {
    url: `${apiUrl}moodle-instances/${instanceId}`,
    method: "get",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      ADD_EDIT_DIALOG_FORM_POPULATE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [
      MSG_SHOW_ERROR_MSG,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
  };
};

export const getEditMoodleInstanceParams = (instId: number) => {
  return {
    url: `${apiUrl}moodle-instances/${instId}`,
    method: "put",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_MOODLE_INST_UPDATE_SUCCESS,
      MOODLE_INSTANCES_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const getDelMoodleInstanceParams = (instId: number) => {
  return {
    url: `${apiUrl}moodle-instances/${instId}`,
    method: "delete",
    preSendDispatchTypes: [DELETE_DIALOG_LOADING],
    successDispatchTypes: [
      DELETE_DIALOG_NOT_LOADING,
      DELETE_DIALOG_HIDE,
      MSG_MOODLE_INST_DEL_SUCCESS,
      MOODLE_INSTANCES_DT_REFRESH,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, DELETE_DIALOG_NOT_LOADING],
  };
};
