import axios from "axios";

const axiosApiInstance = axios.create();

import { capitaliseFirstLetter } from "./helpers";

const headersTpl = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const apiUrl = process.env.REACT_APP_API_V1_URL;

async function refreshAccessToken(): Promise<any> {
  const refreshToken = localStorage.getItem("refresh_token");

  const body = JSON.stringify({ refresh: refreshToken });

  return await axios.post(`${apiUrl}acc/token/refresh/`, body, {
    headers: headersTpl,
  });
}

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...headersTpl,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    let errMsg = "";

    if (error.response.status === 401 && !originalRequest._retry) {
      // these are expired token errors, retry when received
      originalRequest._retry = true;

      // get new token, save it in storage and add it to headers
      const resp = await refreshAccessToken();
      const newToken = resp.data.access;
      localStorage.setItem("access_token", newToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;

      return axiosApiInstance(originalRequest);
      // below errors are actual errors (not token expiry) - returned from failed requests
    } else if (
      typeof error.response.data === "string" &&
      error.response.data.substring(0, 14) === "ProtectedError"
    )
      // django returns protected errors as 500 so need to check for them to return a relevant error message
      errMsg =
        "Protected Error: you cannot delete this instance. Please make sure it is not referenced elsewhere.";
    else if (error.response.status >= 500 && error.response.status <= 511) {
      // internal server errors
      errMsg = error.response.statusText;
    } else if (
      error.response.status >= 400 &&
      error.response.status <= 451 &&
      "response" in error &&
      "data" in error.response &&
      error.response.data
    ) {
      let responseData = error.response.data;
      if (responseData instanceof Blob) {
        // this is used when fetching PDF (requested Blob in response type)
        responseData = JSON.parse(await responseData.text());
      }
      // django error messages
      const errArray: any[] = Object.values(responseData);
      errArray.forEach((arrItem) => {
        errMsg += `${capitaliseFirstLetter(arrItem.toString())} `;
      });
    } else if ("message" in error) {
      // other (generic) error messages
      errMsg = error.message;
    } else errMsg = "Uncaptured Error. Please inform your administrator."; // uncaptured error messages (should be overwritten below)

    // setting custom error message
    error.customMsg = errMsg;
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
