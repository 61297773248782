import React, { useEffect, useState } from "react";

import { Card, Spinner } from "react-bootstrap";
// redux
import { RootStateOrAny, useSelector } from "react-redux";
// charts
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const EnrolmentCountCard = () => {
  const { chartDataLoading, chartData } = useSelector(
    (state: RootStateOrAny) => state.homePage.topChart
  );

  const [chartJsData, setChartJsData] = useState({ ...enrolmentChartDataTpl });

  useEffect(() => {
    if ("enrolment_card" in chartData) {
      setChartJsData({
        labels: chartData.enrolment_card.labels,
        datasets: [
          {
            label: "Monthly Enrollments",
            data: chartData.enrolment_card.data,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
          },
        ],
      });
    }
  }, [chartData]);

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">Enrollments</Card.Title>
        {/*<p className="card-category">*/}
        {/*  Number of enrolments compared to previous month*/}
        {/*</p>*/}
      </Card.Header>
      <Card.Body>
        {!chartDataLoading && chartData && "enrolment_card" in chartData ? (
          <div className="chart" style={{ height: `150px` }}>
            <Line
              data={chartJsData}
              //@ts-ignore
              options={{ ...enrolmentChartOptionsTpl }}
              plugins={[ChartDataLabels]}
            />
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default EnrolmentCountCard;

const enrolmentChartDataTpl = {
  labels: [],
  datasets: [
    {
      label: "Monthly Enrollments",
      data: [],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 2,
    },
  ],
};

const enrolmentChartOptionsTpl = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  legend: {
    display: true,
    position: "bottom",
  },
  // scales: {
  //   yAxes: [
  //     {
  //       stacked: true,
  //       ticks: {
  //         beginAtZero: true,
  //         // @ts-ignore
  //         // eslint-disable-next-line no-unused-vars
  //         callback: (value) => {
  //           return value + "%";
  //         },
  //       },
  //     },
  //   ],
  //   xAxes: [
  //     {
  //       stacked: true,
  //       ticks: {
  //         beginAtZero: true,
  //       },
  //     },
  //   ],
  // },
  tooltips: {
    mode: "label",
    callbacks: {
      // @ts-ignore
      label: function (tooltipItem, data) {
        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ": " +
          tooltipItem.value +
          "%"
        );
      },
    },
  },
};
