import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import useAddButtonsToTable from "../../../functions/dataTables/useAddButtonsToTable";

import "datatables.net";

// datatables dependencies
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");

// datatables plugins
addSmallerPlugins();
require("datatables.net-select");
require("datatables.net-responsive");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.print");

const Datatable: React.FC<DataTablePropsTypes> = ({
  tableParams,
  setDataTable,
  tableId,
}) => {
  const dtHtmlEl = useRef(); // datatable html ref

  const dataTableParams = useAddButtonsToTable(tableParams);

  // initialises required buttons
  useEffect(() => {
    const $table = $(dtHtmlEl.current);

    // if params set to responsive, make table responsive
    if (dataTableParams.responsive)
      // @ts-ignore
      dataTableParams.responsive = {
        details: {
          renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes(),
        },
      };

    setDataTable($table.DataTable({ ...dataTableParams }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableParams]); // only runs once as tableParams is a constant

  // clean-up function
  useEffect(() => {
    return () => {
      setDataTable(undefined);
      const $table = $(dtHtmlEl.current);
      $table.remove();
    };
  }, []);

  return (
    <div>
      <table
        id={tableId}
        className="table table-bordered table-striped"
        width="100%"
        // @ts-ignore
        ref={dtHtmlEl}
      />
    </div>
  );
};

export default Datatable;

// add smaller plugins to datatable
function addSmallerPlugins() {
  // row().show() plugin from datatables cdn (allows navigating to a specific row)
  $.fn.dataTable.Api.register("row().show()", function () {
    // @ts-ignore
    const page_info = this.table().page.info();
    // Get row index
    // @ts-ignore
    const new_row_index = this.index();
    // Row position
    // @ts-ignore
    const row_position = this.table().rows()[0].indexOf(new_row_index);
    // Already on right page ?
    if (row_position >= page_info.start && row_position < page_info.end) {
      // Return row object
      // @ts-ignore
      return this;
    }
    // Find page number
    // @ts-ignore
    const page_to_display = Math.floor(row_position / this.table().page.len());
    // Go to that page
    // @ts-ignore
    this.table().page(page_to_display);
    // Return row object
    // @ts-ignore
    return this;
  });

  // processing() plugin from datatables cdn (adds processing function for  manual setting of spinner)
  $.fn.dataTable.Api.register("processing()", function (show: boolean) {
    // @ts-ignore
    return this.iterator("table", function (ctx) {
      ctx.oApi._fnProcessingDisplay(ctx, show);
    });
  });
}

interface DataTablePropsTypes {
  tableParams: DataTableParamsType;
  // eslint-disable-next-line no-undef
  setDataTable: Dispatch<SetStateAction<DataTables.Api | undefined>>;
  tableId: string;
}

export interface DataTableParamsType {
  section?: string;
  refreshUrl?: string;
  reduxTableName?: string;
  buttonsColumn?: ButtonsColumnType;
  // eslint-disable-next-line no-undef
  tableNativeParams: DataTables.Settings;
  filterTabValues?: string[];
}

interface ButtonsColumnType {
  id: number;
  genIcons: (rowData: any) => any[];
}
