import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const netgearMasterReport = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Netgear Master Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Netgear Master Report",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "firstName", title: "First Name" },
      { data: "lastName", title: "Last Name" },
      { data: "email", title: "E-mail" },
      { data: "company", title: "Company" },
      { data: "certified", title: "Certified" },
      { data: "totalCertifications", title: "Total Certifications" },
      {
        data: {
          _: "lastAccessDate.display",
          sort: "lastAccessDate.@data-order",
        },
        title: "Last Access Date",
      },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
