import React, { useEffect } from "react";

import { useForm } from "react-hook-form";

// template
import { Button, Form, Modal } from "react-bootstrap";
// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { SUPPORT_MESSAGE_DIALOG_HIDE } from "redux/actionTypes";
// api calls
import apiCall from "../../../functions/apiCall";
import { getAddSupportMessageParams } from "config/api_calls";
// components
import CustomSpinner from "../CustomSpinner";

const SupportMessageDialog: React.FC<{ caseId: number }> = ({ caseId }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const { show, loading } = useSelector(
    (state: RootStateOrAny) => state.supportMessagesPage.dialog
  );

  const onSubmit = (formData: formDataTypes) => {
    dispatch(apiCall(formData, getAddSupportMessageParams(caseId)));
  };

  useEffect(() => {
    if (show) {
      setValue("support_case", caseId);
      setValue("message", "");
    }
  }, [show]);

  return (
    <Modal
      className="modal-primary"
      show={show}
      onHide={() =>
        dispatch({ type: SUPPORT_MESSAGE_DIALOG_HIDE, payload: null })
      }
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Add a Reply</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <CustomSpinner height={80} />
        ) : (
          <>
            <Form onSubmit={handleSubmit(onSubmit)} id="supportMessageForm">
              <Form.Group>
                <Form.Control
                  {...register("message", { required: true })}
                  type="text"
                  isInvalid={errors.message?.type === "required"}
                  as="textarea"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a message
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" form="supportMessageForm">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupportMessageDialog;

interface formDataTypes {
  name: string;
  message: string;
}
