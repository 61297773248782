import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const assessmentCompletionCleerline = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    title: "Assessment Completion Report (Cleerline)",
    buttons: [
      "colvis",
      "copyHtml5",
      // "excelHtml5",
      // "pdfHtml5",
      {
        extend: "csvHtml5",
        title: "Assessment Completion Report (Cleerline)",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Assessment Completion Report (Cleerline)",
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    data: [],
    dom: "Bfrtip",
    scrollX: true,
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      {
        data: { _: "date.display", sort: "date.@data-order" },
        title: "Date",
      },
      { data: "email", title: "Email" },
      { data: "country", title: "Country" },
      { data: "state", title: "State" },
      { data: "city", title: "City" },
      { data: "phone", title: "phone" },
      { data: "course", title: "Course" },
      { data: "grade_to_pass", title: "Pass Grade" },
      { data: "grade_achieved", title: "Grade Achieved" },
      { data: "max_grade", title: "Max Grade" },
      { data: "percentage", title: "Percentage" },
      { data: "pass_fail", title: "Pass/fail" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
