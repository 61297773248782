import { RootStateOrAny } from "react-redux";

import {
  CLIENTS_DT_REFRESH,
  CLIENTS_DT_REFRESH_RESEL,
  CLIENTS_SET_DT_PAGE,
  CLIENTS_SET_DT_ROW,
} from "../../actionTypes";

const initialState = {
  tables: {
    companiesDataTable: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {},
    },
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case CLIENTS_SET_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          companiesDataTable: {
            ...state.tables.companiesDataTable,
            viewedPage: action.payload,
          },
        },
      };
    case CLIENTS_SET_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          companiesDataTable: {
            ...state.tables.companiesDataTable,
            selectedRow: action.payload,
          },
        },
      };
    case CLIENTS_DT_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          companiesDataTable: {
            ...state.tables.companiesDataTable,
            selectedRow: null,
            triggerDtRefresh: {},
          },
        },
      };
    case CLIENTS_DT_REFRESH_RESEL:
      return {
        ...state,
        tables: {
          ...state.tables,
          companiesDataTable: {
            ...state.tables.companiesDataTable,
            selectedRow: action.payload.data.id,
            triggerDtRefresh: {},
          },
        },
      };
    default:
      return state;
  }
}
