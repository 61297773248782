import { RootStateOrAny } from "react-redux";

import {
  SUPPORT_MESSAGE_DT_REFRESH_RESEL,
  SUPPORT_MESSAGE_SET_DT_PAGE,
  SUPPORT_MESSAGE_SET_DT_ROW,
  SUPPORT_MESSAGE_SET_DT_REFRESH_URL,
  // dialog
  SUPPORT_MESSAGE_DIALOG_SHOW,
  SUPPORT_MESSAGE_DIALOG_HIDE,
  SUPPORT_MESSAGE_DIALOG_LOADING,
  SUPPORT_MESSAGE_DIALOG_NOT_LOADING,
} from "../../actionTypes";

const initialState = {
  tables: {
    supportMessageTable: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {},
      refreshUrl: "",
    },
  },
  dialog: {
    show: false,
    loading: false,
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case SUPPORT_MESSAGE_DT_REFRESH_RESEL:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportMessageTable: {
            ...state.tables.supportMessageTable,
            selectedRow: action.payload.data.id,
            triggerDtRefresh: {},
          },
        },
      };
    case SUPPORT_MESSAGE_SET_DT_REFRESH_URL:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportMessageTable: {
            ...state.tables.supportMessageTable,
            refreshUrl: action.payload,
          },
        },
      };
    case SUPPORT_MESSAGE_SET_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportMessageTable: {
            ...state.tables.supportMessageTable,
            viewedPage: action.payload,
          },
        },
      };
    case SUPPORT_MESSAGE_SET_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          supportMessageTable: {
            ...state.tables.supportMessageTable,
            selectedRow: action.payload,
          },
        },
      };
    case SUPPORT_MESSAGE_DIALOG_SHOW:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          show: true,
        },
      };
    case SUPPORT_MESSAGE_DIALOG_HIDE:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          show: false,
        },
      };
    case SUPPORT_MESSAGE_DIALOG_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loading: true,
        },
      };
    case SUPPORT_MESSAGE_DIALOG_NOT_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loading: false,
        },
      };
    default:
      return state;
  }
}
