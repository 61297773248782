import {
  PLANS_SET_DT_PAGE,
  PLANS_SET_DT_ROW,
  PLANS_DT_REFRESH,
  PLANS_DT_REFRESH_RESEL,
} from "redux/actionTypes";

import { RootStateOrAny } from "react-redux";

const initialState = {
  tables: {
    plansDataTable: {
      selectedRow: null,
      viewedPage: 0,
      triggerDtRefresh: {},
    },
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case PLANS_SET_DT_PAGE:
      return {
        ...state,
        tables: {
          ...state.tables,
          plansDataTable: {
            ...state.tables.plansDataTable,
            viewedPage: action.payload,
          },
        },
      };
    case PLANS_SET_DT_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          plansDataTable: {
            ...state.tables.plansDataTable,
            selectedRow: action.payload,
          },
        },
      };
    case PLANS_DT_REFRESH:
      return {
        ...state,
        tables: {
          ...state.tables,
          plansDataTable: {
            ...state.tables.plansDataTable,
            selectedRow: null,
            triggerDtRefresh: {},
          },
        },
      };
    case PLANS_DT_REFRESH_RESEL:
      return {
        ...state,
        tables: {
          ...state.tables,
          plansDataTable: {
            ...state.tables.plansDataTable,
            selectedRow: action.payload.data.id,
            triggerDtRefresh: {},
          },
        },
      };
    default:
      return state;
  }
}
