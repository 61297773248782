import React from "react";

import { Button, Modal, Spinner } from "react-bootstrap";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import apiCall from "functions/apiCall";

import { DELETE_DIALOG_HIDE } from "redux/actionTypes";

const DeleteDialog: React.FC<DeleteDialogPropTypes> = ({
  title,
  confirmationQuestion,
  confirmDeleteApiCallParams,
}) => {
  const dispatch = useDispatch();

  const showDialog = useSelector(
    (state: RootStateOrAny) => state.dialogs.deleteDialog.show
  );

  const deleteInProgress = useSelector(
    (state: RootStateOrAny) => state.dialogs.deleteDialog.loading
  );

  const handleDeleteClick = () => {
    dispatch(apiCall({}, confirmDeleteApiCallParams));
  };

  return (
    <Modal
      className="modal-primary"
      show={showDialog}
      onHide={() => dispatch({ type: DELETE_DIALOG_HIDE })}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!deleteInProgress ? (
          <p>{confirmationQuestion}</p>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleDeleteClick}
          disabled={deleteInProgress}
        >
          Delete
        </Button>
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: DELETE_DIALOG_HIDE })}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDialog;

interface DeleteDialogPropTypes {
  title: string;
  confirmationQuestion: string;
  confirmDeleteApiCallParams: any;
}
