import React from "react";

import { Col, Form, Row } from "react-bootstrap";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  HOME_PAGE_REPORTS_ADD_ALL_MONTHS,
  HOME_PAGE_REPORTS_ADD_MONTH,
  HOME_PAGE_REPORTS_REMOVE_ALL_MONTHS,
  HOME_PAGE_REPORTS_REMOVE_MONTH,
} from "redux/actionTypes";

const ReportMonthPicker = () => {
  return (
    <>
      <Row>
        <Col className="checkbox-radios" xs={12}>
          <Form.Label>Select Month(s)</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col className="checkbox-radios" xs={6} sm={5} md={4} lg={4} xl={3}>
          <MonthCheckbox label="All" value={99} />
        </Col>
      </Row>
      <Row>
        <Col className="checkbox-radios" xs={6} sm={5} md={4} lg={4} xl={3}>
          <MonthCheckbox label="January" value={0} />
          <MonthCheckbox label="March" value={2} />
          <MonthCheckbox label="May" value={4} />
          <MonthCheckbox label="July" value={6} />
          <MonthCheckbox label="September" value={8} />
          <MonthCheckbox label="November" value={10} />
        </Col>
        <Col className="checkbox-radios" xs={6} sm={5} md={4} lg={4} xl={3}>
          <MonthCheckbox label="February" value={1} />
          <MonthCheckbox label="April" value={3} />
          <MonthCheckbox label="June" value={5} />
          <MonthCheckbox label="August" value={7} />
          <MonthCheckbox label="October" value={9} />
          <MonthCheckbox label="December" value={11} />
        </Col>
      </Row>
    </>
  );
};

export default ReportMonthPicker;

const MonthCheckbox: React.FC<MonthCheckboxProps> = ({ label, value }) => {
  const dispatch = useDispatch();

  const { months } = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.dateOptions
  );

  const handleCheckboxChange = (monthId: number, value: any) => {
    if (monthId === 99 && value === true) {
      dispatch({ type: HOME_PAGE_REPORTS_ADD_ALL_MONTHS, payload: null });
    } else if (monthId === 99 && value === false) {
      dispatch({ type: HOME_PAGE_REPORTS_REMOVE_ALL_MONTHS, payload: null });
    } else if (value === true) {
      dispatch({ type: HOME_PAGE_REPORTS_ADD_MONTH, payload: monthId });
    } else {
      dispatch({ type: HOME_PAGE_REPORTS_REMOVE_MONTH, payload: monthId });
    }
  };

  return (
    <Form.Check>
      <Form.Check.Label>
        <Form.Check.Input
          type="checkbox"
          value={value}
          checked={value === 99 ? months.length === 12 : months.includes(value)}
          onChange={(e: any) =>
            handleCheckboxChange(parseInt(e.target.value), e.target.checked)
          }
        />
        <span className="form-check-sign" />
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

type MonthCheckboxProps = {
  label: string;
  value: number;
};
