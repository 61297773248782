import React, { useEffect, useState } from "react";

import { Card, Spinner, Row, Col } from "react-bootstrap";
// redux
import { RootStateOrAny, useSelector } from "react-redux";

const PlanInfoCard = () => {
  const { chartDataLoading, chartData } = useSelector(
    (state: RootStateOrAny) => state.homePage.topChart
  );

  const planName = useSelector(
    (state: RootStateOrAny) => state.auth.user.plan_name
  );

  const planEnrollments = useSelector(
    (state: RootStateOrAny) => state.auth.user.plan_enrollments
  );

  const [enrolmentsUsed, setEnrolmentsUsed] = useState(0);

  useEffect(() => {
    if ("plan_info_card" in chartData) {
      setEnrolmentsUsed(chartData.plan_info_card.data);
    }
  }, [chartData]);

  return (
    <Card>
      <Card.Body>
        <Card.Title as="h4">Plan Info</Card.Title>
        {!chartDataLoading && chartData && "plan_info_card" in chartData ? (
          <Card.Header>
            <InfoRow title="Plan" value={planName} />
            <InfoRow title="Enrollments Included" value={planEnrollments} />
            <InfoRow title="Enrollments Used" value={enrolmentsUsed} />
          </Card.Header>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default PlanInfoCard;

const InfoRow: React.FC<{ title: string; value: any }> = ({ title, value }) => {
  return (
    <Row className="mt-2">
      <Col xs={6} className="ml-0 pl-0">
        <p className="card-category">{title}</p>
      </Col>
      <Col xs={6} className="d-flex justify-content-center align-items-center">
        <p className="card-category" style={{ color: "#212529" }}>
          {value}
        </p>
      </Col>
    </Row>
  );
};
