import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const userRegistrations = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "User Registrations",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "User Registrations",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "username", title: "Username" },
      { data: "email", title: "Email" },
      { data: "company_name", title: "Company Name" },
      {
        data: { _: "date.display", sort: "date.@data-order" },
        title: "Date",
      },
      { data: "about_your_organization", title: "About Your Organization" },
      { data: "ifother", title: "If Other" },
      { data: "phone", title: "Phone" },
      { data: "title", title: "Title" },
      { data: "aboutyourself", title: "About Yourself" },
      { data: "agree_to_process_data", title: "Agree to Process Data" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
