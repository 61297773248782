import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const totalSiteEnrolmentsDt = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "Total Site Enrollments",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "Total Site Enrollments",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "month", title: "Month", orderable: false }, // 0
      { data: "enrolments", title: "Enrollments", responsivePriority: 1 }, // 1
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[1, "desc"]],
  },
};
