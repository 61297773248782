import React from "react";

import { Spinner } from "react-bootstrap";

const CustomSpinner: React.FC<CustomSpinnerProps> = ({ height }) => {
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ height: `${height}px` }}
    >
      <Spinner animation="border" />
    </div>
  );
};

export default CustomSpinner;

interface CustomSpinnerProps {
  height: number;
}
