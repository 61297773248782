const apiUrl = process.env.REACT_APP_API_V1_URL;

// auth
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  GLOBAL_LOADER_SHOW,
  AUTH_SET_TOKENS,
  GLOBAL_LOADER_HIDE,
  MSG_AUTH_LOGIN_SUCCESS,
  MSG_AUTH_LOGIN_ERROR,
} from "redux/actionTypes";

export const loginUserApiCallParams = {
  url: `${apiUrl}acc/token/`,
  method: "post",
  preSendDispatchTypes: [GLOBAL_LOADER_SHOW],
  successDispatchTypes: [
    AUTH_SET_TOKENS,
    AUTH_LOGIN_SUCCESS,
    GLOBAL_LOADER_HIDE,
    MSG_AUTH_LOGIN_SUCCESS,
  ],
  errorDispatchTypes: [
    AUTH_LOGIN_FAIL,
    GLOBAL_LOADER_HIDE,
    MSG_AUTH_LOGIN_ERROR,
  ],
};

export const checkIfLoggedInApiParams = {
  url: `${apiUrl}acc/get_user_from_token/`,
  method: "get",
  preSendDispatchTypes: [GLOBAL_LOADER_SHOW],
  successDispatchTypes: [AUTH_LOGIN_SUCCESS, GLOBAL_LOADER_HIDE],
  errorDispatchTypes: [GLOBAL_LOADER_HIDE],
};
