import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const sdvoeConsolidatedBlackbox = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    title: " SDVoE Consolidated Summary Report for Blackbox Academy",
    buttons: [
      "colvis",
      "copyHtml5",
      // "excelHtml5",
      // "pdfHtml5",
      {
        extend: "csvHtml5",
        title: " SDVoE Consolidated Summary Report for Blackbox Academy",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: " SDVoE Consolidated Summary Report for Blackbox Academy",
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    data: [],
    dom: "Bfrtip",
    scrollX: true,
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "company", title: "Company" },
      { data: "org_type", title: "Organization Type" },
      { data: "other", title: "Other" },
      { data: "email", title: "Email" },
      { data: "phone", title: "Phone" },
      { data: "city", title: "City" },
      { data: "country", title: "Country" },
      { data: "title", title: "Title" },
      { data: "first_access", title: "First Access" },
      { data: "first_access_time", title: "First Access Time" },
      { data: "last_access", title: "Last Access" },
      { data: "last_access_time", title: "Last Access Time" },
      { data: "course_name", title: "Course Name" },
      { data: "course_category", title: "Course Category" },
      { data: "grade", title: "Grade" },
      { data: "assessment_month", title: "Assessment Month" },
      { data: "assessment_year", title: "Assessment Year" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
