// custom
import ChangeOwnPasswordPage from "views/Pages/ChangeOwnPasswordPage";
import CompaniesPage from "./views/Pages/CompaniesPage";
import DatatableViewPage from "./views/Pages/DatatableViewPage";
import HomePage from "./views/Pages/HomePage";
import LoginPage from "views/Pages/LoginPage";
import MoodleInstancesPage from "./views/Pages/MoodleInstancesPage";
import PlansPage from "./views/Pages/PlansPage";
import ReportPackagesPage from "./views/Pages/ReportPackagesPage";
import ReportsDbEntriesPage from "./views/Pages/ReportsDbEntriesPage";
import SupportMessagesPage from "./views/Pages/SupportMessagesPage";
import SupportPage from "./views/Pages/SupportPage";
import UserManagementPage from "./views/Pages/UserManagementPage";
// template
// import Dashboard from "views/Dashboard.js";
// import Buttons from "views/Components/Buttons.js";
// import GridSystem from "views/Components/GridSystem.js";
// import Panels from "views/Components/Panels.js";
// import SweetAlert from "views/Components/SweetAlertPage.js";
// import Notifications from "views/Components/Notifications.js";
// import Icons from "views/Components/Icons.js";
// import Typography from "views/Components/Typography.js";
// import RegularForms from "views/Forms/RegularForms.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import Wizard from "views/Forms/Wizard/Wizard.js";
// import RegularTables from "views/Tables/RegularTables.js";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import ReactTables from "views/Tables/ReactTables.js";
// import GoogleMaps from "views/Maps/GoogleMaps.js";
// import FullScreenMap from "views/Maps/FullScreenMap.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Charts from "views/Charts.js";
// import Calendar from "views/Calendar.js";
// import UserPage from "views/Pages/UserPage.js";
// import RegisterPage from "views/Pages/RegisterPage.js";
// import LockScreenPage from "views/Pages/LockScreenPage.js";

const routes = [
  // {
  //   path: "/dashboard",
  //   layout: "/portal",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  // },
  {
    path: "/support/:slug",
    name: "",
    component: SupportMessagesPage,
    layout: "/portal",
    invisibleLink: true,
  },
  {
    path: "/home/datatable-view",
    name: "",
    component: DatatableViewPage,
    layout: "/portal",
    invisibleLink: true,
  },
  {
    path: "/home",
    layout: "/portal",
    name: "Homepage",
    icon: "nc-icon nc-chart-bar-32",
    component: HomePage,
  },
  {
    collapse: true,
    path: "/portal",
    name: "Admin Tools",
    state: "openAdminTools",
    icon: "nc-icon nc-badge",
    adminLink: true,
    views: [
      {
        path: "/companies",
        layout: "/portal",
        name: "Companies",
        mini: "C",
        component: CompaniesPage,
      },
      {
        path: "/moodle-instances",
        layout: "/portal",
        name: "Moodle Instances",
        mini: "MI",
        component: MoodleInstancesPage,
      },
      {
        path: "/plans",
        layout: "/portal",
        name: "Plans",
        mini: "P",
        component: PlansPage,
      },
      {
        path: "/report-db-entries",
        layout: "/portal",
        name: "Report DB Entries",
        mini: "RDE",
        component: ReportsDbEntriesPage,
      },
      {
        path: "/report-packages",
        layout: "/portal",
        name: "Report Packages",
        mini: "RP",
        component: ReportPackagesPage,
      },
      {
        path: "/user-management",
        layout: "/portal",
        name: "User Management",
        mini: "UM",
        component: UserManagementPage,
      },
    ],
  },
  {
    path: "/support",
    layout: "/portal",
    name: "Support",
    icon: "nc-icon nc-chat-round",
    component: SupportPage,
  },
  // {
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       path: "/buttons",
  //       layout: "/portal",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       path: "/grid-system",
  //       layout: "/portal",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       path: "/panels",
  //       layout: "/portal",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       path: "/sweet-alert",
  //       layout: "/portal",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       path: "/notifications",
  //       layout: "/portal",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       path: "/icons",
  //       layout: "/portal",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       path: "/typography",
  //       layout: "/portal",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       layout: "/portal",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //     },
  //     {
  //       path: "/extended-forms",
  //       layout: "/portal",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //     },
  //     {
  //       path: "/validation-forms",
  //       layout: "/portal",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //     },
  //     {
  //       path: "/wizard",
  //       layout: "/portal",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       layout: "/portal",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       path: "/extended-tables",
  //       layout: "/portal",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       path: "/react-table",
  //       layout: "/portal",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       layout: "/portal",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       layout: "/portal",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       path: "/vector-maps",
  //       layout: "/portal",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/portal",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   path: "/calendar",
  //   layout: "/portal",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  {
    collapse: true,
    path: "/pages",
    name: "Auth",
    state: "openPages",
    icon: "nc-icon nc-puzzle-10",
    invisibleLink: true,
    views: [
      // {
      //   path: "/user-page",
      //   layout: "/portal",
      //   name: "User Page",
      //   mini: "UP",
      //   component: UserPage,
      // },
      {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
      },
      {
        path: "/change-password",
        layout: "/auth",
        name: "Change Password",
        mini: "CP",
        component: ChangeOwnPasswordPage,
      },
      // {
      //   path: "/register-page",
      //   layout: "/auth",
      //   name: "Register",
      //   mini: "RP",
      //   component: RegisterPage,
      // },
      // {
      //   path: "/lock-screen-page",
      //   layout: "/auth",
      //   name: "Lock Screen Page",
      //   mini: "LSP",
      //   component: LockScreenPage,
      // },
    ],
  },
];
export default routes;
