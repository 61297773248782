import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import apiCall from "functions/apiCall";

// react-bootstrap components
import { Button, Card, Form, Container, Col } from "react-bootstrap";

import { loginUserApiCallParams } from "../../config/api_calls";

function LoginPage(location: any) {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state: RootStateOrAny) => state.auth.isAuthenticated
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [cardClasses, setCardClasses] = useState("card-hidden");

  const handleLoginClick = () => {
    dispatch(
      apiCall({ email: email, password: password }, loginUserApiCallParams)
    );
  };

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 500);
  });

  // if user is authenticated, redirect oto main page
  if (isAuthenticated) {
    return (
      <Redirect
        to={
          location.location.state.originalLocation
            ? location.location.state.originalLocation
            : "/"
        }
      />
    );
  }
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("../../assets/img/full-screen-image-2.jpg")}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form action="" className="form" method="">
                <Card className={"card-login " + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">Clavia Portal</h3>
                    <h4 className="header text-center">
                      Please login to access your dashboard
                    </h4>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          placeholder="Username"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                          onKeyPress={(e: any) =>
                            e.key === "Enter" && handleLoginClick()
                          }
                        />
                      </Form.Group>
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          placeholder="Password"
                          type="password"
                          onChange={(e) => setPassword(e.currentTarget.value)}
                          value={password}
                          onKeyPress={(e: any) =>
                            e.key === "Enter" && handleLoginClick()
                          }
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button
                      style={{
                        backgroundColor: "#1D75DE",
                        border: "1px solid #1966c1",
                      }}
                      className="btn-wd"
                      variant="info"
                      onClick={handleLoginClick}
                    >
                      Login
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("../../assets/img/backgrounds/login_bg.jpeg") +
              ")",
          }}
        />
      </div>
    </>
  );
}

export default LoginPage;
