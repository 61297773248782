// fetches PDF from a given link and pops out an 'open'/'save' dialog with provided filename

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import download from "downloadjs";

import {
  GLOBAL_LOADER_HIDE,
  GLOBAL_LOADER_SHOW,
  MSG_CUSTOM_MSG,
  MSG_SHOW_ERROR_MSG,
} from "../redux/actionTypes";
import axiosApiInstance from "./axiosApiInstance";

// types
export type DocParamsType = {
  url: string;
  fetchMsg: string;
  fileName: string;
  format: "csv" | "pdf" | "";
};

async function useFetchDocument(docParams: DocParamsType) {
  const dispatch = useDispatch();
  const [docGenerated, setDocGenerated] = useState(false);

  useEffect(() => {
    // first time the hook will run with initial value of '' and do nothing
    if (docParams && docParams.url && docParams.fileName) {
      // show fetching message if included in params
      if ("fetchMsg" in docParams) {
        dispatch({
          type: MSG_CUSTOM_MSG,
          payload: {
            color: "info",
            text: docParams.fetchMsg,
          },
        });
      }

      // fetch the document
      dispatch({ type: GLOBAL_LOADER_SHOW }); // set loading cursor
      axiosApiInstance
        .get(docParams.url, { responseType: "blob" })

        .then((response) => {
          // const content = response.headers["content-type"];

          const mimeType =
            docParams.format === "csv" ? "text/csv" : "application/pdf";

          download(
            response.data,
            `${docParams.fileName}.${docParams.format}`,
            mimeType
          );
          dispatch({ type: GLOBAL_LOADER_HIDE });
        })
        .catch((err) => {
          dispatch({ type: GLOBAL_LOADER_HIDE });
          dispatch({ type: MSG_SHOW_ERROR_MSG, payload: err });
        });
    }
  }, [docParams, dispatch]);

  // this returns whether PDF was generated or not. If PDF is generated, setDocGenerated back
  //  to false before turning true, as it will stay true every run otherwise and return 'true' every run
  if (docGenerated) {
    setDocGenerated(false);
    return true;
  } else {
    return false;
  }
}

export default useFetchDocument;
