// plans
import {
  DELETE_DIALOG_LOADING,
  DELETE_DIALOG_NOT_LOADING,
  DELETE_DIALOG_HIDE,
  MSG_PLAN_DEL_SUCCESS,
  PLANS_DT_REFRESH,
  ADD_EDIT_DIALOG_LOADING,
  ADD_EDIT_DIALOG_HIDE,
  ADD_EDIT_DIALOG_NOT_LOADING,
  ADD_EDIT_DIALOG_FORM_POPULATE,
  MSG_PLAN_ADD_SUCCESS,
  PLANS_DT_REFRESH_RESEL,
  MSG_PLAN_UPDATE_SUCCESS,
  MSG_SHOW_ERROR_MSG,
} from "redux/actionTypes";

const apiUrl = process.env.REACT_APP_API_V1_URL;

export const getAddPlanParams = () => {
  return {
    url: `${apiUrl}plans/`,
    method: "post",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_PLAN_ADD_SUCCESS,
      PLANS_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const populateAddEditPlanFormParams = (instanceId: number) => {
  return {
    url: `${apiUrl}plans/${instanceId}`,
    method: "get",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      ADD_EDIT_DIALOG_FORM_POPULATE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [
      MSG_SHOW_ERROR_MSG,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
  };
};

export const getEditPlanParams = (instId: number) => {
  return {
    url: `${apiUrl}plans/${instId}`,
    method: "put",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_LOADING],
    successDispatchTypes: [
      MSG_PLAN_UPDATE_SUCCESS,
      PLANS_DT_REFRESH_RESEL,
      ADD_EDIT_DIALOG_HIDE,
      ADD_EDIT_DIALOG_NOT_LOADING,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, ADD_EDIT_DIALOG_NOT_LOADING],
  };
};

export const getDelPlanParams = (instId: number) => {
  return {
    url: `${apiUrl}plans/${instId}`,
    method: "delete",
    preSendDispatchTypes: [DELETE_DIALOG_LOADING],
    successDispatchTypes: [
      DELETE_DIALOG_NOT_LOADING,
      DELETE_DIALOG_HIDE,
      MSG_PLAN_DEL_SUCCESS,
      PLANS_DT_REFRESH,
    ],
    errorDispatchTypes: [MSG_SHOW_ERROR_MSG, DELETE_DIALOG_NOT_LOADING],
  };
};
