import React from "react";

import ReactLoading from "react-loading";

import { useSelector, RootStateOrAny } from "react-redux";

const GlobalLoader = () => {
  const authLoader = useSelector(
    (state: RootStateOrAny) => state.globalLoader.showLoader
  );

  return authLoader ? (
    <div className="loader-container">
      <ReactLoading type="bars" color="#0A5499" height={200} width={200} />
    </div>
  ) : (
    <></>
  );
};

export default GlobalLoader;
