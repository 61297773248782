const apiUrl = process.env.REACT_APP_API_V1_URL;

import {
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_POPULATE,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_LOADING,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING,
  MSG_SHOW_ERROR_MSG,
} from "redux/actionTypes";

export const getAddEditFormSuggestionsParams = (formId: string) => {
  return {
    url: `${apiUrl}form-options/?form_id=${formId}`,
    method: "get",
    preSendDispatchTypes: [ADD_EDIT_DIALOG_FORM_SUGGESTIONS_LOADING],
    successDispatchTypes: [
      ADD_EDIT_DIALOG_FORM_SUGGESTIONS_POPULATE,
      ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING,
    ],
    errorDispatchTypes: [
      ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR,
      ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING,
      MSG_SHOW_ERROR_MSG,
    ],
  };
};
