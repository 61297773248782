import * as React from "react";

import { Route, Redirect, RouteProps } from "react-router-dom";

import { useSelector, RootStateOrAny } from "react-redux";

interface PrivateRouteProps extends RouteProps {
  component: any;
  location?: any; // used to capture original location URL so the user can be redirected to it after credentials check
  // on LoginPage
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, location, ...rest } = props;

  const auth = useSelector((state: RootStateOrAny) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.userLoading || auth.isAuthenticated == null) {
          // return <CustomLoadingSpinner positionFixed={true}/>
          return <p>Loading...</p>;
        } else if (!auth.isAuthenticated) {
          // for when (1) not authenticated, (2) refreshing site (comes up as not authenticated first)
          return (
            <Redirect
              to={{
                pathname: "/auth/login-page",
                state: { originalLocation: location.pathname },
              }}
            />
          );
        } else if (auth.user.req_pass_change) {
          return <Redirect to="/auth/change-password" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
