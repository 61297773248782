import {
  // add/edit dialog
  ADD_EDIT_DIALOG_HIDE,
  ADD_EDIT_DIALOG_FORM_CLEAR,
  ADD_EDIT_DIALOG_FORM_POPULATE,
  ADD_EDIT_DIALOG_LOADING,
  ADD_EDIT_DIALOG_NOT_LOADING,
  ADD_EDIT_DIALOG_SHOW,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_POPULATE,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_LOADING,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING,
  // delete dialog
  DELETE_DIALOG_LOADING,
  DELETE_DIALOG_NOT_LOADING,
  DELETE_DIALOG_SHOW,
  DELETE_DIALOG_HIDE,
} from "redux/actionTypes";

import { RootStateOrAny } from "react-redux";

const initialState = {
  deleteDialog: {
    show: false,
    loading: false,
  },
  addEditDialog: {
    show: false,
    loading: false,
    suggestionsLoading: false,
    formFields: {},
    formSuggestions: {},
  },
};

export default function (state = initialState, action: RootStateOrAny) {
  switch (action.type) {
    case ADD_EDIT_DIALOG_LOADING: // add edit dialog
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          loading: true,
        },
      };
    case ADD_EDIT_DIALOG_FORM_CLEAR:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          formFields: {},
        },
      };
    case ADD_EDIT_DIALOG_FORM_POPULATE:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          formFields: action.payload.data,
        },
      };
    case ADD_EDIT_DIALOG_NOT_LOADING:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          loading: false,
        },
      };
    case ADD_EDIT_DIALOG_SHOW:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          show: true,
        },
      };
    case ADD_EDIT_DIALOG_HIDE:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          show: false,
        },
      };
    case ADD_EDIT_DIALOG_FORM_SUGGESTIONS_POPULATE:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          formSuggestions: action.payload.data,
        },
      };
    case ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          formSuggestions: {},
        },
      };
    case ADD_EDIT_DIALOG_FORM_SUGGESTIONS_LOADING:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          suggestionsLoading: true,
        },
      };
    case ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING:
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          suggestionsLoading: false,
        },
      };
    case DELETE_DIALOG_LOADING: // delete dialog
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          loading: true,
        },
      };
    case DELETE_DIALOG_NOT_LOADING:
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          loading: false,
        },
      };
    case DELETE_DIALOG_SHOW:
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          show: true,
        },
      };
    case DELETE_DIALOG_HIDE:
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          show: false,
        },
      };
    default:
      return state;
  }
}
