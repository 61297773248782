import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";

// template
import { Card, Container, Row, Col } from "react-bootstrap";
// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { MSG_CUSTOM_MSG } from "../../redux/actionTypes";

// functions
import useGetSelectedReportParams from "functions/useGetSelectedReportParams";
// custom components
import BackButton from "../../components/_Custom/BackButton";
// datatables
import Datatable from "../../components/_Custom/DataTables/DataTable";
import useDataTableRefresh from "functions/dataTables/useDatatableRefresh";
import {
  assessmentCompletion,
  averageCourseFeedback,
  certificatesByUser,
  claviaTvChannelPartner,
  coursesByCategoryStructure,
  sdvoeCertificates,
  sdvoeConsolidatedBlackbox,
  sdvoeConsolidatedNetgear,
  sdvoeConsolidatedSdvoe,
  sdvoeQuiz,
  totalSiteEnrolmentsDt,
  userCourseEnrolment,
  userCourseEnrolmentCleerline,
  userRegistrations,
  userRegistrationsTypeC,
  userRegistrationsVelocity,
  assessmentCompletionCleerline,
  yearOnYearGrowthReport,
  netgearMasterReport,
} from "views/dataTables/report_datatables";
import { studentCompetency } from "../dataTables/report_datatables/partials/studentCompetencyReport";
import { questionAnalysis } from "../dataTables/report_datatables/partials/questionAnalysisReport";
import { siteUsageByUser } from "../dataTables/report_datatables/partials/siteUsageByUser";
import { totalCertificatesByCompanyAndCountry } from "../dataTables/report_datatables/partials/totalCertificatesByCompanyAndCountry";
import { topTenPerformers } from "../dataTables/report_datatables/partials/topTenPerformers";
import { detailedFeedback } from "../dataTables/report_datatables/partials/detailedFeedback";

const DatatableViewPage = () => {
  const history = useHistory();

  const tableParamsDict = {
    assessment_completion: assessmentCompletion,
    assessment_completion_cleerline: assessmentCompletionCleerline,
    average_course_feedback: averageCourseFeedback,
    certificates_by_user: certificatesByUser,
    clavia_tv_channel_partner: claviaTvChannelPartner,
    courses_by_category_structure: coursesByCategoryStructure,
    detailed_feedback: detailedFeedback,
    netgear_master: netgearMasterReport,
    question_analysis: questionAnalysis,
    sdvoe_certificates: sdvoeCertificates,
    sdvoe_consolidated_blackbox: sdvoeConsolidatedBlackbox,
    sdvoe_consolidated_netgear: sdvoeConsolidatedNetgear,
    sdvoe_consolidated_sdvoe: sdvoeConsolidatedSdvoe,
    sdvoe_quiz: sdvoeQuiz,
    site_usage_by_user: siteUsageByUser,
    student_competency: studentCompetency,
    top_ten_performers: topTenPerformers,
    total_certificates_by_company_and_country: totalCertificatesByCompanyAndCountry,
    total_site_enrolments: totalSiteEnrolmentsDt,
    user_course_enrolment: userCourseEnrolment,
    user_course_enrolment_cleerline: userCourseEnrolmentCleerline,
    user_registrations: userRegistrations,
    user_registrations_type_c: userRegistrationsTypeC,
    user_registrations_velocity: userRegistrationsVelocity,
    year_on_year_growth: yearOnYearGrowthReport,
  };

  const selectedReportParams = useGetSelectedReportParams();

  const { selectedMoodleInstanceId } = useSelector(
    (state: RootStateOrAny) => state.homePage
  );

  // construct refresh url
  const { selectedReport, checkboxUrlSuffix, dateUrlSuffix } = useSelector(
    (state: RootStateOrAny) => state.homePage.reports
  );

  const [tableParams, setTableParams] = useState(null);

  // eslint-disable-next-line no-undef
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  // if no report is selected, the view was probably loaded manually - redirect to homepage and show error message
  useEffect(() => {
    if (!selectedReport) {
      dispatch({
        type: MSG_CUSTOM_MSG,
        payload: {
          color: "danger",
          text: "Please use Report Generation Panel to generate reports",
        },
      });
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setTableParams({
      // @ts-ignore
      ...tableParamsDict[selectedReport],
      refreshUrl: `reports/?report_id=${selectedReport}&instance_id=${selectedMoodleInstanceId}${
        dateUrlSuffix + checkboxUrlSuffix
      }&rep_format=datatable`,
    });
  }, [
    selectedReport,
    checkboxUrlSuffix,
    dateUrlSuffix,
    selectedMoodleInstanceId,
  ]);

  useDataTableRefresh(dataTable, tableParams);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <BackButton />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">{`${selectedReportParams?.label}`}</Card.Title>
                {/*<p className="card-category">PARAMS</p>*/}
              </Card.Header>
              <Card.Body>
                {tableParams ? (
                  <Datatable
                    setDataTable={setDataTable}
                    tableId="reportTable"
                    // @ts-ignore
                    tableParams={tableParams}
                  />
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DatatableViewPage;
