import React, { useEffect, useState } from "react";

import { Col, Form } from "react-bootstrap";
// redux

import { useDispatch } from "react-redux";
import { HOME_PAGE_REPORTS_SET_CHECKBOX_URL_SUFFIX } from "../../../../../../../redux/actionTypes";

// functions
import useGetSelectedReportParams from "functions/useGetSelectedReportParams";

// styles
import { rowWithTopMargin } from "../../../ReportGenerationPanel";

const CheckboxFilters: React.FC = () => {
  const [paramsObj, setParamsObj] = useState({});

  const reportInfo = useGetSelectedReportParams();

  const dispatch = useDispatch();

  // on report params change, set params object to unchecked values of checkboxes
  useEffect(() => {
    const tempObj = {};
    reportInfo?.checkbox_filters.forEach((checkBoxInfo) => {
      // @ts-ignore
      tempObj[checkBoxInfo.id] = checkBoxInfo.option_2;
    });

    setParamsObj(tempObj);
  }, [reportInfo]);

  // every time params object changes, set new URL suffix
  useEffect(() => {
    let urlSuffix = "";
    for (const [key, value] of Object.entries(paramsObj)) {
      urlSuffix += `&${key}=${value}`;
    }
    dispatch({
      type: HOME_PAGE_REPORTS_SET_CHECKBOX_URL_SUFFIX,
      payload: urlSuffix,
    });
  }, [paramsObj]);

  return reportInfo?.checkbox_filters && reportInfo.checkbox_filters.length ? (
    <>
      <Form.Row style={rowWithTopMargin}>
        <Col className="checkbox-radios" xs={12}>
          <Form.Label>Options</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row style={rowWithTopMargin}>
        <Col className="checkbox-radios" xs={6} sm={5} md={4} lg={4} xl={3}>
          {reportInfo.checkbox_filters.map((checkboxFilterInfo, index) => {
            return (
              <React.Fragment key={`params-checkbox-${index}}`}>
                <ParamsCheckbox
                  id={checkboxFilterInfo.id}
                  title={checkboxFilterInfo.title}
                  checked={
                    // @ts-ignore
                    paramsObj[checkboxFilterInfo.id] ===
                    checkboxFilterInfo.option_1
                  }
                  onChange={(checked) => {
                    const tempObj = { ...paramsObj };
                    // @ts-ignore
                    tempObj[checkboxFilterInfo.id] = checked
                      ? checkboxFilterInfo.option_1
                      : checkboxFilterInfo.option_2;
                    setParamsObj(tempObj);
                  }}
                />
              </React.Fragment>
            );
          })}
        </Col>
      </Form.Row>
    </>
  ) : (
    <></>
  );
};

export default CheckboxFilters;

const ParamsCheckbox: React.FC<CustomCheckboxProps> = ({
  title,
  checked,
  onChange,
}) => {
  return (
    <Form.Check>
      <Form.Check.Label>
        <Form.Check.Input
          type="checkbox"
          checked={checked}
          onChange={(e: any) => onChange(e.target.checked)}
        />
        <span className="form-check-sign" />
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
};

type CustomCheckboxProps = {
  id: string;
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};
