import { DtLoadingSpinner } from "components/_Custom/DataTables/DtLoadingSpinner";

export const userCourseEnrolmentCleerline = {
  tableNativeParams: {
    autoWidth: true,
    responsive: false,
    scrollX: true,
    buttons: [
      "colvis",
      "copyHtml5",
      {
        extend: "csvHtml5",
        title: "User Course Enrolment (Cleerline)",
        exportOptions: {
          columns: ":visible",
        },
      },
      {
        extend: "print",
        title: "User Course Enrolment (Cleerline)",
        exportOptions: {
          columns: ":visible",
        },
      },
      // "excelHtml5",
      // "pdfHtml5",
    ],
    data: [],
    dom: "Bfrtip",
    select: {
      style: "single",
    },
    columns: [
      { data: "first_name", title: "First Name" },
      { data: "last_name", title: "Last Name" },
      { data: "username", title: "Username" },
      { data: "email", title: "Email" },
      { data: "country", title: "Country" },
      { data: "city", title: "City" },
      { data: "phone", title: "Phone" },
      { data: "state", title: "State" },
      { data: "course", title: "Course" },
      { data: "category", title: "Category" },
    ],
    processing: true,
    language: {
      processing: DtLoadingSpinner,
      emptyTable: "No data",
    },
    aaSorting: [[0, "asc"]],
  },
};
