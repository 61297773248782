import React, { useMemo } from "react";

import { Col } from "react-bootstrap";

// redux
import {
  HOME_PAGE_SET_REPORTS_TO_YEAR,
  HOME_PAGE_SET_REPORTS_FROM_YEAR,
  HOME_PAGE_SET_REPORTS_YEAR,
} from "redux/actionTypes";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Form } from "react-bootstrap";

const ReportYearPicker: React.FC<ReportYearPickerType> = ({ type }) => {
  const setYear = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.dateOptions[type]
  );

  const yearOptions = useMemo(() => {
    const options = [];
    const currentYear = new Date().getFullYear();

    for (let year = currentYear; year >= 2010; year--) {
      options.push({
        value: year,
        label: year.toString(),
      });
    }

    return options;
  }, []);

  const dispatch = useDispatch();

  return (
    <Col xs="6">
      <Form.Group>
        <Form.Label>
          {type === "fromYear" ? "From " : type === "toYear" ? "To " : ""}Year
        </Form.Label>
        <Form.Control
          as="select"
          value={setYear}
          onChange={(e) => {
            dispatch({
              type:
                type === "fromYear"
                  ? HOME_PAGE_SET_REPORTS_FROM_YEAR
                  : type === "toYear"
                  ? HOME_PAGE_SET_REPORTS_TO_YEAR
                  : HOME_PAGE_SET_REPORTS_YEAR,
              payload: parseInt(e.target.value),
            });
          }}
        >
          {yearOptions !== [] &&
            yearOptions.map((option, index) => {
              return (
                <option key={`year-option-${index}`} value={option.value}>
                  {option.label}
                </option>
              );
            })}
        </Form.Control>
      </Form.Group>
    </Col>
  );
};

export default ReportYearPicker;

type ReportYearPickerType = {
  type: "year" | "fromYear" | "toYear";
};
