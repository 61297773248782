import { useState, useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";

import useGetSelectedReportParams from "./useGetSelectedReportParams";

const useValidateReportDateFields = () => {
  const {
    date,
    fromDate,
    toDate,
    fromYear,
    toYear,
    year,
    months,
  } = useSelector(
    (state: RootStateOrAny) => state.homePage.reports.dateOptions
  );

  // gets selected report parameters
  const reportInfo = useGetSelectedReportParams();

  const [formValid, setFormValid] = useState({ valid: true, message: "" });

  useEffect(() => {
    let validityObj = { valid: true, message: "" };
    if (reportInfo) {
      // check date fields
      if (reportInfo.accepts_date && (!date || date === ""))
        validityObj = { valid: false, message: "Please provide the date" };
      // check date range
      else if (reportInfo.accepts_date_range && !fromDate) {
        validityObj = {
          valid: false,
          message: "Please provide the start date",
        };
      } else if (reportInfo.accepts_date_range && !toDate) {
        validityObj = { valid: false, message: "Please provide the end date" };
      } else if (reportInfo.accepts_date_range && fromDate > toDate) {
        validityObj = {
          valid: false,
          message: "End date cannot go after the start date",
        };
      }
      // year fields (year is always provided - can't clear, only validating range)
      else if (reportInfo.accepts_year_range && fromYear > toYear) {
        validityObj = {
          valid: false,
          message: "End year cannot go after the start year",
        };
      }
      // month multi-select
      else if (reportInfo.accepts_multiselect_month && months.length === 0) {
        validityObj = {
          valid: false,
          message: "Please select at least one month",
        };
      }
    }

    setFormValid(validityObj);
  }, [reportInfo, toDate, fromDate, fromYear, toYear, year, date, months]);

  return formValid;
};

export default useValidateReportDateFields;
