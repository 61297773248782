import React from "react";

import { useHistory } from "react-router";

import { Button } from "react-bootstrap";

const BackButton: React.FC<BackButtonProps> = ({ overrideUrl }) => {
  const history = useHistory();

  return (
    <Button
      className="btn btn-outline mr-1"
      type="button"
      variant="info"
      onClick={
        // @ts-ignore
        overrideUrl ? () => history.push(overrideUrl) : () => history.goBack()
      }
    >
      <span className="btn-label">
        <i className="fas fa-arrow-left" />
      </span>
    </Button>
  );
};

export default BackButton;

type BackButtonProps = {
  overrideUrl?: string;
};
