import React, { useEffect, useState } from "react";

import axiosApiInstance from "../../functions/axiosApiInstance";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  MSG_CUSTOM_MSG,
  SUPPORT_MESSAGE_DIALOG_SHOW,
  SUPPORT_MESSAGE_SET_DT_REFRESH_URL,
} from "../../redux/actionTypes";
// template
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
// custom components
import Datatable from "../../components/_Custom/DataTables/DataTable";
import AddButton from "../../components/_Custom/AddButton";
import BackButton from "../../components/_Custom/BackButton";
import SupportMessageDialog from "../../components/_Custom/Dialogs/SupportMessageDialog";
// custom functions
import useDataTableRefresh from "../../functions/dataTables/useDatatableRefresh";
// datatable params
import { supportMessagesTableParams } from "../dataTables/supportMessagesTableParams";
// types
import { AxiosResponse } from "axios";
// api calls
import apiCall from "../../functions/apiCall";
import { getApproveSupportReqFromPageParams } from "../../config/api_calls";

const apiUrl = process.env.REACT_APP_API_V1_URL;

const SupportMessagesPage: React.FC = (props: any) => {
  const caseId = props.match.params.slug;

  const dispatch = useDispatch();

  const { admin } = useSelector((state: RootStateOrAny) => state.auth.user);

  const tableRefresTrig = useSelector(
    (state: RootStateOrAny) =>
      state.supportMessagesPage.tables.supportMessageTable.triggerDtRefresh
  );

  // eslint-disable-next-line no-undef
  const [dataTable, setDataTable] = useState<DataTables.Api | undefined>(
    undefined
  );

  // parent case info (top screen)
  const [caseName, setCaseName] = useState("");
  const [caseDataLoading, setCaseDataLoading] = useState(false);
  const [caseStatus, setCaseStatus] = useState(null);
  const [caseStatusStr, setCaseStatusStr] = useState("");
  const [caseUser, setCaseUser] = useState("");

  // seting table refresh URL based on case for which messages are viewed
  useEffect(() => {
    dispatch({
      type: SUPPORT_MESSAGE_SET_DT_REFRESH_URL,
      payload: `support-cases/${caseId}/messages`,
    });

    // loading case (parent) details to display above table
    setCaseDataLoading(true);
    axiosApiInstance
      .get(`${apiUrl}support-cases/${caseId}`)
      .then((resp: AxiosResponse) => {
        setCaseName(resp.data.name);
        setCaseUser(resp.data.user_name_str);
        setCaseStatusStr(resp.data.status_str);
        setCaseStatus(resp.data.status);
        setCaseDataLoading(false);
      })
      .catch(() =>
        dispatch({
          type: MSG_CUSTOM_MSG,
          payload: {
            color: "danger",
            text: "Error loading case info. Please refresh and try again.",
          },
        })
      );
  }, [tableRefresTrig]);

  useDataTableRefresh(dataTable, supportMessagesTableParams);

  return (
    <Container fluid>
      <Row>
        <Col>
          <BackButton overrideUrl="/portal/support" />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              {caseDataLoading ? (
                <div style={{ padding: 15 }}>
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <Card.Title as="h4">{`${caseName}`}</Card.Title>
                  <p className="card-category">
                    {admin
                      ? `By ${caseUser}, status: ${caseStatusStr}`
                      : `Status: ${caseStatusStr}`}
                  </p>
                  {admin ? (
                    <ApprovalButtons caseId={caseId} caseStatus={caseStatus} />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Card.Header>
            <Card.Body>
              <Datatable
                setDataTable={setDataTable}
                tableId="supportMessageTable"
                tableParams={supportMessagesTableParams}
              />
            </Card.Body>
            {/*show reply button to all statuses except for 4 (Resolved)*/}
            {caseStatus !== 4 ? (
              <Card.Footer>
                <AddButton
                  titleOverride="Reply"
                  handleAddClick={() =>
                    dispatch({
                      type: SUPPORT_MESSAGE_DIALOG_SHOW,
                      payload: null,
                    })
                  }
                />
              </Card.Footer>
            ) : (
              <></>
            )}
          </Card>
        </Col>
      </Row>
      <SupportMessageDialog caseId={caseId} />
    </Container>
  );
};

export default SupportMessagesPage;

const ApprovalButtons: React.FC<{
  caseId: number;
  caseStatus: number | null;
}> = ({ caseId, caseStatus }) => {
  const dispatch = useDispatch();

  return (
    <Row>
      <Col>
        <>
          {caseStatus && [1, 2, 3, 5].includes(caseStatus) ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-48903503">Mark as Resolved</Tooltip>
              }
            >
              <Button
                className="btn btn-outline mr-1"
                type="button"
                variant="success"
                onClick={
                  () =>
                    dispatch(
                      apiCall(
                        { action: 1 },
                        getApproveSupportReqFromPageParams(caseId)
                      )
                    ) // approve request
                }
              >
                <span className="btn-label">
                  <i className="fas fa-check" />
                </span>
              </Button>
            </OverlayTrigger>
          ) : (
            <></>
          )}
          {caseStatus && [1, 2, 4, 5].includes(caseStatus) ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-48903503">
                  Mark as Pending User Input
                </Tooltip>
              }
            >
              <Button
                className="btn btn-outline mr-1"
                type="button"
                variant="primary"
                onClick={
                  () =>
                    dispatch(
                      apiCall(
                        { action: 0 },
                        getApproveSupportReqFromPageParams(caseId)
                      )
                    ) // send request back
                }
              >
                <span className="btn-label">
                  <i className="fas fa-arrow-right" />
                </span>
              </Button>
            </OverlayTrigger>
          ) : (
            <></>
          )}
        </>
      </Col>
    </Row>
  );
};
