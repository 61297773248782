import React from "react";

// react-bootstrap components
import { Navbar, Container } from "react-bootstrap";

const AdminNavbar: React.FC = () => {
  // const [collapseOpen, setCollapseOpen] = React.useState(false);

  const bannerUrl = "https://clavia.tv/clavia_portal/Clavia%20Portal%20V2.png";

  return (
    <>
      <Navbar expand="lg" className="disappearing-navbar">
        <Container fluid>
          <div className="navbar-wrapper">
            {/*  <div className="navbar-minimize">*/}
            {/*<Button*/}
            {/*  className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"*/}
            {/*  variant="dark"*/}
            {/*  onClick={() => document.body.classList.toggle("sidebar-mini")}*/}
            {/*>*/}
            {/*  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>*/}
            {/*  <i className="fas fa-bars visible-on-sidebar-mini"></i>*/}
            {/*</Button>*/}
            {/*    <Button*/}
            {/*      className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"*/}
            {/*      variant="dark"*/}
            {/*      onClick={() =>*/}
            {/*        document.documentElement.classList.toggle("nav-open")*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>*/}
            {/*      <i className="fas fa-bars visible-on-sidebar-mini"></i>*/}
            {/*    </Button>*/}
            {/*  </div>*/}
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            // onClick={() => setCollapseOpen(!collapseOpen)}
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          >
            <span className="navbar-toggler-bar burger-lines" />
            <span className="navbar-toggler-bar burger-lines" />
            <span className="navbar-toggler-bar burger-lines" />
          </button>
        </Container>
      </Navbar>

      {/*@ts-ignore*/}
      <div style={bannerStyles}>
        <img src={bannerUrl} style={{ width: "100%" }} alt="" />
      </div>
    </>
  );
};

export default AdminNavbar;

const bannerStyles = {
  padding: 0,
  margin: 0,
  display: "inline-block",
  whiteSpace: "nowrap",
  lineHeight: "inherit",
};
