import React, { useEffect, useState } from "react";

import { Button, Modal, Spinner } from "react-bootstrap";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Bar } from "react-chartjs-2";

import apiCall from "functions/apiCall";
import {
  HOME_PAGE_CHART_DATA_CLEAR,
  HOME_PAGE_HIDE_CHART_DIALOG,
} from "redux/actionTypes";

// functions
import { getFetchChartParams } from "../../../config/api_calls";

const ChartDialog: React.FC = () => {
  const dispatch = useDispatch();

  const { data, urlSuffix, reportId, loading, show } = useSelector(
    (state: RootStateOrAny) => state.homePage.chartDialog
  );

  const { selectedMoodleInstanceId } = useSelector(
    (state: RootStateOrAny) => state.homePage
  );

  const [chartJsData, setChartJsData] = useState({ ...chartDialogDataTpl });

  useEffect(() => {
    setChartJsData({
      labels: data.labels,
      datasets: [
        {
          label: "",
          data: data.data,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 2,
        },
      ],
    });
  }, [data]);

  useEffect(() => {
    // construct URL based on selected report info
    if (show)
      dispatch(
        apiCall(
          null,
          getFetchChartParams(reportId, selectedMoodleInstanceId, urlSuffix)
        )
      );
    else dispatch({ type: HOME_PAGE_CHART_DATA_CLEAR });
  }, [show]);

  return (
    <Modal
      className="modal-primary"
      show={show}
      onHide={() => dispatch({ type: HOME_PAGE_HIDE_CHART_DIALOG })}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Chart View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && data && "data" in data ? (
          <div className="chart" style={{ height: `300px` }}>
            {/*@ts-ignore*/}
            <Bar data={chartJsData} options={{ ...chartDialogOptionsTpl }} />
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: HOME_PAGE_HIDE_CHART_DIALOG })}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChartDialog;

const chartDialogDataTpl = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 2,
    },
    // {
    //   label: "Internal",
    //   data: [],
    //   backgroundColor: "rgba(54, 162, 235, 0.2)",
    //   borderColor: "rgba(54, 162, 235, 1)",
    //   borderWidth: 2,
    // },
    // {
    //   label: "Holiday",
    //   data: [],
    //   backgroundColor: "rgba(255, 206, 86, 0.2)",
    //   borderColor: "rgba(255, 206, 86, 1)",
    //   borderWidth: 2,
    // },
  ],
};

const chartDialogOptionsTpl = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  legend: {
    display: true,
    position: "bottom",
  },
  // scales: {
  //   yAxes: [
  //     {
  //       stacked: true,
  //       ticks: {
  //         beginAtZero: true,
  //         // @ts-ignore
  //         // eslint-disable-next-line no-unused-vars
  //         callback: (value) => {
  //           return value + "%";
  //         },
  //       },
  //     },
  //   ],
  //   xAxes: [
  //     {
  //       stacked: true,
  //       ticks: {
  //         beginAtZero: true,
  //       },
  //     },
  //   ],
  // },
  tooltips: {
    mode: "label",
    callbacks: {
      // @ts-ignore
      label: function (tooltipItem, data) {
        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ": " +
          tooltipItem.value +
          "%"
        );
      },
    },
  },
};
