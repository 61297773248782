// auth (global)
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_TOKENS = "AUTH_SET_TOKENS";
// loader (global)
export const GLOBAL_LOADER_SHOW = "GLOBAL_LOADER_SHOW";
export const GLOBAL_LOADER_HIDE = "GLOBAL_LOADER_HIDE";
// dialogs (global)
export const DELETE_DIALOG_LOADING = "DELETE_DIALOG_LOADING";
export const DELETE_DIALOG_NOT_LOADING = "DELETE_DIALOG_NOT_LOADING";
export const DELETE_DIALOG_SHOW = "DELETE_DIALOG_SHOW";
export const DELETE_DIALOG_HIDE = "DELETE_DIALOG_HIDE";
export const ADD_EDIT_DIALOG_FORM_CLEAR = "ADD_EDIT_DIALOG_FORM_CLEAR";
export const ADD_EDIT_DIALOG_FORM_POPULATE = "ADD_EDIT_DIALOG_FORM_POPULATE";
export const ADD_EDIT_DIALOG_FORM_SUGGESTIONS_POPULATE =
  "ADD_EDIT_DIALOG_FORM_SUGGESTIONS_POPULATE";
export const ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR =
  "ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR";
export const ADD_EDIT_DIALOG_FORM_SUGGESTIONS_LOADING =
  "ADD_EDIT_DIALOG_FORM_SUGGESTIONS_LOADING";
export const ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING =
  "ADD_EDIT_DIALOG_FORM_SUGGESTIONS_NOT_LOADING";
export const ADD_EDIT_DIALOG_LOADING = "ADD_EDIT_DIALOG_LOADING";
export const ADD_EDIT_DIALOG_NOT_LOADING = "ADD_EDIT_DIALOG_NOT_LOADING";
export const ADD_EDIT_DIALOG_SHOW = "ADD_EDIT_DIALOG_SHOW";
export const ADD_EDIT_DIALOG_HIDE = "ADD_EDIT_DIALOG_HIDE";
// company page
export const CLIENTS_DT_REFRESH = "CLIENTS_DT_REFRESH";
export const CLIENTS_DT_REFRESH_RESEL = "CLIENTS_DT_REFRESH_RESEL";
export const CLIENTS_SET_DT_PAGE = "CLIENTS_SET_DT_PAGE";
export const CLIENTS_SET_DT_ROW = "CLIENTS_SET_DT_ROW";
// moodle instances page
export const MOODLE_INSTANCES_SET_DT_PAGE = "MOODLE_INSTANCES_SET_DT_PAGE";
export const MOODLE_INSTANCES_SET_DT_ROW = "MOODLE_INSTANCES_SET_DT_ROW";
export const MOODLE_INSTANCES_DT_REFRESH = "MOODLE_INSTANCES_DT_REFRESH";
export const MOODLE_INSTANCES_DT_REFRESH_RESEL =
  "MOODLE_INSTANCES_DT_REFRESH_RESEL";
// home page
export const HOME_PAGE_FORM_FIELDS_LOADING = "HOME_PAGE_FORM_FIELDS_LOADING";
export const HOME_PAGE_FORM_FIELDS_NOT_LOADING =
  "HOME_PAGE_FORM_FIELDS_NOT_LOADING";
export const HOME_PAGE_FORM_FIELDS_SET = "HOME_PAGE_FORM_FIELDS_SET";
export const HOME_PAGE_CHART_DATA_SET = "HOME_PAGE_CHART_DATA_SET";
export const HOME_PAGE_CHART_DATA_CLEAR = "HOME_PAGE_CHART_DATA_CLEAR";
export const HOME_PAGE_SELECT_MOODLE_INSTANCE =
  "HOME_PAGE_SELECT_MOODLE_INSTANCE";
export const HOME_PAGE_TOP_NAVBAR_LOADING = "HOME_PAGE_TOP_NAVBAR_LOADING";
export const HOME_PAGE_TOP_NAVBAR_NOT_LOADING =
  "HOME_PAGE_TOP_NAVBAR_NOT_LOADING";
export const HOME_PAGE_TOP_NAVBAR_DATA_SET = "HOME_PAGE_TOP_NAVBAR_DATA_SET";
export const HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING =
  "HOME_PAGE_CHART_DIALOG_DATA_NOT_LOADING";
export const HOME_PAGE_CHART_DIALOG_DATA_LOADING =
  "HOME_PAGE_CHART_DIALOG_DATA_LOADING";
export const HOME_PAGE_SHOW_CHART_DIALOG = "HOME_PAGE_SHOW_CHART_DIALOG";
export const HOME_PAGE_HIDE_CHART_DIALOG = "HOME_PAGE_HIDE_CHART_DIALOG";
export const HOME_PAGE_CHARTS_LOADING = "HOME_PAGE_CHARTS_LOADING";
export const HOME_PAGE_CHARTS_NOT_LOADING = "HOME_PAGE_CHARTS_NOT_LOADING";
export const HOME_PAGE_CHARTS_DATA_SET = "HOME_PAGE_CHARTS_DATA_SET";
// home page - reports component
export const HOME_PAGE_SELECT_REPORT = "HOME_PAGE_SELECT_REPORT";
export const HOME_PAGE_SET_REPORTS_DATE = "HOME_PAGE_SET_REPORTS_DATE";
export const HOME_PAGE_SET_REPORTS_FROM_DATE =
  "HOME_PAGE_SET_REPORTS_FROM_DATE";
export const HOME_PAGE_SET_REPORTS_TO_DATE = "HOME_PAGE_SET_REPORTS_TO_DATE";
export const HOME_PAGE_SET_REPORTS_YEAR = "HOME_PAGE_SET_REPORTS_YEAR";
export const HOME_PAGE_SET_REPORTS_FROM_YEAR =
  "HOME_PAGE_SET_REPORTS_FROM_YEAR";
export const HOME_PAGE_SET_REPORTS_TO_YEAR = "HOME_PAGE_SET_REPORTS_TO_YEAR";
export const HOME_PAGE_REPORTS_ADD_MONTH = "HOME_PAGE_REPORTS_ADD_MONTH";
export const HOME_PAGE_REPORTS_REMOVE_MONTH = "HOME_PAGE_REPORTS_REMOVE_MONTH";
export const HOME_PAGE_REPORTS_ADD_ALL_MONTHS =
  "HOME_PAGE_REPORTS_ADD_ALL_MONTHS";
export const HOME_PAGE_REPORTS_REMOVE_ALL_MONTHS =
  "HOME_PAGE_REPORTS_REMOVE_ALL_MONTHS";
export const HOME_PAGE_REPORTS_SET_CHECKBOX_URL_SUFFIX =
  "HOME_PAGE_REPORTS_SET_CHECKBOX_URL_SUFFIX";
export const HOME_PAGE_REPORTS_SET_DATE_URL_SUFFIX =
  "HOME_PAGE_REPORTS_SET_DATE_URL_SUFFIX";
// plans
export const MSG_PLAN_DEL_SUCCESS = "MSG_PLAN_DEL_SUCCESS";
export const MSG_PLAN_ADD_SUCCESS = "MSG_PLAN_ADD_SUCCESS";
export const MSG_PLAN_UPDATE_SUCCESS = "MSG_PLAN_UPDATE_SUCCESS";
// report packages page
export const REPORT_PACKAGES_DT_REFRESH = "REPORT_PACKAGES_DT_REFRESH";
export const REPORT_PACKAGES_DT_REFRESH_RESEL =
  "REPORT_PACKAGES_DT_REFRESH_RESEL";
export const REPORT_PACKAGES_SET_DT_PAGE = "REPORT_PACKAGES_SET_DT_PAGE";
export const REPORT_PACKAGES_SET_DT_ROW = "REPORT_PACKAGES_SET_DT_ROW";
// user management
export const USER_MANAGEMENT_SET_DT_PAGE = "USER_MANAGEMENT_SET_DT_PAGE";
export const USER_MANAGEMENT_SET_DT_ROW = "USER_MANAGEMENT_SET_DT_ROW";
export const USER_MANAGEMENT_DT_REFRESH = "USER_MANAGEMENT_DT_REFRESH";
export const USER_MANAGEMENT_DT_REFRESH_RESEL =
  "USER_MANAGEMENT_DT_REFRESH_RESEL";
export const USER_MANAGEMENT_SHOW_CP_DIALOG = "USER_MANAGEMENT_SHOW_CP_DIALOG";
export const USER_MANAGEMENT_HIDE_CP_DIALOG = "USER_MANAGEMENT_HIDE_CP_DIALOG";
export const USER_MANAGEMENT_CP_DIALOG_LOADING =
  "USER_MANAGEMENT_CP_DIALOG_LOADING";
export const USER_MANAGEMENT_CP_DIALOG_NOT_LOADING =
  "USER_MANAGEMENT_CP_DIALOG_NOT_LOADING";
// plans
export const PLANS_SET_DT_PAGE = "PLANS_SET_DT_PAGE";
export const PLANS_SET_DT_ROW = "PLANS_SET_DT_ROW";
export const PLANS_DT_REFRESH_RESEL = "PLANS_DT_REFRESH_RESEL";
export const PLANS_DT_REFRESH = "PLANS_DT_REFRESH";
// support page
export const SUPPORT_DT_REFRESH_RESEL = "SUPPORT_DT_REFRESH_RESEL";
export const SUPPORT_SET_DT_PAGE = "SUPPORT_SET_DT_PAGE";
export const SUPPORT_SET_DT_ROW = "SUPPORT_SET_DT_ROW";
export const SUPPORT_DIALOG_SHOW = "SUPPORT_DIALOG_SHOW";
export const SUPPORT_DIALOG_HIDE = "SUPPORT_DIALOG_HIDE";
export const SUPPORT_DIALOG_LOADING = "SUPPORT_DIALOG_LOADING";
export const SUPPORT_DIALOG_NOT_LOADING = "SUPPORT_DIALOG_NOT_LOADING";
// support messages
export const SUPPORT_MESSAGE_DT_REFRESH_RESEL =
  "SUPPORT_MESSAGE_DT_REFRESH_RESEL";
export const SUPPORT_MESSAGE_SET_DT_PAGE = "SUPPORT_MESSAGE_SET_DT_PAGE";
export const SUPPORT_MESSAGE_SET_DT_ROW = "SUPPORT_MESSAGE_SET_DT_ROW";
export const SUPPORT_MESSAGE_SET_DT_REFRESH_URL =
  "SUPPORT_MESSAGE_SET_DT_REFRESH_URL";
export const SUPPORT_MESSAGE_DIALOG_SHOW = "SUPPORT_MESSAGE_DIALOG_SHOW";
export const SUPPORT_MESSAGE_DIALOG_HIDE = "SUPPORT_MESSAGE_DIALOG_HIDE";
export const SUPPORT_MESSAGE_DIALOG_LOADING = "SUPPORT_MESSAGE_DIALOG_LOADING";
export const SUPPORT_MESSAGE_DIALOG_NOT_LOADING =
  "SUPPORT_MESSAGE_DIALOG_NOT_LOADING";
// ----------------
// messages (global)
export const MSG_CUSTOM_MSG = "MSG_CUSTOM_MSG";
// auth messages
export const MSG_AUTH_LOGGED_OUT = "MSG_AUTH_LOGGED_OUT";
export const MSG_AUTH_LOGIN_SUCCESS = "MSG_AUTH_LOGIN_SUCCESS";
export const MSG_AUTH_LOGIN_ERROR = "MSG_AUTH_LOGIN_ERROR";
// company messages
export const MSG_CLIENT_ADD_SUCCESS = "MSG_CLIENT_ADD_SUCCESS";
export const MSG_CLIENT_DEL_SUCCESS = "MSG_CLIENT_DEL_SUCCESS";
export const MSG_CLIENT_UPDATE_SUCCESS = "MSG_CLIENT_UPDATE_SUCCESS";
// moodle instances
export const MSG_MOODLE_INST_ADD_SUCCESS = "MSG_MOODLE_INST_ADD_SUCCESS";
export const MSG_MOODLE_INST_DEL_SUCCESS = "MSG_MOODLE_INST_DEL_SUCCESS";
export const MSG_MOODLE_INST_UPDATE_SUCCESS = "MSG_MOODLE_INST_UPDATE_SUCCESS";
// reports
export const MSG_REPORT_DB_ENTRY_ADD_SUCCESS =
  "MSG_REPORT_DB_ENTRY_ADD_SUCCESS";
export const MSG_REPORT_DB_ENTRY_DEL_SUCCESS =
  "MSG_REPORT_DB_ENTRY_DEL_SUCCESS";
export const MSG_REPORT_DB_ENTRY_UPDATE_SUCCESS =
  "MSG_REPORT_DB_ENTRY_UPDATE_SUCCESS";
// report packages
export const MSG_REPORT_PACKAGES_ADD_SUCCESS =
  "MSG_REPORT_PACKAGES_ADD_SUCCESS";
export const MSG_REPORT_PACKAGES_DEL_SUCCESS =
  "MSG_REPORT_PACKAGES_DEL_SUCCESS";
export const MSG_REPORT_PACKAGES_UPDATE_SUCCESS =
  "MSG_REPORT_PACKAGES_UPDATE_SUCCESS";
// reports
export const REPORT_DB_ENTRY_SET_DT_PAGE = "REPORT_DB_ENTRY_SET_DT_PAGE";
export const REPORT_DB_ENTRY_SET_DT_ROW = "REPORT_DB_ENTRY_SET_DT_ROW";
export const REPORT_DB_ENTRY_DT_REFRESH = "REPORT_DB_ENTRY_DT_REFRESH";
export const REPORT_DB_ENTRY_DT_REFRESH_RESEL =
  "REPORT_DB_ENTRY_DT_REFRESH_RESEL";
// user messages
export const MSG_USER_ADD_SUCCESS = "MSG_USER_ADD_SUCCESS";
export const MSG_USER_DEL_SUCCESS = "MSG_USER_DEL_SUCCESS";
export const MSG_USER_UPDATE_SUCCESS = "MSG_USER_UPDATE_SUCCESS";
export const MSG_SHOW_ERROR_MSG = "MSG_SHOW_ERROR_MSG";
export const MSG_PWD_CHANGED = "MSG_PWD_CHANGED";
// support page messages
export const MSG_SUPPORT_REQUEST_SUBMITTED = "MSG_SUPPORT_REQUEST_SUBMITTED";
// support message page messages
export const MSG_SUPPORT_MESSAGE_SENT = "MSG_SUPPORT_MESSAGE_SENT";
