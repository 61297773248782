import React, { useEffect } from "react";

import { Button, Modal } from "react-bootstrap";

import apiCall from "functions/apiCall";

// redux
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  ADD_EDIT_DIALOG_FORM_CLEAR,
  ADD_EDIT_DIALOG_HIDE,
  ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR,
} from "redux/actionTypes";

import { getAddEditFormSuggestionsParams } from "config/api_calls";

const GlobalAddEditDialog: React.FC<DialogProps> = ({
  instanceId,
  getAddParams,
  getEditParams,
  populateFormParams,
  topTitle,
  formSuggestionsId,
  children,
}) => {
  const dispatch = useDispatch();

  const { suggestionsLoading, formSuggestions, loading, show } = useSelector(
    (state: RootStateOrAny) => state.dialogs.addEditDialog
  );

  const handleSubmit = (formData: any) => {
    instanceId
      ? dispatch(apiCall(formData, getEditParams(instanceId)))
      : dispatch(apiCall(formData, getAddParams()));
  };

  // clear form if 'add' is pressed, populate if 'edit' is pressed
  useEffect(() => {
    if (instanceId && show) {
      dispatch(apiCall(null, populateFormParams(instanceId)));
    } else if (show) {
      dispatch({ type: ADD_EDIT_DIALOG_FORM_CLEAR });
    }
  }, [show, instanceId]);

  // if the form has suggestions for fields, get them from dedicated endpoint
  useEffect(() => {
    if (formSuggestionsId && show)
      dispatch(
        apiCall(null, getAddEditFormSuggestionsParams(formSuggestionsId))
      );
    return () => {
      if (formSuggestionsId) {
        dispatch({ type: ADD_EDIT_DIALOG_FORM_SUGGESTIONS_CLEAR });
      }
    };
  }, [formSuggestionsId, show]);

  return (
    <Modal
      className="modal-primary"
      onHide={() => dispatch({ type: ADD_EDIT_DIALOG_HIDE })}
      show={show}
    >
      <Modal.Header className="justify-content-center" closeButton={true}>
        <Modal.Title>
          {instanceId ? "Edit" : "Add"} {topTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children &&
          React.cloneElement(children, {
            onSubmit: handleSubmit,
            formSuggestionsLoading: suggestionsLoading,
            formSuggestions: formSuggestions,
            dialogLoading: loading,
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" form="modalForm">
          Submit
        </Button>
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: ADD_EDIT_DIALOG_HIDE })}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GlobalAddEditDialog;

interface DialogProps {
  instanceId: number;
  getAddParams: () => any;
  getEditParams: (instId: number) => any;
  populateFormParams: (instId: number) => any;
  topTitle: string;
  formSuggestionsId?: string;
  children: JSX.Element;
}
