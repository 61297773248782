import React, { useEffect } from "react";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Card, Col, Row, Spinner } from "react-bootstrap";

// api calls
import apiCall from "functions/apiCall";
import { getTopNavDataParams } from "config/api_calls";

const TopAdminBar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiCall(null, getTopNavDataParams));
  }, []);

  const topBarValues = useSelector(
    (state: RootStateOrAny) => state.homePage.topBar.values
  );

  const topBarLoading = useSelector(
    (state: RootStateOrAny) => state.homePage.topBar.loading
  );

  return (
    <>
      {topBarValues.active_support_cases ? (
        <Row>
          <Col sm="12">
            <p style={{ color: "red" }}>
              You currently have {topBarValues.active_support_cases} unresolved
              support requests. Please visit the support section to address
              them.
            </p>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="far fa-building text-warning" />
                  </div>
                </Col>
                <Col xs="7">
                  {topBarLoading ? (
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="numbers">
                      <p className="card-category">Companies</p>
                      <Card.Title as="h4">
                        {topBarValues.company_count}
                      </Card.Title>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr />
              {/*<div className="stats">*/}
              {/*  <i className="fas fa-redo mr-1" />*/}
              {/*  Update Now*/}
              {/*</div>*/}
            </Card.Footer>
          </Card>
        </Col>
        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="far fa-user text-info" />
                  </div>
                </Col>
                <Col xs="7">
                  {topBarLoading ? (
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="numbers">
                      <p className="card-category">Users</p>
                      <Card.Title as="h5">{topBarValues.user_count}</Card.Title>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr />
            </Card.Footer>
          </Card>
        </Col>
        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="fas fa-database text-success" />
                  </div>
                </Col>
                <Col xs="7">
                  {topBarLoading ? (
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="numbers">
                      <p className="card-category">Moodle Instances</p>
                      <Card.Title as="h4">
                        {topBarValues.moodle_instance_count}
                      </Card.Title>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr />
              {/*<div className="stats">*/}
              {/*  <i className="far fa-calendar-alt mr-1" />*/}
              {/*  Last day*/}
              {/*</div>*/}
            </Card.Footer>
          </Card>
        </Col>
        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="far fa-copy text-info" />
                  </div>
                </Col>
                <Col xs="7">
                  {topBarLoading ? (
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="numbers">
                      <p className="card-category">Reports</p>
                      <Card.Title as="h5">
                        {topBarValues.report_count} reports
                        <br />
                        {topBarValues.report_package_count} package
                        {topBarValues.report_package_count === 1 ? "" : "s"}
                      </Card.Title>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr />
              {/*<div className="stats">*/}
              {/*  <i className="far fa-clock-o mr-1" />*/}
              {/*  In the last hour*/}
              {/*</div>*/}
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TopAdminBar;
