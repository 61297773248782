import React, { useEffect, useState } from "react";

import Select, { OptionsType, OptionTypeBase } from "react-select";

import { Controller } from "react-hook-form";

import { Form } from "react-bootstrap";

const CustomFormSelect: React.FC<CustomFormSelectTypes> = ({
  loading,
  options,
  formFields,
  placeholder,
  control,
  fieldName,
  showValidationError,
  validationMessage,
  isMulti,
  required,
}) => {
  const [selectedObj, setSelectedObj] = useState<
    OptionTypeBase | OptionTypeBase[] | null
  >();

  const getSelectedValuesFromInts = (
    selectBoxOptions: OptionsType<OptionTypeBase>,
    selectedOptionsInt: number[]
  ) => {
    const returnArray: OptionsType<OptionTypeBase> = [];
    selectBoxOptions.forEach((option) => {
      if (selectedOptionsInt && selectedOptionsInt.includes(option.value))
        // @ts-ignore
        returnArray.push(option);
    });

    return returnArray;
  };

  // when pre populated form field values change in redux, (which is on load) and, if there's a value, find
  // selected values and set the appropriate select object for the select box
  useEffect(() => {
    let matchedOption: OptionTypeBase | undefined | null = null;
    if (isMulti) {
      let selectedArray: OptionsType<OptionTypeBase> = [];
      if (
        options &&
        formFields[fieldName] &&
        Object.keys(formFields[fieldName]).length
      ) {
        selectedArray = getSelectedValuesFromInts(
          options,
          formFields[fieldName]
        );
      } else {
        selectedArray = [];
      }
      setSelectedObj(selectedArray);
    } else {
      if (options && formFields) {
        matchedOption = options.find(
          (obj) => obj.value === formFields[fieldName]
        );
        if (matchedOption) setSelectedObj(matchedOption);
        else setSelectedObj(null);
      } else setSelectedObj(null);
    }
  }, [formFields, options]);

  return (
    <>
      <Controller
        defaultValue={selectedObj}
        // @ts-ignore
        name={fieldName}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field }) => (
          <Select
            {...field}
            className="react-select primary"
            classNamePrefix="react-select"
            value={selectedObj}
            options={options}
            placeholder={placeholder}
            isDisabled={loading}
            isMulti={isMulti}
            onChange={(val) => {
              setSelectedObj(val);
              field.onChange(
                !val
                  ? null
                  : isMulti
                  ? val.map((item: OptionTypeBase) => {
                      return item.value;
                    })
                  : val.value
              );
            }}
          />
        )}
      />
      <Form.Group>
        <Form.Control
          isInvalid={showValidationError}
          style={{ display: "none" }}
        />
        <Form.Control.Feedback type="invalid">
          {validationMessage}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default CustomFormSelect;

interface CustomFormSelectTypes {
  loading: boolean;
  options: OptionsType<OptionTypeBase>;
  formFields: any;
  placeholder: string;
  fieldName: string;
  control: any;
  showValidationError: boolean;
  validationMessage: string;
  isMulti?: boolean;
  required: boolean;
}
